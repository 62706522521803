var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "quality-control d-flex align-items-center" },
    [
      _vm.$gate.isAdmin()
        ? _c("div", { staticClass: "check-wrapp" }, [
            _c("input", {
              attrs: { type: "checkbox" },
              domProps: {
                value: _vm.video.id,
                checked: this.checkedVideos.includes(_vm.video.id),
              },
              on: { change: (e) => _vm.handleSelect(e, _vm.video.id) },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "thumbnail" }, [
        _c("img", {
          attrs: {
            src: _vm.video.thumbnail
              ? _vm.video.thumbnail
              : "https://via.placeholder.com/600x400",
            alt: _vm.video.title,
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.video.title))]),
      _vm._v(" "),
      _c("div", { staticClass: "series" }),
      _vm._v(" "),
      _c("div", { staticClass: "status text-success" }, [
        _vm._v("\n    Done\n  "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "action" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }