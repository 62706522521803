<template>
<div>
    <Header></Header>
    <section class="content">
        <h1 class="section-title"></h1>

        <div class="controls">
            <input type="text" v-model="q" class="form-control search-input" placeholder="Search.." @keyup.enter="search" />
            <button class="btn btn-primary btn-search" :disabled="!q" @click="search">
                Search
            </button>
        </div>

        <div class="card-body table-responsive p-0">
            <table class="table table-hover table-borderless table-striped">
                <thead>
                    <tr>
                        <th @click="handleSort('series.name')">
                            Series
                            <i class="fa fa-fw fa-sort"></i>
                        </th>
                        <th></th>
                        <th>Status</th>
                        <th></th>
                        <th>
                            Show:
                            <select class="form-control" v-model="perPage" @change="changePerPage">
                                <option>10</option>
                                <option>25</option>
                                <option>50</option>
                                <option>100</option>
                            </select>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="one in series.data" :key="one.id">
                        <td class="series-name">{{ one.name }}</td>
                        <td></td>
                        <td class="text-capitalize">{{ "Active" }}</td>
                        <td></td>
                        <td>
                            <a href="#" @click="goQuploads($event, one.id)">
                                View Content
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="pagination">
                <pagination :data="series" @pagination-change-page="getResults" :per-page="perPage"></pagination>
            </div>
        </div>
    </section>
</div>
</template>

    
<script>
import Header from "./header";

export default {
    components: {
        Header,
    },
    data() {
        return {
            editmode: false,
            series: {},
            form: new Form({
                id: "",
                type: "",
                name: "",
                email: "",
                password: "",
                email_verified_at: "",
            }),
            perPage: 10,
            q: "",
            sortOrder: "",
            sortColumn: "series.name",
        };
    },
    methods: {
        changePerPage() {
            window.localStorage.setItem("dashboard_per_page", this.perPage);
        },
        search() {
            this.getResults();
        },
        getResults(page = 1) {
            this.$Progress.start();

            axios
                .get(
                    `api/series/getbyuserid?userId=${this.$gate.user.id}&page=${page}&per_page=${
                        this.perPage
                        }&q=${this.q}&sort_by=${this.sortColumn}&sort_dir=${
                        this.sortOrder ? this.sortOrder : "desc"
                        }`
                )
                .then(({
                    data
                }) => (this.series = data.data));

            // update query param
            const queryParams = new URLSearchParams(window.location.search);
            queryParams.set("page", page);
            const newUrl = `${window.location.origin}${
                window.location.pathname
                }?${queryParams.toString()}`;
            window.history.replaceState({
                path: newUrl
            }, "", newUrl);

            this.$Progress.finish();
        },
        goQuploads(e, series_id) {
            e.preventDefault();
            this.$router.push("/quality-uploads/" + series_id);
        },
        handleSort(column) {
            if (this.sortColumn === column) {
                this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
            } else {
                this.sortColumn = column;
                this.sortOrder = "asc";
            }
            this.getResults(this.$route.query.page);
        },
    },
    watch: {
        perPage(oldPerPage, newPerPage) {
            this.getResults();
        },
    },
    created() {
        this.$Progress.start();
        this.getResults(this.$route.query.page);

        if (window.localStorage.getItem("dashboard_per_page"))
            this.perPage = window.localStorage.getItem("dashboard_per_page");
        this.$Progress.finish();
    }
};
</script>

    
<style lang="scss" scoped>
.content {
    padding: 70px 50px;

    .controls {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;

        .search-input {
            width: auto;
            height: 40px;
            border-radius: 10px;
        }

        .btn-search {
            height: 40px;
            line-height: 1;
        }

        .btn-create-programmer {
            padding: 8px 20px;
            display: block;
            height: 40px;
            line-height: 1;
        }
    }

    .section-title {
        margin-bottom: 100px;
    }

    .table-striped {
        tbody {
            tr {
                border-radius: 3px;

                &:nth-of-type(even) {
                    background-color: #dedede;
                }

                &:nth-of-type(odd) {
                    background-color: #f1f1f1;
                }
            }
        }
    }

    .table {
        border-collapse: separate;
        border-spacing: 0 10px;

        thead {
            th {
                font-weight: 700;
                font-size: 16px;
                line-height: 29px;
                color: #616978;

                .form-control {
                    width: auto;
                    display: inline-block;
                }
            }
        }

        .series-name {
            white-space: nowrap;
        }

        td {
            font-weight: 500;
            font-size: 16px;
            line-height: 29px;
            color: #8a94a6;
        }

        tr td:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        tr td:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
}
</style>
