var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Header"),
      _vm._v(" "),
      _c("section", { staticClass: "content" }, [
        _c("h1", { staticClass: "section-title" }, [_vm._v("Vimeo Jobs List")]),
        _vm._v(" "),
        _vm.$gate.isAdmin()
          ? _c("div", { staticClass: "controls" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary btn-create-programmer",
                  on: {
                    click: function ($event) {
                      return _vm.$router.push("/vimeo-job")
                    },
                  },
                },
                [_vm._v("\n        Create a Job\n      ")]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.$gate.isAdmin()
          ? _c("div", { staticClass: "card-body table-responsive p-0" }, [
              _c(
                "table",
                {
                  staticClass:
                    "table table-hover table-borderless table-striped",
                },
                [
                  _c("thead", [
                    _c("tr", [
                      _c(
                        "th",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.handleSort("name")
                            },
                          },
                        },
                        [
                          _vm._v("\n              Programmer\n              "),
                          _c("i", {
                            staticClass: "fa fa-fw",
                            class:
                              _vm.sortOrder === "asc"
                                ? "fa-sort-asc"
                                : _vm.sortOrder === "desc"
                                ? "fa-sort-desc"
                                : "fa-sort",
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("th", [_vm._v("Last Run")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Status")]),
                      _vm._v(" "),
                      _c("th", [
                        _vm._v("\n              Show:\n              "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.perPage,
                                expression: "perPage",
                              },
                            ],
                            staticClass: "form-control",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.perPage = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          [
                            _c("option", [_vm._v("10")]),
                            _vm._v(" "),
                            _c("option", [_vm._v("25")]),
                            _vm._v(" "),
                            _c("option", [_vm._v("50")]),
                            _vm._v(" "),
                            _c("option", [_vm._v("100")]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.users.data, function (programmer) {
                      return _c("tr", { key: programmer.id }, [
                        _c("td", [_vm._v(_vm._s(programmer.name))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(programmer.jobs[0].last_run))]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-capitalize" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(programmer.jobs[0].status) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: `/vimeo-job?user_id=${programmer.id}`,
                              },
                              on: { click: function ($event) {} },
                            },
                            [
                              _c("i", { staticClass: "fa fa-pencil" }),
                              _vm._v("\n                Edit\n              "),
                            ]
                          ),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("pagination", {
                    attrs: { data: _vm.users, "per-page": _vm.perPage },
                    on: { "pagination-change-page": _vm.getResults },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }