var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "avatar-cropper" }, [
    _vm.dataUrl
      ? _c(
          "div",
          {
            staticClass: "avatar-cropper-overlay",
            class: { "avatar-cropper-overlay-inline": _vm.inline },
          },
          [
            !_vm.inline
              ? _c("div", { staticClass: "avatar-cropper-mark" }, [
                  _c(
                    "a",
                    {
                      staticClass: "avatar-cropper-close",
                      attrs: { title: _vm.labels.cancel, href: "javascript:;" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v("×")]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "avatar-cropper-container" }, [
              _c("div", { staticClass: "avatar-cropper-image-container" }, [
                _c("img", {
                  ref: "img",
                  attrs: { src: _vm.dataUrl, alt: "" },
                  on: {
                    load: function ($event) {
                      $event.stopPropagation()
                      return _vm.createCropper.apply(null, arguments)
                    },
                    error: _vm.onImgElementError,
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "avatar-cropper-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "avatar-cropper-btn",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.cancel.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.labels.cancel) + "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "avatar-cropper-btn",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.submit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.labels.submit) + "\n        "
                    ),
                  ]
                ),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.file
      ? _c("input", {
          ref: "input",
          staticClass: "avatar-cropper-img-input",
          attrs: {
            accept: _vm.cleanedMimes,
            capture: _vm.capture,
            type: "file",
          },
          on: { change: _vm.onFileInputChange },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }