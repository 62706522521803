<template>
  <div class="">
    <Header />
    <div class="content">
      <h1 class="title">Success!</h1>
      <p class="message">
        Your video file(s) have successfully been uploaded to Real Life Network.
        Our team is actively reviewing your submission. Once the file(s) are
        approved you will be notified via email.

        <br />
        <br />

        <span v-for="(title, index) in videoTitles" :key="index"
          >{{ title }}<br
        /></span>
        <br />

        <button class="upload-btn" @click="goToUpload">
          Upload More
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import Header from "../components/header";

export default {
  props: ["videos"],
  components: {
    Header,
  },
  data: function () {
    return {
      videoTitles: JSON.parse(this.$route.query.videoTitles),
    };
  },
  methods: {
    goToUpload() {
      if (this.$route.query.folder_name) {
        this.$router.push(
          `/upload-video?series_id=${this.$route.query.series_id}&folder_name=${this.$route.query.folder_name}`
        );
      } else {
        this.$router.push("/upload-video");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  padding: 50px 30px;
  max-width: 650px;
  margin: 0 auto;
  .title {
    font-weight: 600;
    font-size: 32px;
    line-height: 29px;
    text-align: center;
    color: #323b4b;
    margin-bottom: 27px;
  }
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 29px;
    color: #8a94a6;
  }
  .upload-btn {
    width: 120px;
    height: 38px;
    background: #ffffff;
    border: 1px solid #b6b6b6;
    border-radius: 15px;
    outline: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 29px;
    color: #323b4b;
  }
}
</style>
