var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Header"),
      _vm._v(" "),
      _c("section", { staticClass: "content" }, [
        _c("h1", { staticClass: "section-title" }, [_vm._v("Vimeo Sync")]),
        _vm._v(" "),
        _c("div", { staticClass: "form" }, [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.handleSubmit()
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.user_id,
                          expression: "form.user_id",
                        },
                      ],
                      staticClass: "form-control programmer-select",
                      attrs: { name: "programmerName", id: "programmer-name" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.form,
                            "user_id",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("Choose Programmer"),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.users, function (user) {
                        return _c(
                          "option",
                          { key: user.id, domProps: { value: user.id } },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(user.name) +
                                "\n            "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "programmer-name" } }, [
                    _vm._v("Programmer Name"),
                  ]),
                  _vm._v(" "),
                  _c("has-error", { attrs: { form: _vm.form, field: "name" } }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.app_id,
                        expression: "form.app_id",
                      },
                    ],
                    staticClass: "form-control input-box",
                    class: {
                      "is-invalid": _vm.form.errors.has("vimeo_app_ID"),
                    },
                    attrs: {
                      type: "text",
                      id: "vimeo-app-id",
                      "aria-describedby": "appID",
                      placeholder: "Please enter app id",
                    },
                    domProps: { value: _vm.form.app_id },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "app_id", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "vimeo-app-id" } }, [
                    _vm._v("App ID"),
                  ]),
                  _vm._v(" "),
                  _c("has-error", {
                    attrs: { form: _vm.form, field: "vimeo_app_ID" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.app_secret,
                        expression: "form.app_secret",
                      },
                    ],
                    staticClass: "form-control input-box",
                    class: {
                      "is-invalid": _vm.form.errors.has("vimeo_app_secret"),
                    },
                    attrs: {
                      type: "text",
                      id: "vimeo-app-secret",
                      "aria-describedby": "vimeo_app_secret",
                      placeholder: "Please enter app secret",
                      autocomplete: "false",
                    },
                    domProps: { value: _vm.form.app_secret },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "app_secret", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "vimeo-app-secret" } }, [
                    _vm._v("Secret"),
                  ]),
                  _vm._v(" "),
                  _c("has-error", {
                    attrs: { form: _vm.form, field: "vimeo_app_secret" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "actions d-flex justify-content-end gap-3" },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-cancel btn-white px-4 py-3 text-danger",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push("/dashboard")
                        },
                      },
                    },
                    [_vm._v("\n            Delete\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-save btn-primary px-5",
                      attrs: { type: "submit" },
                    },
                    [_vm._v("\n            Save\n          ")]
                  ),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }