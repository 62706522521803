<template>
  <div>
    <Header></Header>
    <section class="content">
      <h1 class="section-title">Create a User</h1>
      <div class="form">
        <form @submit.prevent="createUser()">
          <div class="form-group">
            <v-select
              class="new-styles"
              v-model="form.type_name"
              label="type_name"
              :options="types"
              :reduce="(type_name) => type_name.value"
              :taggable="true"
              id="types-option"
              placeholder="Select User Type"
            ></v-select>
            <label for="types-option">User Type</label>
          </div>
          <div v-if="this.form.type_name != 'guest'" class="form-group">
            <v-select
              class="new-styles"
              label="name"
              :options="series"
              :taggable="true"
              @input="selectedSeries"
              id="series-option"
              placeholder="Select Series"
            ></v-select>
            <label for="series-option">Series Name</label>
          </div>
          <!-- <div class="form-group">
                    <input type="text" v-model="form.name" id="series-title" class="form-control input-box"
                    :class="{ 'is-invalid': form.errors.has('name') }" aria-describedby="seriesTitle"
                    placeholder="Programmer Name" />
                    <label for="series-title">Programmer Name</label>
                    <has-error :form="form" field="name"></has-error>
                </div> -->
          <div v-if="this.form.type_name != 'guest'" class="form-group">
            <input
              type="text"
              v-model="form.folder_name"
              id="folder-name"
              class="form-control input-box"
              :class="{ 'is-invalid': form.errors.has('folder_name') }"
              aria-describedby="foldername"
              placeholder="Folder name"
            />
            <label for="folder-name">Folder Name</label>
            <has-error :form="form" field="folder_name"></has-error>
          </div>
          <div class="form-group checkbox">
            <input
              type="checkbox"
              v-model="form.require_user"
              id="require_user"
              class="form-control check-box"
            />
            <label for="require_user">Require User Access</label>
          </div>
          <div class="form-group">
            <input
              type="text"
              v-model="form.email"
              id="programmer-email"
              class="form-control input-box"
              :class="{ 'is-invalid': form.errors.has('email') }"
              :disabled="!form.require_user"
              aria-describedby="programmerEmail"
              placeholder="Programmer Email"
            />
            <label for="programmer-email">Email</label>
            <has-error :form="form" field="email"></has-error>
          </div>
          <div class="form-group">
            <input
              type="password"
              v-model="form.password"
              id="programmer-pass"
              class="form-control input-box"
              :class="{ 'is-invalid': form.errors.has('password') }"
              :disabled="!form.require_user"
              aria-describedby="password"
              placeholder="Password for programmer"
              autocomplete="false"
            />
            <label for="programmer-pass">Password</label>
            <has-error :form="form" field="password"></has-error>
          </div>
          <div class="controls-wrapper" v-if="this.form.type_name != 'admin'">
            <div class="all-wrapper">
              <input type="checkbox" v-model="form.watch_all" />
              <span>&nbsp;&nbsp;Select all</span>
            </div>
            <div class="controls">
              <input
                type="text"
                v-model="q"
                class="form-control search-input"
                placeholder="Search.."
                @keyup.enter="search"
              />
              <button
                class="btn btn-primary btn-search"
                type="button"
                :disabled="!q"
                @click="search"
              >
                Search
              </button>
            </div>
          </div>
          <div
            class="card-body table-responsive p-0"
            v-if="this.form.type_name != 'admin'"
          >
            <table class="table table-hover table-borderless table-striped">
              <thead>
                <tr>
                  <th @click="handleSort('series.name')" class="series-wrap">
                    Series
                    <i class="fa fa-fw fa-sort"></i>
                  </th>
                  <th></th>
                  <th>Status</th>
                  <th></th>
                  <th>
                    Show:
                    <select
                      class="form-control"
                      v-model="perPage"
                      @change="changePerPage"
                    >
                      <option>10</option>
                      <option>25</option>
                      <option>50</option>
                      <option>100</option>
                    </select>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="one in seriesAll.data" :key="one.id">
                  <td class="check-wrap">
                    <input
                      type="checkbox"
                      @change="(e) => handleChange(e, one.id)"
                      :checked="form.checked_series.includes(one.id)"
                    />
                  </td>
                  <td class="series-name">{{ one.name }}</td>
                  <td class="text-capitalize">{{ "Active" }}</td>
                  <td></td>
                  <td>
                    <a href="#" @click="goQuploads($event, one.id)">
                      View Content
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="pagination">
              <pagination
                :data="seriesAll"
                @pagination-change-page="getResults"
                :per-page="perPage"
              >
              </pagination>
            </div>
          </div>
          <div class="actions d-flex justify-content-end gap-3">
            <button
              class="btn btn-cancel btn-white px-4 py-3"
              @click="$router.push('/dashboard')"
              type="button"
            >
              Cancel
            </button>
            <button class="btn btn-save btn-primary px-5" type="submit">
              Save
            </button>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import Header from "./header";

export default {
  components: {
    Header,
  },
  data: function () {
    return {
      form: new Form({
        id: "",
        name: "",
        email: "",
        type_name: "",
        password: "",
        folder_name: "",
        require_user: true,
        checked_series: [],
        watch_all: false,
      }),
      series: [],
      seriesAll: {},
      types: [
        {
          type_name: "Admin",
          value: "admin",
        },
        {
          type_name: "Programmer",
          value: "user",
        },
        {
          type_name: "User",
          value: "guest",
        },
      ],
      type_name: null,
      perPage: 10,
      q: "",
      sortOrder: "",
      sortColumn: "series.name",
    };
  },
  methods: {
    changePerPage() {
      this.form.checked_series = [];
      this.getResults();
    },
    search() {
      this.form.checked_series = [];
      this.getResults();
    },
    getResults(page = 1) {
      this.$Progress.start();

      axios
        .get(
          `api/series/getSeries?page=${page}&per_page=${this.perPage}&q=${
            this.q
          }&sort_by=${this.sortColumn}&sort_dir=${
            this.sortOrder ? this.sortOrder : "desc"
          }`
        )
        .then(({ data }) => {
          this.seriesAll = data.data;
        });

      // update query param
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("page", page);
      const newUrl = `${window.location.origin}${
        window.location.pathname
      }?${queryParams.toString()}`;
      window.history.replaceState(
        {
          path: newUrl,
        },
        "",
        newUrl
      );

      this.$Progress.finish();
    },
    selectedSeries(option) {
      this.form.name = option.name;
      if (option.folder_name) this.form.folder_name = option.folder_name;
      else this.form.folder_name = "";
    },
    goQuploads(e, series_id) {
      e.preventDefault();
      this.$router.push("/quality-uploads/" + series_id);
    },
    handleSort(column) {
      if (this.sortColumn === column) {
        this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = column;
        this.sortOrder = "asc";
      }
      this.getResults(this.$route.query.page);
    },
    getSeries() {
      this.$Progress.start();
      axios.get("/api/series/getAll").then(({ data }) => {
        this.series = data;
      });
      this.$Progress.finish();
    },
    createUser() {
      this.$Progress.start();

      this.form
        .post("api/series")
        .then((response) => {
          Toast.fire({
            icon: "success",
            title: response.data.message,
          });

          this.$Progress.finish();
          this.$router.push("/dashboard");
        })
        .catch((e) => {
          Toast.fire({
            icon: "error",
            title: "Some error occured! Please try again",
          });
        });
    },
    handleChange(e, id) {
      e.preventDefault();
      if (e.target.checked) {
        if (!this.form.checked_series.includes(id)) {
          this.form.checked_series.push(id);
        }
      } else {
        const index = this.form.checked_series.indexOf(id);
        if (index !== -1) {
          this.form.checked_series.splice(index, 1);
        }
      }
    },
  },
  created() {
    this.$Progress.start();
    this.getSeries();
    this.getResults(this.$route.query.page);
    this.$Progress.finish();
  },
  updated() {}
};
</script>

<style lang="scss">
.new-styles .vs__search {
  height: 50px;
}

.new-styles .vs__dropdown-toggle {
  border-radius: 15px;
}

.content {
  padding: 70px 100px;

  .section-title {
    margin-bottom: 100px;
  }

  .table {
    border-collapse: separate;
    border-spacing: 0 10px;

    thead {
      th {
        font-weight: 700;
        font-size: 16px;
        line-height: 29px;
        color: #616978;

        .form-control {
          width: auto;
          display: inline-block;
        }
      }
    }

    .check-wrap {
      width: 5px !important;
      text-align: center;
    }

    .series-name {
      white-space: nowrap;
    }

    .series-wrap {
      display: inline-flex;
      align-items: center;
    }

    td {
      font-weight: 500;
      font-size: 16px;
      line-height: 29px;
      color: #8a94a6;
    }

    tr td:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    tr td:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  .form {
    .form-group {
      label {
        padding: 6px 15px;
        font-weight: 500 !important;
        font-size: 18px;
        line-height: 29px;
        color: #8a94a6;
      }
    }

    .controls {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;

      .search-input {
        width: auto;
        height: 40px;
        border-radius: 10px;
      }

      .btn-search {
        height: 40px;
        line-height: 1;
      }
    }

    .form-group.checkbox {
      display: flex;
      flex-direction: row;
      align-items: center;

      input {
        width: 20px;

        &:focus {
          box-shadow: none;
        }
      }

      label {
        margin-bottom: 0px;
      }
    }
  }
  .controls-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .all-wrapper {
    display: flex;
    align-items: center;
    span {
        font-size: 16px;
    }
  }
}
</style>
