var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Header"),
      _vm._v(" "),
      _c("section", { staticClass: "content" }, [
        _c("h1", { staticClass: "section-title" }, [_vm._v("Video Upload")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      Drag and Drop your videos on the upload area below or click browse and\n      add your videos. Click Start Upload to send Real Life Network your video\n      content. Once the videos are uploaded you will be prompt to add meta\n      data to each video below. Make sure to click save to complete your video\n      uploads. You will recieve an email receipt letting you know we\n      successfully recieved your content.\n    "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "drop" },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.$refs.upload && _vm.$refs.upload.dropActive,
                    expression: "$refs.upload && $refs.upload.dropActive",
                  },
                ],
                staticClass: "drop-active",
              },
              [_c("h3", [_vm._v("Drop files to upload")])]
            ),
            _vm._v(" "),
            _c(
              "file-upload",
              {
                ref: "upload",
                staticClass: "d-none",
                attrs: {
                  multiple: true,
                  drop: true,
                  "drop-directory": true,
                  "custom-action": _vm.uploadS3,
                  accept: "video/*",
                  timeout: 600 * 1000,
                },
                model: {
                  value: _vm.files,
                  callback: function ($$v) {
                    _vm.files = $$v
                  },
                  expression: "files",
                },
              },
              [
                _c("i", { staticClass: "fa fa-plus" }),
                _vm._v("\n        Select files\n      "),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "drop-zone" }, [
              _vm._m(0),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _vm.files.length
                ? _c("p", { staticClass: "file-counts" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.files.length) +
                        " files added\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.files.length
                ? _c(
                    "ul",
                    _vm._l(_vm.files, function (file) {
                      return _c("li", { key: file.id }, [
                        _c("div", { staticClass: "d-flex" }, [
                          _c("span", [_vm._v(_vm._s(file.name))]),
                          _vm._v(" -\n              "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.formatBytes(file.size))),
                          ]),
                          _vm._v(" -\n              "),
                          file.error
                            ? _c("span", [_vm._v(_vm._s(file.error))])
                            : file.success
                            ? _c("span", { staticClass: "success" }, [
                                _vm._v("success\n                "),
                                _c("i", {
                                  staticClass: "fa fas fa-check-circle ml-2",
                                }),
                              ])
                            : file.active
                            ? _c("span", [_vm._v("uploading...")])
                            : _c("span"),
                        ]),
                        _vm._v(" "),
                        file.active
                          ? _c("div", { staticClass: "progress" }, [
                              _c(
                                "div",
                                {
                                  class: {
                                    "progress-bar": true,
                                    "progress-bar-striped": true,
                                    "bg-danger": file.error,
                                    "progress-bar-animated": file.active,
                                  },
                                  style: { width: _vm.progress + "%" },
                                  attrs: { role: "progressbar" },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.progress) +
                                      "%\n              "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "upload-btn m-4" }, [
          !_vm.$refs.upload || !_vm.$refs.upload.active
            ? _c(
                "button",
                {
                  staticClass: "btn btn-primary px-4",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.$refs.upload.active = true
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-arrow-up",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v("\n        Start Upload\n      "),
                ]
              )
            : _c(
                "button",
                {
                  staticClass: "btn btn-danger px-4",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.$refs.upload.active = false
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-stop",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v("\n        Stop Upload\n      "),
                ]
              ),
        ]),
        _vm._v(" "),
        _vm.videos.length
          ? _c(
              "div",
              { staticClass: "videos" },
              [
                _c("div", { staticClass: "videos-head d-flex" }, [
                  _c("div", { staticClass: "count" }, [
                    _vm._v(_vm._s(_vm.videos.length) + " Videos"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "status" }, [_vm._v("Status")]),
                ]),
                _vm._v(" "),
                _vm._l(_vm.videos, function (video, index) {
                  return _c("Video", {
                    key: index,
                    attrs: {
                      video: video,
                      idx: index,
                      series_id: _vm.$route.query.series_id
                        ? _vm.$route.query.series_id
                        : "",
                    },
                  })
                }),
              ],
              2
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "file" } }, [
      _c("img", {
        attrs: { src: "/images/upload-icon.png", alt: "Upload Icon" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "upload-text" }, [
      _vm._v("\n          Drag and Drop or "),
      _c("label", { attrs: { for: "file" } }, [_vm._v("Browse")]),
      _vm._v(" to upload\n        "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }