export default [
  {
    path: "/dashboard",
    component: require("./components/Dashboard.vue").default,
  },
  {
    path: "/guest",
    component:require("./components/Guest.vue").default,
  },
  {
    path: "/schedule",
    component:require("./components/Schedule.vue").default,
  },
  {
    path: "/add-schedule",
    component:require("./components/AddSchedule.vue").default,
  },
  {
    path:'/draft',
    component:require("./components/Draft.vue").default,
  },
  {
    path:'/edit-draft/:draft_id',
    component:require("./components/UploadVideoDraft.vue").default,
  },
  {
    path: "/quality-uploads",
    component: require("./components/QualityUploads.vue").default,
  },
  {
    path: "/videos/:user_id",
    component: require("./components/UserVideos.vue").default,
  },
  {
    path: "/quality-uploads/:series_id",
    component: require("./components/QualityUploads.vue").default,
  },
  {
    path: "/edit-series/:series_id",
    component: require("./components/EditSeries.vue").default,
  },
  {
    path: "/edit-season/:season_id",
    component: require("./components/EditSeasons.vue").default,
  },
  {
    path: "/upload-video",
    component: require("./components/UploadVideo.vue").default,
  },
  {
    path: "/quality-control-check/:video_id",
    component: require("./components/QualityControlCheck.vue").default,
  },
  {
    path: "/create-programmer",
    component: require("./components/CreateProgrammer.vue").default,
  },
  {
    path: "/upload-success",
    component: require("./components/UploadSuccess.vue").default,
  },
  {
    path: "/vimeo-jobs",
    component: require("./components/VimeoJobs.vue").default,
  },
  {
    path: "/vimeo-videos-list",
    component: require("./components/VimeoVideoList.vue").default,
  },
  {
    path: "/vimeo-job",
    component: require("./components/VimeoJob.vue").default,
  },
  {
    path: "/vimeo-test",
    component: require("./components/VimeoTest.vue").default,
  },
  { path: "/profile", component: require("./components/Profile.vue").default },
  {
    path: "/developer",
    component: require("./components/Developer.vue").default,
  },
  { path: "/users", component: require("./components/Users.vue").default },
  {
    path: "*",
    redirect: to => {
      return { path: '/dashboard'}
    }
  },

];
