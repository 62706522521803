<template>
  <div>
    <Header></Header>
    <section class="content">
      <h1 class="section-title">Vimeo Sync</h1>
      <div class="form">
        <form @submit.prevent="handleSubmit()">
          <div class="form-group">
            <select
              name="programmerName"
              id="programmer-name"
              class="form-control programmer-select"
              v-model="form.user_id"
            >
              <option value="">Choose Programmer</option>
              <option v-for="user of users" :key="user.id" :value="user.id">
                {{ user.name }}
              </option>
            </select>
            <label for="programmer-name">Programmer Name</label>
            <has-error :form="form" field="name"></has-error>
          </div>
          <div class="form-group">
            <input
              type="text"
              v-model="form.app_id"
              id="vimeo-app-id"
              class="form-control input-box"
              :class="{ 'is-invalid': form.errors.has('vimeo_app_ID') }"
              aria-describedby="appID"
              placeholder="Please enter app id"
            />
            <label for="vimeo-app-id">App ID</label>
            <has-error :form="form" field="vimeo_app_ID"></has-error>
          </div>
          <div class="form-group">
            <input
              type="text"
              v-model="form.app_secret"
              id="vimeo-app-secret"
              class="form-control input-box"
              :class="{ 'is-invalid': form.errors.has('vimeo_app_secret') }"
              aria-describedby="vimeo_app_secret"
              placeholder="Please enter app secret"
              autocomplete="false"
            />
            <label for="vimeo-app-secret">Secret</label>
            <has-error :form="form" field="vimeo_app_secret"></has-error>
          </div>
          <div class="actions d-flex justify-content-end gap-3">
            <button
              class="btn btn-cancel btn-white px-4 py-3 text-danger"
              @click="$router.push('/dashboard')"
              type="button"
            >
              Delete
            </button>
            <button class="btn btn-save btn-primary px-5" type="submit">
              Save
            </button>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import Header from "./header";

export default {
  data: function () {
    return {
      users: [],
      form: new Form({
        id: "",
        user_id: "",
        app_id: "",
        app_secret: "",
        type: "vimeo",
      }),
      editMode: false,
    };
  },
  components: {
    Header,
  },
  mounted() {
    this.getAllUsers();
    if (this.$route.query.user_id) {
      this.form.user_id = this.$route.query.user_id;
    }
  },
  methods: {
    getAllUsers() {
      this.$Progress.start();

      axios.get(`/api/user/getall`).then(({ data }) => {
        this.users = data.data;
        this.$Progress.finish();
      });
    },
    getJob(user_id, type) {
      this.$Progress.start();
      axios
        .get(`api/job/getbyuser?user_id=${user_id}&type=${type}`)
        .then(({ data }) => {
          if (data.data.length > 0) {
            this.form.fill(data.data[0]);
            this.editMode = true;
          } else {
            this.form.app_id = "";
            this.form.app_secret = "";
            this.editMode = false;
          }
          this.$Progress.finish();
        });
    },
    handleSubmit() {
      this.$Progress.start();

      if (this.editMode) {
        this.form
          .put("api/job/" + this.form.id)
          .then((response) => {
            Toast.fire({
              icon: "success",
              title: response.data.message,
            });

            this.$Progress.finish();
            this.$router.push("/vimeo-jobs");
          })
          .catch((e) => {
            Toast.fire({
              icon: "error",
              title: "Some error occured! Please try again",
            });
          });
      } else {
        this.form
          .post("api/job")
          .then((response) => {
            Toast.fire({
              icon: "success",
              title: response.data.message,
            });

            this.$Progress.finish();
            this.$router.push("/vimeo-jobs");
          })
          .catch((e) => {
            Toast.fire({
              icon: "error",
              title: "Some error occured! Please try again",
            });
          });
      }
    },
  },
  watch: {
    "form.user_id": function (val) {
      this.getJob(val, "vimeo");
    },
  },
  updated() {
  },
};
</script>

<style scoped lang="scss">
.content {
  padding: 70px 100px;

  .section-title {
    margin-bottom: 100px;
  }

  .form {
    .form-group {
      label {
        padding: 6px 15px;
      }

      .programmer-select {
        height: 60px;
        border-radius: 10px;
      }
    }
  }
}
</style>
