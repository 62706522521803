<template>
    <div>
        <Header></Header>
        <h1 class="section-title">File Distribution Hub</h1>

        <section class="content">
            <h2 class="section-title1">Active List</h2>
            <div class="controls">
                <input type="text" v-model="q" class="form-control search-input" placeholder="Search.."
                    @keyup.enter="search" />
                <button class="btn btn-primary btn-search" :disabled="!q" @click="search">
                    Search
                </button>
                <button class="btn btn-primary btn-create-programmer" @click="$router.push('/add-schedule')">
                    Add Schedule
                </button>
            </div>
            <div class="paginate">
                Show:
                <select class="form-control" v-model="perPage" @change="changePerPage">
                    <option>10</option>
                    <option>25</option>
                    <option>50</option>
                    <option>100</option>
                </select>
            </div>
            <div class="card-body table-responsive p-0">
                <table class="table table-hover table-borderless table-striped">
                    <thead>
                        <tr>
                            <th>
                                Programmer
                            </th>
                            <th>Files</th>
                            <th>Sent To</th>
                            <th>Frequency</th>
                            <th>Message</th>
                            <th></th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="one in schedule.data" :key="one.id">
                            <td>{{ one.series.name }}</td>
                            <td>{{ allFiles[one.files] }}</td>
                            <td>{{ one.email }}</td>
                            <td>{{ frequencies[one.frequency] }}</td>
                            <td>{{ one.message }}</td>
                            <td></td>
                            <td>{{ formatDate(one.sent_at) }}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="pagination">
                    <pagination :data="schedule" @pagination-change-page="getResults" :per-page="perPage"></pagination>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Header from "./header";

export default {
    components: {
        Header,
    },
    data() {
        return {
            editmode: false,
            schedule: {},
            perPage: 10,
            q: "",
            sortOrder: "",
            sortColumn: "schedule.id",
            frequencies: [
                'Daily',
                'Once a week',
                'Once a month'
            ],
            allFiles:[
                'Latest Files',
                'A Week ago'
            ]
        };
    },
    methods: {
        changePerPage() {
            window.localStorage.setItem("dashboard_per_page", this.perPage);
        },
        search() {
            this.getResults();
        },
        formatDate(value) {
            if (value) {
                const date = new Date(value);
                return date.toLocaleString();
            }
            return "";
        },
        getResults(page = 1) {
            this.$Progress.start();

            axios
                .get(
                    `api/schedule/getschedule?page=${page}&per_page=${this.perPage
                    }&q=${this.q}&sort_by=${this.sortColumn}&sort_dir=${this.sortOrder ? this.sortOrder : "desc"
                    }`
                )
                .then((response) => {
                    this.schedule = response.data.data;
                })
                .catch((e) => {
                    Toast.fire({
                        icon: "error",
                        title: "Some error occured! Please try again",
                    });
                });

            // update query param
            const queryParams = new URLSearchParams(window.location.search);
            queryParams.set("page", page);
            const newUrl = `${window.location.origin}${window.location.pathname}?${queryParams.toString()}`;
            window.history.replaceState({ path: newUrl }, "", newUrl);

            this.$Progress.finish();
        },
        handleSort(column) {
            if (this.sortColumn === column) {
                this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
            } else {
                this.sortColumn = column;
                this.sortOrder = "asc";
            }
            this.getResults(this.$route.query.page);
        },
    },
    watch: {
        perPage(oldPerPage, newPerPage) {
            this.getResults();
        },
    },
    created() {
        this.$Progress.start();
        this.getResults(this.$route.query.page);

        this.$Progress.finish();
    }
};
</script>

<style lang="scss" scoped>
.content {
    padding: 70px 50px;

    .controls {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;

        .search-input {
            width: auto;
            height: 40px;
            border-radius: 10px;
        }

        .btn-search {
            height: 40px;
            line-height: 1;
        }

        .btn-create-programmer {
            padding: 8px 20px;
            display: block;
            height: 40px;
            line-height: 1;
        }
    }

    .paginate {
        display: flex;
        align-items: center;
        width: 130px;
        padding: 10px;
        float: right;
    }

    .button-flex {
        display: flex;
    }

    .section-title {
        margin-bottom: 60px;
        font-family: "Inter";
        font-weight: 600;
        font-size: 28px;
        color: #323b4b;
    }

    .section-title1 {
        margin-bottom: 10px;
        font-family: "Inter";
        font-weight: 600;
        font-size: 24px;
        color: #323b4b;
    }

    .table-striped {
        tbody {
            tr {
                border-radius: 3px;

                &:nth-of-type(even) {
                    background-color: #dedede;
                }

                &:nth-of-type(odd) {
                    background-color: #f1f1f1;
                }
            }
        }
    }

    .table {
        border-collapse: separate;
        border-spacing: 0 10px;

        thead {
            th {
                font-weight: 700;
                font-size: 16px;
                line-height: 29px;
                color: #616978;

                .form-control {
                    width: auto;
                    display: inline-block;
                }
            }
        }

        .series-name {
            white-space: nowrap;
        }

        td {
            font-weight: 500;
            font-size: 16px;
            line-height: 29px;
            color: #8a94a6;
        }

        tr td:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        tr td:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
}
</style>
