<template>
<div>
    <Header></Header>
    <div class="d-flex">
        <div>
            <h1 class="section-title">Start an Automation</h1>
        </div>
        <div class="back-button">
            <button class="btn btn-back" @click="goBack">Back</button>
        </div>
    </div>
    <section class="content">
        <h3 class="small-title">Basic Details</h3>
        <div class="form">
            <form @submit.prevent="createSchedule()">
                <div class="form-group">
                    <v-select class="new-styles" v-model="selectedValue" label="name" :options="series" :taggable="true" @input="selectedSeries" id="series-option" placeholder="Select a Programmer"></v-select>
                    <label for="series-option">Select a Programmer</label>
                </div>
                <div class="form-group">
                    <v-select class="new-styles" v-model="form.file" label="file" :options="files" :reduce="file=>file.code" 
                        :taggable="true" id="file" placeholder="Which Files?"/>
                    <label for="frequency">Frequency</label>
                </div>
                <div class="form-group">
                    <input type="text" v-model="form.email_address" label="email_address" id="sendToEmail" class="form-control input-box" aria-describedby="sendToEmail" placeholder="Input The Email" />
                    <label for="sendToEmail">Send To Email</label>
                </div>
                <div class="form-group">
                    <v-select class="new-styles" v-model="form.frequency" label="frequency" :options="frequencies" :reduce="frequency=>frequency.code" 
                        :taggable="true" id="frequency" placeholder="Frequency"/>
                    <label for="frequency">Frequency</label>
                </div>
                <div class="form-group">
                    <textarea v-model="form.message" id="email-message" class="textarea form-control"></textarea>
                    <label for="email-message">Email Message</label>
                </div>
                <div class="actions d-flex justify-content-start gap-3">
                    <button class="btn btn-cancel btn-white px-4 py-3" @click="$router.push('/dashboard')" type="button">
                        Delete
                    </button>
                    <button class="btn btn-save btn-primary px-5" type="submit">
                        Schedule
                    </button>
                </div>
            </form>
        </div>
    </section>
</div>
</template>

<script>
import Header from "./header";

export default {
    components: {
        Header,
    },
    data: function () {
        return {
            form: new Form({
                id: "",
                name: "",
                file: "",
                email_address: "",
                frequency: 2,
                message: "",
            }),
            selectedValue: {
                name: "",
            },
            series: [],
            email_address: [],
            message: [],
            frequencies: [{
                    frequency: 'Daily', code: 0
                },
                {
                    frequency: 'Once a week', code: 1
                },
                {
                    frequency: 'Once a month', code: 2
                }
            ],
            files: [{
                    file: 'Latest File', code:0
                }
            ]
        };
    },
    methods: {
        createSchedule() {
            this.$Progress.start

            axios.post("api/schedule", {
                    name: this.form.name,
                    file: this.form.file,
                    email_address: this.form.email_address,
                    frequency: this.form.frequency,
                    message: this.form.message
                }).then((response) => {
                    Toast.fire({
                        icon: "success",
                        title: response.data.message,
                    });

                    this.$Progress.finish();
                    this.$router.push("/schedule");

                })
                .catch((e) => {
                    Toast.fire({
                        icon: "error",
                        title: "Some error occured! Please try again",
                    });
                });
        },
        selectedFile(option) {
            this.form.file = option.file;
        },
        selectedSeries(option) {
            this.form.name = option.name;
        },
        getSeries() {
            this.$Progress.start();
            axios.get("/api/series/getAll").then(({
                data
            }) => {
                this.series = data;
            });
            this.$Progress.finish();
        },
        goBack() {
            this.$router.push('/schedule');
        }
    },
    created() {
        this.$Progress.start();
        this.getSeries();
        this.$Progress.finish();
    },
    updated() {console.log(this.form.frequency)}
}
</script>

<style lang="scss">
.new-styles .vs__search {
    height: 50px;
}

.new-styles .vs__dropdown-toggle {
    width: 50% !important;
    border-radius: 15px;
}

.d-flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.back-button {
    margin-right: 20%;
}

.section-title {
    font-weight: 600;
    font-size: 28px;
    line-height: 29px;
    color: #323b4b;
    font-family: Inter;
    margin-bottom: 30px;
}

.textarea {
    height: 100px;
}

.small-title {
    font-weight: 600;
    font-size: 22px;
    line-height: 29px;
    color: #323b4b;
    font-family: Inter;
    margin-bottom: 30px;
}

.btn-back {
    font-weight: 500;
    font-size: 16px;
    line-height: 29px;
    color: #8a94a6;
}

.content {
    padding: 70px 100px;

    .form {
        .form-group {
            label {
                padding: 6px 15px;
                font-weight: 500 !important;
                font-size: 18px;
                line-height: 29px;
                color: #8a94a6;
            }
        }

        .form-group.checkbox {
            display: flex;
            flex-direction: row;
            align-items: center;

            input {
                width: 20px;

                &:focus {
                    box-shadow: none;
                }
            }

            label {
                margin-bottom: 0px;
            }
        }

        .form-control {
            width: 80%;
        }
    }

}
</style>
