<template>
  <div>
    <Header></Header>
    <section class="content">
      <h1 class="section-title">Edit Series</h1>
      <div>
        <div class="d-flex edit-grid">
          <file-upload class="d-none" v-model="files" ref="thumbnailUpload" :custom-action="uploadS3"
            @input-filter="inputFilter" accept="image/*">
          </file-upload>
          <div class="image-select">
            <img v-if="form.poster && !files.length" :src="form.poster" alt="Series Poster" class="d-block" />
            <img v-if="files.length" :src="files[0].blob" alt="Series Poster" class="d-block" />
            <ul v-if="files.length">
              <li v-for="file in files" :key="file.id">
                <span>{{ file.name }}</span> -
                <span>{{ formatBytes(file.size) }}</span> -
                <span v-if="file.error">{{ file.error }}</span>
                <span v-else-if="file.success" class="success">success
                  <i class="fa fas fa-check-circle ml-2"></i>
                </span>
                <span v-else-if="file.active">uploading...</span>
                <span v-else></span>
              </li>
            </ul>
            <label for="file" class="file-select-label">Browse Files</label>
            <input type="text" id="file" class="d-none" />
            <p class="poster-name">Series Poster</p>
            <div class="upload-btn">
              <button type="button" class="btn btn-primary px-4"
                v-if="!$refs.thumbnailUpload || !$refs.thumbnailUpload.active"
                @click.prevent="$refs.thumbnailUpload.active = true">
                <i class="fa fa-arrow-up" aria-hidden="true"></i>
                Start Upload
              </button>
              <button type="button" class="btn btn-danger px-4" v-else
                @click.prevent="$refs.thumbnailUpload.active = false">
                <i class="fa fa-stop" aria-hidden="true"></i>
                Stop Upload
              </button>
            </div>
          </div>
          <div class="edit-box">
            <div class="form-group">
              <input type="text" v-model="form.name" id="series-title" class="form-control input-box"
                aria-describedby="seriesTitle" placeholder="Series Title"
                :class="{ 'is-invalid': form.errors.has('name') }" />
              <label for="series-title">Series Title</label>
            </div>
            <div class="form-group">
              <textarea v-model="form.short_description" id="short-description" class="textarea form-control"
                :class="{ 'is-invalid': form.errors.has('short_description') }"></textarea>
              <label for="short-description">Short Description</label>
            </div>
            <div class="form-group">
              <datepicker placeholder="Select Date" v-model="form.release_date" :input-class="{
                'is-invalid': form.errors.has('release_date'),
                'form-control': true,
              }" :readonly="false">
              </datepicker>
              <label for="publish-date">Publish Date</label>
            </div>
            <div class="actions d-flex justify-content-end gap-3">
              <button class="btn btn-cancel btn-white px-4 py-3" @click="$router.go(-1)">
                Cancel
              </button>
              <button class="btn btn-save btn-primary px-5" @click="updateSeries">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import FileUpload from "vue-upload-component";
import Header from "./header";

export default {
  data: function () {
    return {
      env: {},
      form: new Form({
        id: "",
        name: "",
        short_description: "",
        release_date: "",
        poster: "",
      }),
      files: [],
    };
  },
  components: {
    Header,
    Datepicker,
    FileUpload,
  },
  methods: {
    loadUser() {
      this.$Progress.start();
      axios.get("/api/series/" + this.$route.params.series_id).then(({ data }) => {
        this.form.fill(data.data);
      });
      this.$Progress.finish();
    },
    updateSeries() {
      this.$Progress.start();

      this.form.release_date = new Date(this.form.release_date).toISOString();

      this.form
        .put("/api/series/" + this.form.id)
        .then((response) => {
          // success
          Toast.fire({
            icon: "success",
            title: response.data.message,
          });
          this.$Progress.finish();
          this.$router.go(-1);
        })
        .catch(() => {
          this.$Progress.fail();
        });
    },
    async uploadS3(fileObj, component) {
      const s3 = new AWS.S3({
        accessKeyId: this.env.AWS_ACCESS_KEY_ID,
        secretAccessKey: this.env.AWS_SECRET_ACCESS_KEY,
        region: "us-east-1",
      });
      const folderName = this.form.folder_name; // ger folder name from $gate

      const file = fileObj.file; // Get the selected file from the file input
      const fileName = file.name; // Get the file name

      const params = {
        Bucket: this.env.AWS_BUCKET,
        Key: "drop/" + folderName + "/" + fileName,
        Body: file,
        ACL: "public-read", // Set the ACL permissions to allow public read access to the file
      };

      const data = await s3.upload(params).promise();

      this.form.poster = data.Location;
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Add file

        // Filter non-image file
        // Will not be added to files
        if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
          return prevent();
        }

        // Create the 'blob' field for thumbnail preview
        newFile.blob = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
      }

      if (newFile && oldFile) {
        // Update file

        // Increase the version number
        if (!newFile.version) {
          newFile.version = 0;
        }
        newFile.version++;
      }
    },
    formatBytes(bytes, decimals) {
      if (bytes == 0) return "0 Bytes";
      var k = 1024,
        dm = decimals || 2,
        sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
  },
  created() {
    this.loadUser();
    axios.get("/api/env").then((response) => {
      this.env = response.data;
    });
  },
};
</script>

<style scoped lang="scss">
.content {
  padding: 70px 100px;

  .section-title {
    margin-bottom: 30px;
  }

  .small-title {
    font-weight: 600;
    font-size: 25px;
    line-height: 29px;
    color: #323b4b;
    font-family: Inter;
    margin-bottom: 40px;
  }

  .edit-grid {
    gap: 40px;

    .image-select {
      width: 165px;

      img {
        margin-bottom: 15px;
        width: 100%;
      }

      .file-select-label {
        height: 40px;
        width: 125px;
        background-color: #ffffff;
        border: 1px solid #b6b6b6;
        border-radius: 15px;
        font-weight: 600;
        font-size: 14px;
        line-height: 38px;
        text-align: center;
        color: #323b4b;
        margin-bottom: 10px;
      }

      .poster-name {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 29px;
        color: #8a94a6;
      }

      .success {
        i {
          color: green;
        }
      }
    }

    .edit-box {
      flex: 1;

      .form-group {
        margin-bottom: 30px;

        input,
        textarea,
        select {
          margin-bottom: 5px;
        }

        select {
          width: 370px;
          height: 50px;
          font-weight: 500;
          font-size: 14px;
          line-height: 29px;
          color: #b0b7c3;
          border-radius: 15px;
        }

        .textarea {
          height: 100px;
        }

        label {
          padding: 0px 20px;
          margin-bottom: 0;
          font-weight: 500 !important;
          font-size: 18px;
          line-height: 29px;
          color: #8a94a6;
        }
      }
    }
  }
}
</style>
