var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "quality-control align-items-center" }, [
    _vm.$gate.isAdmin()
      ? _c("div", { staticClass: "check-wrapp" }, [
          _c("input", {
            attrs: { type: "checkbox" },
            domProps: {
              value: _vm.video.id,
              checked: this.checkedVideos.includes(_vm.video.id),
            },
            on: { change: (e) => _vm.handleSelect(e, _vm.video.id) },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "thumbnail" }, [
      _c("img", {
        attrs: {
          src: _vm.video.thumbnail
            ? _vm.video.thumbnail
            : "https://via.placeholder.com/600x400",
          alt: _vm.video.title,
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.video.title))]),
    _vm._v(" "),
    _c("div", { staticClass: "series" }, [
      _vm._v(_vm._s(_vm.series && _vm.series.name)),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "release-date" }, [
      _vm._v(
        "\n    " +
          _vm._s(new Date(_vm.video.release_date).toLocaleDateString()) +
          "\n  "
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "created-at" }, [
      _vm._v(
        "\n    " +
          _vm._s(new Date(_vm.video.created_at).toLocaleDateString()) +
          "\n  "
      ),
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _vm.$gate.isAdmin() || _vm.$gate.isUser()
      ? _c("div", { staticClass: "action" }, [
          _c("a", { attrs: { href: "#" }, on: { click: _vm.gotoCheck } }, [
            _c("i", { staticClass: "fas fa-pencil-alt" }),
            _vm._v("\n      Edit"),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.$gate.isAdmin()
      ? _c("div", { staticClass: "action" }, [
          _c("a", { attrs: { href: "#" }, on: { click: _vm.gotoDelete } }, [
            _c("i", { staticClass: "fa-solid fa-trash" }),
            _vm._v("\n      Delete"),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "download" }, [
      _c("a", { attrs: { href: "#" }, on: { click: _vm.gotoDownload } }, [
        _c("i", { staticClass: "fa-solid fa-download" }),
        _vm._v(" "),
        _vm.downloading
          ? _c("span", [_vm._v("Downloading...")])
          : _c("span", [_vm._v("Download")]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "status" }, [_c("span", [_vm._v("ready")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }