var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "header d-flex justify-content-between align-items-center",
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "menu" }, [
          _vm.$gate.isAdmin()
            ? _c(
                "a",
                { staticClass: "menu-item", attrs: { href: "/dashboard" } },
                [_vm._v("Dashboard\n      ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$gate.isGuest()
            ? _c("a", { staticClass: "menu-item", attrs: { href: "/guest" } })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "menu-item",
              attrs: {
                href: _vm.$gate.isAdmin()
                  ? "/quality-uploads"
                  : "/videos/" + _vm.$gate.user.id,
              },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$gate.isAdmin()
                    ? "Quality Control"
                    : _vm.$gate.isUser()
                    ? "Videos"
                    : ""
                )
              ),
            ]
          ),
          _vm._v(" "),
          _vm.$gate.isAdmin() || _vm.$gate.isUser()
            ? _c("a", { staticClass: "menu-item", attrs: { href: "/draft" } }, [
                _vm._v("Draft"),
              ])
            : _c("a"),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "menu-item",
              attrs: { href: "mailto:onboarding@reallifenetwork.com" },
            },
            [_vm._v("Support")]
          ),
          _vm._v(" "),
          _vm.name
            ? _c("span", { staticClass: "profile-name" }, [
                _vm._v("Hi, " + _vm._s(_vm.name)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "profile-wrapper" }, [
            _c(
              "a",
              { attrs: { href: "#" }, on: { click: _vm.toggleProfile } },
              [
                _c("img", {
                  staticClass: "profile-avatar",
                  attrs: {
                    src: _vm.avatarUrl ? _vm.avatarUrl : "/images/avatar.png",
                    alt: "avatar",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _vm.showProfile
              ? _c("div", { staticClass: "submenu" }, [
                  _c(
                    "a",
                    {
                      staticClass: "submenu-item",
                      attrs: { href: "/profile" },
                    },
                    [_vm._v("Settings")]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "submenu-item",
                      attrs: { href: "#" },
                      on: { click: _vm.logout },
                    },
                    [_vm._v("Logout")]
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticClass: "site-logo", attrs: { href: "/" } }, [
      _c("img", { attrs: { src: "/images/logo.png", alt: "" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }