<template>
    <div>
        <Header></Header>
        <h1 class="section-title"></h1>

        <section class="content">
            <h2 class="section-title1">Draft List</h2>
            <div class="controls">
                <input type="text" v-model="q" class="form-control search-input" placeholder="Search.."
                    @keyup.enter="search" />
                <button class="btn btn-primary btn-search" :disabled="!q" @click="search">
                    Search
                </button>
            </div>
            <div class="paginate">
                Show:&nbsp;
                <select class="form-control" v-model="perPage" @change="changePerPage">
                    <option>10</option>
                    <option>25</option>
                    <option>50</option>
                    <option>100</option>
                </select>
            </div>
            <div class="card-body table-responsive p-0">
                <table class="table table-hover table-borderless table-striped">
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Series</th>
                            <th>Programmer</th>
                            <th>File Name</th>
                            <th>Status</th>
                            <th>Created</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="one in draft.data" :key="one.id">
                            <td>{{ one.id }}</td>
                            <td>{{ one.series.name }}</td>
                            <td>{{ one.user.email }}</td>
                            <td class="wrap">{{ one.file_name }}</td>
                            <td class="status"><span>draft</span></td>
                            <td>{{ new Date(one.created_at).toDateString() }}</td>
                            <td>
                                <a href="#" @click="goEdit(one.id)">Edit</a>
                            </td>
                            <td>
                                <a href="#" @click="goDelete(one.id)">Delete</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="pagination">
                    <pagination :data="draft" @pagination-change-page="getResults" :per-page="perPage"></pagination>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Header from "./header";

export default {
    components: {
        Header,
    },
    data() {
        return {
            editmode: false,
            draft: {},
            perPage: 10,
            q: "",
            sortOrder: "",
            sortColumn: "draft.id",
            userId: this.$gate.user.id,
        };
    },
    methods: {
        changePerPage() {
            window.localStorage.setItem("dashboard_per_page", this.perPage);
        },
        search() {
            this.getResults();
        },
        getResults(page = 1) {
            this.$Progress.start();
            axios
                .get(
                    `/api/draft/getdraftlist?userId=${this.userId}&page=${page}&per_page=${this.perPage
                    }&q=${this.q}&userId=${this.$gate.user.id}&sort_by=${this.sortColumn}&sort_dir=${this.sortOrder ? this.sortOrder : "desc"
                    }`
                )
                .then(({ data }) => {
                    this.draft = data.data;
                })
                .catch((e) => {
                    Toast.fire({
                        icon: "error",
                        title: "Some error occured! Please try again",
                    });
                });

            const queryParams = new URLSearchParams(window.location.search);
            queryParams.set("page", page);
            const newUrl = `${window.location.origin}${window.location.pathname}?${queryParams.toString()}`;
            window.history.replaceState({ path: newUrl }, "", newUrl);

            this.$Progress.finish();
        },
        handleSort(column) {
            if (this.sortColumn === column) {
                this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
            } else {
                this.sortColumn = column;
                this.sortOrder = "asc";
            }
            this.getResults(this.$route.query.page);
        },
        goEdit(id) {
            this.$router.push(`/edit-draft/${id}`);
        },
        goDelete(id) {
            axios.delete(`/api/draft/${id}`)
                .then(response => {
                    console.log(response)
                    Toast.fire({
                        icon: "success",
                        title: "Deleted",
                    });
                    this.getResults(this.$route.query.page);
                })
                .catch((e) => {
                    console.log(e)
                    Toast.fire({
                        icon: "error",
                        title: "Some error occured! Please try again",
                    });
                });
        }
    },
    watch: {
        perPage(oldPerPage, newPerPage) {
            this.getResults();
        },
    },
    created() {
        this.$Progress.start();

        this.getResults(this.$route.query.page);

        this.$Progress.finish();
    }
};
</script>

<style lang="scss" scoped>
.content {
    padding: 70px 50px;

    .controls {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;

        .search-input {
            width: auto;
            height: 40px;
            border-radius: 10px;
        }

        .btn-search {
            height: 40px;
            line-height: 1;
        }

        .btn-create-programmer {
            padding: 8px 20px;
            display: block;
            height: 40px;
            line-height: 1;
        }
    }

    .paginate {
        display: flex;
        align-items: center;
        width: 130px;
        padding: 10px;
        float: right;
    }

    .button-flex {
        display: flex;
    }

    .section-title {
        margin-bottom: 60px;
        font-family: "Inter";
        font-weight: 600;
        font-size: 28px;
        color: #323b4b;
    }

    .section-title1 {
        margin-bottom: 10px;
        font-family: "Inter";
        font-weight: 600;
        font-size: 24px;
        color: #323b4b;
    }

    .table-striped {
        tbody {
            tr {
                border-radius: 3px;

                &:nth-of-type(even) {
                    background-color: #dedede;
                }

                &:nth-of-type(odd) {
                    background-color: #f1f1f1;
                }
            }
        }
    }

    .status {
        width: 15%;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            border-radius: 4px;
            background-color: rgb(76, 9, 231);
            top: 24.5px;
            left: 25px;
        }

        span {
            display: inline-block;
            background: #fafbfc;
            border-radius: 15px;
            font-weight: 500;
            font-size: 14px;
            color: #9ba3b1;
            padding: 0 30px;
        }
    }

    .wrap {
        width: 200px;
        overflow-wrap: anywhere;
    }

    .table {
        border-collapse: separate;
        border-spacing: 0 10px;

        thead {
            th {
                font-weight: 700;
                font-size: 16px;
                line-height: 29px;
                color: #616978;

                .form-control {
                    width: auto;
                    display: inline-block;
                }
            }
        }

        .series-name {
            white-space: nowrap;
        }

        td {
            font-weight: 500;
            font-size: 16px;
            line-height: 29px;
            color: #8a94a6;
        }

        tr td:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        tr td:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }

    }
}
</style>
