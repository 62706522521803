<template>
  <div class="quality-control d-flex align-items-center">
    <div v-if="$gate.isAdmin()" class="check-wrapp">
      <input
        type="checkbox"
        :value="video.id"
        :checked="this.checkedVideos.includes(video.id)"
        @change="(e) => handleSelect(e, video.id)"
      />
    </div>
    <div class="thumbnail">
      <img
        :src="
          video.thumbnail
            ? video.thumbnail
            : 'https://via.placeholder.com/600x400'
        "
        :alt="video.title"
      />
    </div>
    <div class="title">{{ video.title }}</div>
    <div class="series"></div>
    <div class="status text-success">
      Done
    </div>
    <div class="action">
      <!-- <a href="#" @click="gotoCheck">
        <i class="fas fa-pencil-alt"></i>
        Edit</a
      > -->
    </div>
  </div>
</template>

<script>
export default {
  name: "QualityControl",
  props: ["video", "user", "checkedVideos"],
  data: function () {
    return {
      expand: false,
      checked: this.checkedVideos.includes(this.video.id),
      userInfo: null,
    };
  },
  methods: {
    loadUser() {
      this.$Progress.start();
      axios.get("/api/user/" + this.video.user_id).then(({ data }) => {
        this.userInfo = data.data;
      });
      this.$Progress.finish();
    },
    gotoCheck(event) {
      event.preventDefault();
      if (this.user && this.user.id) {
        this.$router.push(
          "/quality-control-check/" + this.video.id + "?user_id=" + this.user.id
        );
      } else {
        this.$router.push("/quality-control-check/" + this.video.id);
      }
    },
    handleSelect(e, id) {
      if (e.target.checked) {
        this.$emit("selectItem", id, true);
      } else {
        this.$emit("selectItem", id, false);
      }
    },
  },
  mounted() {
    // get user info
    this.loadUser();
  },
  updated() {},
};
</script>

<style scoped lang="scss">
.quality-control {
  margin-bottom: 30px;
  gap: 20px;
  /* .form-check {
    width: 5%;
  } */

  .thumbnail {
    width: 20%;

    img {
      width: 100%;
    }
  }

  .check-wrapp {
    width: 5%;
    padding-left: 22px;
  }

  .title {
    width: 25%;
    font-weight: 600;
    font-size: 14px;
    line-height: 29px;
    color: #4e5d78;
  }

  .series {
    width: 20%;
    font-weight: 600;
    font-size: 14px;
    line-height: 29px;
    color: #4e5d78;
  }

  .status {
    width: 15%;
    position: relative;

    span {
      display: inline-block;
      width: 120px;
      height: 25px;
      background: #fafbfc;
      border-radius: 15px;
      font-weight: 500;
      font-size: 14px;
      color: #b0b7c3;
      padding: 0 30px;
    }
  }

  .action {
    width: 15%;

    a {
      display: inline-block;
      width: 80px;
      height: 25px;
      background: #f8f8f9;
      border-radius: 15px;
      padding: 0 15px;
      font-weight: 500;
      font-size: 12px;
      line-height: 25px;
      color: #b0b7c3;

      i {
        margin-right: 4px;
      }
    }
  }
}
</style>
