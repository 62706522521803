var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Header"),
      _vm._v(" "),
      _c("section", { staticClass: "content" }, [
        _c("h1", { staticClass: "section-title" }, [_vm._v("Edit Season")]),
        _vm._v(" "),
        _c("div", [
          _c("div", { staticClass: "d-flex edit-grid" }, [
            _c("div", { staticClass: "edit-box" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.name,
                      expression: "form.name",
                    },
                  ],
                  staticClass: "form-control input-box",
                  class: { "is-invalid": _vm.form.errors.has("name") },
                  attrs: {
                    type: "text",
                    id: "seasons-title",
                    "aria-describedby": "seasonsTitle",
                    placeholder: "seasons Title",
                  },
                  domProps: { value: _vm.form.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "name", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "Season name" } }, [
                  _vm._v("Season Name"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.status,
                        expression: "form.status",
                      },
                    ],
                    staticClass: "form-control input-box",
                    class: { "is-invalid": _vm.form.errors.has("status") },
                    attrs: {
                      id: "status-title",
                      "aria-describedby": "statusTitle",
                      placeholder: "status Title",
                    },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.form,
                          "status",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "1" } }, [_vm._v("Active")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "0" } }, [
                      _vm._v("inActive"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("label", { attrs: { for: "status-select" } }, [
                  _vm._v("Status"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "actions d-flex justify-content-end gap-3" },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-cancel btn-white px-4 py-3 text-danger",
                      on: {
                        click: function ($event) {
                          return _vm.seasonDelete($event)
                        },
                      },
                    },
                    [_vm._v("\n              Delete\n            ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-save btn-primary px-5",
                      on: { click: _vm.updateSeason },
                    },
                    [_vm._v("\n              Save\n            ")]
                  ),
                ]
              ),
              _vm._v(" "),
              _vm._m(0),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "description-season" }, [
      _c("p", [
        _vm._v("Are you sure you want to delete job? (prompt to confirm)"),
      ]),
      _vm._v(" "),
      _c("p", [
        _c("b", [
          _vm._v(
            "Programmer can request deletion and needs to be QC’d before\n                removal. Provide reason for deletion."
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }