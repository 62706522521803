<template>
  <div>
    <Header></Header>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <h1 style="margin-bottom: 50px;">My Profile</h1>

          <div class="col-md-12">

            <div class="card">
              <div class="card-header p-2">
                <ul class="nav nav-pills">
                  <li class="nav-item">
                    <a class="nav-link active show" href="#settings" data-toggle="tab">Profile</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#change-password" data-toggle="tab">Change Password</a>
                  </li>
                </ul>
              </div>
              <div class="card-body">
                <div class="tab-content">
                  <div class="tab-pane active show" id="settings">
                    <div class="profile-setting">
                      <div class="upload-section">
                        <div class="border rounded vue-avatar-cropper-demo text-center">
                          <div class="p-6">
                            <div class="relative">
                              <img :src="form.avatarUrl
                                ? form.avatarUrl
                                : '/images/avatar.png'
                                " @click="showCropper = true" class="avatar" />
                            </div>
                          </div>
                          <avatar-cropper @submit="handleSubmit" @error="handlerError" v-model="showCropper" />
                        </div>
                      </div>
                      <form class="form-horizontal setting-section">
                        <div class="form-group">
                          <label for="inputName" class="col-sm-4 control-label">Name</label>

                          <div class="col-sm-12">
                            <input type="text" v-model="form.name" class="form-control" id="inputName"
                              placeholder="Name" :class="{
                                'is-invalid': form.errors.has('name'),
                              }" />
                            <has-error :form="form" field="name"></has-error>
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="inputEmail" class="col-sm-4 control-label">Email</label>
                          <div class="col-sm-12">
                            <input type="text" v-model="form.email" class="form-control" id="inputEmail"
                              placeholder="Email" :class="{
                                'is-invalid': form.errors.has('email'),
                              }" />
                            <has-error :form="form" field="email"></has-error>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="col-md-12">
                            <button @click.prevent="updateProfile" type="submit" class="btn update-btn">
                              Update Profile
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="tab-pane" id="change-password">
                    <form class="form-horizontal">
                      <div class="form-group">
                        <label for="current_password" autocomplete="false" class="col-sm-4 control-label">Current Password</label>

                        <div class="col-sm-12">
                          <input type="password" v-model="form.current_password" class="form-control"
                            id="current_password" placeholder="Current Password" :class="{
                              'is-invalid': form.errors.has('current_password'),
                            }" />
                          <has-error :form="form" field="current_password"></has-error>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="new_password" class="col-sm-4 control-label">New Password</label>

                        <div class="col-sm-12">
                          <input type="password" v-model="form.new_password" class="form-control" id="new_password"
                            placeholder="New Password" :class="{
                              'is-invalid': form.errors.has('new_password'),
                            }" />
                          <has-error :form="form" field="new_password"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="confirm_password" class="col-sm-4 control-label">Confirm Password</label>

                        <div class="col-sm-12">
                          <input type="password" v-model="form.confirm_password" class="form-control"
                            id="confirm_password" placeholder="Confirm Password" :class="{
                              'is-invalid': form.errors.has('confirm_password'),
                            }" />
                          <has-error :form="form" field="confirm_password"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="col-sm-offset-2 col-sm-12">
                          <button @click.prevent="updatePassword" type="submit" class="btn update-btn">
                            Update Password
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="row">
          <h1 style="margin-top: 100px;">My Content</h1>
          <div class="card-body table-responsive p-0">
            <table class="table table-hover table-borderless table-striped">
              <thead>
                <tr>
                  <th @click="handleSort('series.name')">
                    Series
                    <i class="fa fa-fw fa-sort"></i>
                  </th>
                  <th>Status</th>
                  <th v-if="$gate.isAdmin() || $gate.isUser()"></th>
                  <th>
                    Show:
                    <select class="form-control" v-model="perPage" @change="changePerPage">
                      <option>10</option>
                      <option>25</option>
                      <option>50</option>
                      <option>100</option>
                    </select>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="one in series.data" :key="one.id">
                  <td class="series-name">{{ one.name }}</td>
                  <td class="text-capitalize">{{ "Active" }}</td>
                  <td>
                    <a href="#" @click="goQuploads($event, one.id)">
                      View Content
                    </a>
                  </td>
                  <td v-if="$gate.isAdmin() || $gate.isUser()">
                    <a href="#" @click="goEdit($event, one.id)"> Edit Series </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="pagination">
              <pagination :data="series" @pagination-change-page="getResults" :per-page="perPage"></pagination>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Header from "./header";
import AvatarCropper from "./avatar-cropper";

export default {
  components: {
    Header,
    AvatarCropper,
  },
  data() {
    return {
      form: new Form({
        id: "",
        name: "",
        email: "",
        avatarUrl: "",
        current_password: "",
        new_password: "",
        confirm_password: "",
        created_at: "",
      }),
      showCropper: false,
      message: "ready",
      cropperFile: undefined,
      env: {},
      series: {},
      perPage: 10,
      q: "",
      sortOrder: "",
      sortColumn: "series.name",
    };
  },
  mounted() { },
  methods: {
    updatePassword() {
      this.$Progress.start();
      this.form
        .post("api/change-password")
        .then((data) => {
          this.$Progress.finish();
          this.form.current_password = "";
          this.form.new_password = "";
          this.form.confirm_password = "";

          Toast.fire({
            icon: "success",
            title: data.data.message,
          });
        })
        .catch(() => {
          this.$Progress.fail();

          Toast.fire({
            icon: "error",
            title: "Some error occured! Please try again",
          });
        });
    },
    async updateProfile() {
      if (this.cropperFile) {
        this.form.avatarUrl = await this.uploadS3(this.cropperFile);
      }
      await axios
        .post("api/change-profile", {
          name: this.form.name,
          email: this.form.email,
          avatarUrl: this.form.avatarUrl,
        })
        .then((response) => {
          Toast.fire({
            icon: "success",
            title: "Profile is updated!",
          });
          this.$Progress.finish();
        });
    },
    handlerError(message, type, xhr) {
      this.message = "Oops! Something went wrong...";
    },
    handleSubmit(cropper, croppedImageUrl) {
      this.form.avatarUrl = croppedImageUrl;
      this.cropperFile = cropper;
    },
    async uploadS3(blob, component) {
      const s3 = new AWS.S3({
        accessKeyId: this.env.AWS_ACCESS_KEY_ID,
        secretAccessKey: this.env.AWS_SECRET_ACCESS_KEY,
        region: "us-east-1",
      });
      const folderName = "profile_images"; // ger folder name from $gate

      const fileName = this.$gate.user.id + ".jpg"; // Get the file name

      const params = {
        Bucket: this.env.AWS_BUCKET,
        Key: folderName + "/" + fileName,
        Body: blob,
        ACL: "public-read", // Set the ACL permissions to allow public read access to the file
      };

      const data = await s3.upload(params).promise();
      return data.Location;
    },
    getResults(page = 1) {
      this.$Progress.start();
      console.log(this.perPage, this.$gate.isAdmin())
      if (this.$gate.isAdmin()) {
        axios
          .get(
            `api/series/getSeries?page=${page}&per_page=${this.perPage}&q=${this.q
            }&sort_by=${this.sortColumn}&sort_dir=${this.sortOrder ? this.sortOrder : "desc"
            }`
          )
          .then(({ data }) => (this.series = data.data));
      } else {
        axios
          .get(
            `api/series/getbyuserid?userId=${this.$gate.user.id
            }&page=${page}&per_page=${this.perPage}&q=${this.q}&sort_by=${this.sortColumn
            }&sort_dir=${this.sortOrder ? this.sortOrder : "desc"}`
          )
          .then(({ data }) => (this.series = data.data));
      }
      // update query param
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("page", page);
      const newUrl = `${window.location.origin}${window.location.pathname
        }?${queryParams.toString()}`;
      window.history.replaceState(
        {
          path: newUrl,
        },
        "",
        newUrl
      );

      this.$Progress.finish();
    },
    changePerPage() {
      window.localStorage.setItem("dashboard_per_page", this.perPage);
    },
    goEdit(e, series_id) {
      e.preventDefault();
      this.$router.push("/edit-series/" + series_id);
    },
    goQuploads(e, series_id) {
      e.preventDefault();
      this.$router.push("/quality-uploads/" + series_id);
    },
  },
  watch: {
    perPage(oldPerPage, newPerPage) {
      this.getResults();
    }
  },
  created() {
    this.$Progress.start();

    axios.get("/api/profile").then(({ data }) => {
      this.form.email = data.email;
      if (data.profile) {
        this.form.name = data.profile.name;
        this.form.avatarUrl = data.profile.avatar_url;
      }
    });
    axios.get("/api/env").then((response) => {
      this.env = response.data;
    });
    this.getResults();
    this.$Progress.finish();
  },
};
</script>

<style lang="scss" scoped>
.profile-setting {
  width: 100%;
  display: flex;

  .upload-section {
    width: 30%;
  }

  .setting-section {
    width: 70%;
  }
}

.update-btn {
  background: #388c9e;
  color: white;
}

.vue-avatar-cropper-demo {
  max-width: 18em;
  margin: 0 auto;
}

.avatar {
  width: 160px;
  border-radius: 6px;
  display: block;
  margin: 20px auto;
  cursor: pointer;
}

.card-img-overlay {
  display: none;
  transition: all 0.5s;
}

.card-img-overlay button {
  margin-top: 20vh;
}

.card:hover .card-img-overlay {
  display: block;
}
</style>
