<template>
  <div class="quality-control d-flex align-items-center">
    <div v-if="$gate.isAdmin()" class="check-wrapp">
      <input type="checkbox" :value="video.id" :checked="this.checkedVideos.includes(video.id)"
        @change="(e) => handleSelect(e, video.id)" />
    </div>
    <div class="thumbnail">
      <img :src="video.thumbnail
        ? video.thumbnail
        : 'https://via.placeholder.com/600x400'
        " :alt="video.title" />
    </div>
    <div class="title">{{ video.title }}</div>
    <div class="series">{{ series && series.name }}</div>
    <div class="status">
      <span>ready</span>
    </div>
    <div v-if="$gate.isAdmin() || $gate.isUser()" class="action">
      <a href="#" @click="gotoCheck">
        <i class="fas fa-pencil-alt"></i>
        Edit</a>
    </div>
    <div v-if="$gate.isAdmin()" class="action">
      <a href="#" @click="gotoDelete">
        <i class="fa-solid fa-trash"></i>
        Delete</a>
    </div>
    <div class="download">
      <a href="#" @click="gotoDownload">
        <i class="fa-solid fa-download"></i>
        <span v-if="downloading">Downloading...</span> 
        <span v-else>Download</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "QualityControl",
  props: ["video", "checkedVideos"],
  data: function () {
    return {
      expand: false,
      checked: this.checkedVideos.includes(this.video.id),
      series: null,
      downloading: false,
    };
  },
  methods: {
    loadUser() {
      this.$Progress.start();
      axios.get("/api/series/" + this.video.series_id).then(({ data }) => {
        this.series = data.data;
      });
      this.$Progress.finish();
    },
    gotoCheck(event) {
      event.preventDefault();
      if (this.series && this.series.id) {
        this.$router.push(
          "/quality-control-check/" + this.video.id + "?series_id=" + this.series.id
        );
      } else {
        this.$router.push("/quality-control-check/" + this.video.id);
      }
    },
    gotoDelete(event) {
      event.preventDefault();
      this.$Progress.start();
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        input: "text",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
        inputPlaceholder: "Write a reason!",
        preConfirm: (reason) => {
          // if (reason === "")
            // Swal.showValidationMessage("You need to write a reason!");
        },
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete(`/api/video/${this.video.id}?reason=${result.value}&userId=${this.$gate.user.id}`).then(({ data }) => {
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            this.$emit("reload");
          }).catch((data) => {
            Swal.fire("Failed!", data.message, "warning");
          });
        }
      });
      this.$Progress.finish();
    },

    gotoDownload(event){
      if (this.downloading) return;
      event.preventDefault();
      this.downloading = true;

      axios({
        url: `/api/video/getVideoInfo?video_id=${this.video.id}`,
        method: 'POST',
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `video-${this.video.id}.zip`);
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
          
          const videoLink = document.createElement('a');
          videoLink.href = this.video.url;
          videoLink.download = `video-${this.video.id}.mp4`;
          videoLink.click();
      })
      .catch((error) => {
          console.error('Download error:', error);
      })
      .finally(() => {
          this.downloading = false;
      });
    },
    handleSelect(e, id) {
      if (e.target.checked) {
        this.$emit("selectItem", id, true);
      } else {
        this.$emit("selectItem", id, false);
      }
    },
  },
  mounted() {
    // get user info
    this.loadUser();
  },
  updated() { },
};
</script>

<style scoped lang="scss">
.quality-control {
  margin-bottom: 30px;
  gap: 20px;
  /* .form-check {
    width: 5%;
  } */

  .thumbnail {
    width: 20%;

    img {
      width: 100%;
    }
  }

  .check-wrapp {
    width: 5%;
    padding-left: 22px;
  }

  .title {
    width: 25%;
    font-weight: 600;
    font-size: 14px;
    line-height: 29px;
    color: #4e5d78;
  }

  .series {
    width: 20%;
    font-weight: 600;
    font-size: 14px;
    line-height: 29px;
    color: #4e5d78;
  }

  .status {
    width: 15%;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background-color: green;
      top: 10.5px;
      left: 12px;
    }

    span {
      display: inline-block;
      width: 120px;
      height: 25px;
      background: #fafbfc;
      border-radius: 15px;
      font-weight: 500;
      font-size: 14px;
      color: #b0b7c3;
      padding: 0 30px;
    }
  }

  .action {
    width: 15%;

    a {
      display: inline-block;
      width: 100px;
      height: 25px;
      background: #f8f8f9;
      border-radius: 15px;
      padding: 0 15px;
      font-weight: 500;
      font-size: 12px;
      line-height: 25px;
      color: #b0b7c3;

      i {
        margin-right: 4px;
      }
    }
  }

  .download {
    width: 15%;

    a {
      display: inline-flex;
      width: 100px;
      height: 25px;
      background: #f8f8f9;
      border-radius: 15px;
      padding: 0 15px;
      font-weight: 500;
      font-size: 12px;
      line-height: 25px;
      color: #b0b7c3;
      align-items: center;

      i {
        margin-right: 4px;
      }
    }
  }
}
</style>
