/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");
import Vue from "vue";
import moment from "moment";
import { Form, HasError, AlertError } from "vform";
import * as Sentry from "@sentry/vue";
import Gate from "./Gate";
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

Vue.use(Antd);

window.Form = Form;

Vue.prototype.$gate = new Gate(window.user);

import Swal from "sweetalert2";
import { VueSelect } from "vue-select";
import "vue-select/dist/vue-select.css";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
window.Swal = Swal;
window.Toast = Toast;

import VueProgressBar from "vue-progressbar";
Vue.use(VueProgressBar, {
  color: "rgb(143, 255, 199)",
  failedColor: "red",
  height: "3px",
});

Vue.component(HasError.name, HasError);
Vue.component(AlertError.name, AlertError);
Vue.component("v-select", VueSelect);

/**
 * Routes imports and assigning
 */
import VueRouter from "vue-router";
Vue.use(VueRouter);
import routes from "./routes";

const router = new VueRouter({
  mode: "history",
  routes,
});
// Routes End

// Sentry
Sentry.init({
  Vue,
  dsn: "https://a396b19c01a643019c62221eaa6154af@o4505107281870848.ingest.sentry.io/4505107285147648",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.3, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// Components
Vue.component("pagination", require("laravel-vue-pagination"));
// Vue.component('dashboard', require('./components/Dashboard.vue'));

Vue.component(
  "passport-clients",
  require("./components/passport/Clients.vue").default
);

Vue.component(
  "passport-authorized-clients",
  require("./components/passport/AuthorizedClients.vue").default
);

Vue.component(
  "passport-personal-access-tokens",
  require("./components/passport/PersonalAccessTokens.vue").default
);

Vue.component("not-found", require("./components/NotFound.vue").default);

// Filter Section

Vue.filter("myDate", function (created) {
  return moment(created).format("MMMM Do YYYY");
});

Vue.filter("yesno", (value) =>
  value
    ? '<i class="fas fa-check green"></i>'
    : '<i class="fas fa-times red"></i>'
);

// end Filter

const app = new Vue({
  el: "#app",
  router,
});
