<template>
  <div>
    <Header></Header>
    <section class="content">
      <h1 class="section-title">Vimeo Videos</h1>
      <div class="controls">
        <input
          type="text"
          v-model="q"
          class="form-control search-input"
          placeholder="Search.."
        />
        <button class="btn btn-primary" :disabled="!q" @click="getResults">
          Search
        </button>
        <button
          v-if="$gate.isAdmin()"
          class="btn btn-primary"
          @click="runScript"
        >
          Run
        </button>
        <div class="paginate-select">
          <span>Show:</span>
          <select class="form-control" v-model="perPage">
            <option>10</option>
            <option>25</option>
            <option>50</option>
            <option>100</option>
          </select>
        </div>
      </div>
      <div class="qc-uploads-header">
        <div class="check-wrap">
          <input
            class=""
            type="checkbox"
            @change="handleChange"
            v-model="checkAll"
          />
        </div>
        <div class="select-wrap">Select All</div>
        <div class="bulk-action">
          <div class="action-wrapp">
            <div class="button" @click="() => (expand = !expand)">
              Bulk Action
              <i
                :class="
                  expand ? 'ml-2 fa fa-chevron-up' : 'ml-2 fa fa-chevron-down'
                "
              ></i>
            </div>
            <div v-if="expand" class="expand-popup">
              <div class="button" @click="approveItems">Download</div>
              <div class="button" @click="denyItems">Remove</div>
            </div>
          </div>
        </div>
        <div class="series"></div>
        <div class="status">Status</div>
        <div class="title"></div>
      </div>
      <div>
        <VimeoVideo
          v-for="video in videos.data"
          :key="video.id"
          :video="video"
          :user="user"
          :checkedVideos="checkedVideos"
          @selectItem="selectItem"
        >
        </VimeoVideo>
        <h2 v-if="videos.data && !videos.data.length">
          No approved episodes for this user
        </h2>
      </div>
      <div>
        <pagination
          :data="videos"
          @pagination-change-page="getResults"
          :limit="1"
          :per-page="perPage"
        ></pagination>
      </div>
    </section>
  </div>
</template>

<script>
import Header from "./header";
import VimeoVideo from "./VimeoVideo";

export default {
  data: function () {
    return {
      user: {},
      videos: {},
      q: "",
      perPage: 10,
      expand: false,
      checkedVideos: [],
      checkAll: false,
    };
  },
  components: {
    Header,
    VimeoVideo,
  },
  methods: {
    getResults(page = 1) {
      this.$Progress.start();
      if (this.user.id) {
        axios
          .get(
            `/api/video/getbyfolder?folder_name=${this.user.folder_name}&page=${page}&per_page=${this.perPage}&q=${this.q}`
          )
          .then(({ data }) => (this.videos = data.data));
      } else {
        axios
          .get(`/api/video?page=${page}&per_page=${this.perPage}&q=${this.q}`)
          .then(({ data }) => (this.videos = data.data));
      }
      this.$Progress.finish();
    },
    async loadVideos() {
      this.$Progress.start();
      if (this.$route.params.user_id) {
        const res = await axios.get("/api/user/" + this.$route.params.user_id);
        this.user = res.data.data;
        const resp = await axios.get(
          "/api/video/getbyfolder?folder_name=" + res.data.data.folder_name
        );
        this.videos = resp.data.data;
      } else {
        axios.get("/api/video").then(({ data }) => (this.videos = data.data));
      }
      this.$Progress.finish();
    },
    approveItems() {
      setTimeout(() => {
        this.expand = false;
      }, 300);
      if (this.checkedVideos.length === 0) {
        Toast.fire({
          icon: "warning",
          title: "Please select videos to approve!",
        });
        return;
      }
      this.$Progress.start();
      axios
        .post("/api/video/approveitems", { ids: this.checkedVideos })
        .then(({ data }) => {
          if (data.success) {
            Toast.fire({
              icon: "success",
              title: data.message,
            });
            this.checkedVideos = [];
            this.loadVideos();
          }
        });
      this.$Progress.finish();
    },
    denyItems() {
      setTimeout(() => {
        this.expand = false;
      }, 300);
      if (this.checkedVideos.length === 0) {
        Toast.fire({ icon: "warning", title: "Please select videos to deny!" });
        return;
      }
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        input: "text",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete them!",
        inputPlaceholder: "Write a reason!",
        preConfirm: (reason) => {
          if (reason === "")
            Swal.showValidationMessage("You need to write a reason!");
        },
      }).then((result) => {
        if (result.value) {
          axios
            .post("/api/video/denyitems", {
              ids: this.checkedVideos,
              reason: result.value,
            })
            .then(({ data }) => {
              if (data.success) {
                Toast.fire({
                  icon: "success",
                  title: data.message,
                });
                this.checkedVideos = [];
                this.loadVideos();
              }
            })
            .catch((data) => {
              Swal.fire("Failed!", data.message, "warning");
            });
        }
      });
    },
    runScript() {
      // this.$router.push(
      //   `/upload-video?user_id=${this.user.id}&folder_name=${this.user.folder_name}`
      // );
    },
    selectItem(item, add) {
      // if select all is checked
      if (this.checkAll) {
        this.checkAll = false;
      }
      if (add) {
        this.checkedVideos.push(item);
      } else {
        var ind = this.checkedVideos.indexOf(item);
        this.checkedVideos.splice(ind, 1);
      }
    },
    handleChange(e) {
      if (e.target.checked) {
        this.checkedVideos = this.videos.data.map((video) => video.id);
      } else {
        this.checkedVideos = [];
      }
    },
  },
  watch: {
    perPage() {
      this.getResults();
    },
    checkedVideos() {
      if (this.checkedVideos.length === this.videos.data.length) {
        this.checkAll = true;
      }
    },
  },
  created() {
    this.loadVideos();
  },
  updated() {
  },
};
</script>

<style scoped lang="scss">
.content {
  padding: 70px 100px;

  .section-title {
    margin-bottom: 50px;
  }

  .controls {
    align-items: center;
    justify-content: flex-end;
    display: flex;
    gap: 10px;
    margin-bottom: 40px;

    .search-input {
      width: 276px;
      background: #ffffff;
      border: 1px solid #c6c6c6;
      border-radius: 15px;
      height: 40px;
      font-family: "Inter";
      font-weight: 500;
      font-size: 14px;
      line-height: 29px;
    }

    .btn {
      width: 110px;
      height: 40px;
      font-family: "Inter";
      font-weight: 600;
      font-size: 14px;
      line-height: 29px;
      color: #ffffff;
    }

    .paginate-select {
      display: flex;
      align-items: center;

      span {
        padding: 16px;
      }

      select {
        height: 40px;
        font-family: "Inter";
        font-weight: 500;
        font-size: 14px;
        line-height: 29px;
        border-radius: 15px;
      }
    }
  }
  .qc-uploads-header {
    background: #f5f5f5;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    border-radius: 15px;
    gap: 20px;
    margin-bottom: 30px;

    div {
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #4e5d78;
    }

    .check-wrap {
      width: 5%;
      padding-left: 22px;
    }
    .select-wrap {
      width: 20%;
    }
    .bulk-action {
      width: 25%;
      .action-wrapp {
        position: relative;

        .expand-popup {
          position: absolute;
          width: 100%;
          background: #f6f6f6;
          border-radius: 15px;
        }

        .button {
          cursor: pointer;
          padding: 12px;
          &:hover {
            background-color: #f0eded;
          }
        }
      }
    }

    .series {
      width: 20%;
    }
    .title {
      width: 15%;
      padding-left: 30px;
    }
    .status {
      width: 15%;
      padding-left: 20px;
    }
  }
}
</style>
