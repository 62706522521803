<template>
  <div>
    <Header></Header>
    <section class="content">
      <h1 class="section-title">Vimeo Jobs List</h1>

      <div class="controls" v-if="$gate.isAdmin()">
        <!-- <input
          type="text"
          v-model="q"
          class="form-control search-input"
          placeholder="Search.."
          @keyup.enter="search"
        />
        <button
          class="btn btn-primary btn-search"
          :disabled="!q"
          @click="search"
        >
          Search
        </button> -->
        <button
          class="btn btn-primary btn-create-programmer"
          @click="$router.push('/vimeo-job')"
        >
          Create a Job
        </button>
      </div>

      <div class="card-body table-responsive p-0" v-if="$gate.isAdmin()">
        <table class="table table-hover table-borderless table-striped">
          <thead>
            <tr>
              <th @click="handleSort('name')">
                Programmer
                <i
                  class="fa fa-fw"
                  :class="
                    sortOrder === 'asc'
                      ? 'fa-sort-asc'
                      : sortOrder === 'desc'
                      ? 'fa-sort-desc'
                      : 'fa-sort'
                  "
                ></i>
              </th>
              <th>Last Run</th>
              <th>Status</th>
              <th>
                Show:
                <select class="form-control" v-model="perPage">
                  <option>10</option>
                  <option>25</option>
                  <option>50</option>
                  <option>100</option>
                </select>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="programmer in users.data" :key="programmer.id">
              <td>{{ programmer.name }}</td>
              <td>{{ programmer.jobs[0].last_run }}</td>
              <td class="text-capitalize">
                {{ programmer.jobs[0].status }}
              </td>
              <td>
                <a :href="`/vimeo-job?user_id=${programmer.id}`" @click="">
                  <i class="fa fa-pencil"></i>
                  Edit
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <pagination
            :data="users"
            @pagination-change-page="getResults"
            :per-page="perPage"
          ></pagination>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Header from "./header";

export default {
  components: {
    Header,
  },
  data() {
    return {
      editmode: false,
      users: {},
      form: new Form({
        id: "",
        type: "",
        name: "",
        email: "",
        password: "",
        email_verified_at: "",
      }),
      perPage: 10,
      q: "",
      sortOrder: "",
      sortColumn: "id",
    };
  },
  methods: {
    search() {
      this.getResults();
    },
    getResults(page = 1) {
      this.$Progress.start();

      axios
        .get(
          `api/user/getwithjobs?page=${page}&per_page=${this.perPage}&q=${
            this.q
          }&sort_by=${this.sortColumn}&sort_dir=${
            this.sortOrder ? this.sortOrder : "desc"
          }`
        )
        .then(({ data }) => (this.users = data.data));

      // update query param
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("page", page);
      const newUrl = `${window.location.origin}${
        window.location.pathname
      }?${queryParams.toString()}`;
      window.history.replaceState({ path: newUrl }, "", newUrl);

      this.$Progress.finish();
    },
    goEdit(e, user_id) {
      e.preventDefault();
      this.$router.push("/edit-series/" + user_id);
    },
    goQuploads(e, user_id) {
      e.preventDefault();
      this.$router.push("/quality-uploads/" + user_id);
    },
    handleSort(column) {
      if (this.sortColumn === column) {
        this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = column;
        this.sortOrder = "asc";
      }
      this.getResults(this.$route.query.page);
    },
  },
  watch: {
    perPage(oldPerPage, newPerPage) {
      this.getResults();
    },
  },
  created() {
    this.getResults(this.$route.query.page);
  },
  beforeCreate() {
    if (this.$gate.isUser()) {
      this.$router.push("/upload-video");
    }
  },
  updated() {
  },
};
</script>

<style scoped lang="scss">
.content {
  padding-top: 70px;

  .controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

    .search-input {
      width: auto;
      height: 40px;
      border-radius: 10px;
    }
    .btn-search {
      height: 40px;
      line-height: 1;
    }
    .btn-create-programmer {
      padding: 8px 20px;
      display: block;
      height: 40px;
      line-height: 1;
    }
  }

  .section-title {
    margin-bottom: 100px;
  }

  .table-striped {
    tbody {
      tr {
        border-radius: 3px;

        &:nth-of-type(even) {
          background-color: #dedede;
        }

        &:nth-of-type(odd) {
          background-color: #f1f1f1;
        }
      }
    }
  }

  .table {
    border-collapse: separate;
    border-spacing: 0 10px;

    thead {
      th {
        font-weight: 700;
        font-size: 16px;
        line-height: 29px;
        color: #616978;

        .form-control {
          width: auto;
          display: inline-block;
        }
      }
    }

    td {
      font-weight: 500;
      font-size: 16px;
      line-height: 29px;
      color: #8a94a6;
    }

    tr td:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    tr td:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}
</style>
