var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "content" }, [
    _c("div", { staticClass: "container-fluid" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c("passport-clients"),
            _vm._v(" "),
            _c("passport-authorized-clients"),
            _vm._v(" "),
            _c("passport-personal-access-tokens"),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }