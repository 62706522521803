<template>
  <div>
    <Header></Header>
    <section class="content">
      <h1 class="section-title">
        {{ series && series ? series.name : "Videos" }}
      </h1>
      <div v-if="series.id" class="controls">
        <input
          type="text"
          v-model="q"
          class="form-control search-input"
          placeholder="Search.."
          @keyup.enter="getResults"
        />
        <button class="btn btn-primary" :disabled="!q" @click="getResults">
          Search
        </button>
        <button
          v-if="$gate.isAdmin()"
          class="btn btn-primary"
          @click="goToUpload"
        >
          Upload
        </button>
        <div class="paginate-select">
          <span>Show:</span>
          <select class="form-control" v-model="perPage">
            <option>10</option>
            <option>25</option>
            <option>50</option>
            <option>100</option>
          </select>
        </div>
      </div>
      <div v-else class="qc-uploads-header">
        <div class="select-wrap">Select (1)</div>
        <div class="bulk-action">
          <div class="action-wrapp">
            <div class="button" @click="() => (expand = !expand)">
              Bulk Action
              <i
                :class="
                  expand ? 'ml-2 fa fa-chevron-up' : 'ml-2 fa fa-chevron-down'
                "
              ></i>
            </div>
            <div v-if="expand" class="expand-popup">
              <div class="button" @click="approveItems">Approved</div>
              <div class="button" @click="denyItems">Denied</div>
            </div>
          </div>
        </div>
        <div class="series">Series</div>
        <div class="status">Status</div>
        <div class="title"></div>
      </div>
      <div>
        <QualityControl
          v-for="video in videos.data"
          :key="video.id"
          :video="video"
          :series="series"
          :checkedVideos="checkedVideos"
          @selectItem="selectItem"
          @reload="getResults"
        >
        </QualityControl>
        <h2 v-if="videos.data && !videos.data.length">
          No approved episodes for you
        </h2>
      </div>
      <div>
        <pagination
          :data="videos"
          @pagination-change-page="getResults"
          :per-page="perPage"
        ></pagination>
      </div>
    </section>
  </div>
</template>

<script>
import Header from "./header";
import QualityControl from "./QualityControl";

export default {
  data: function () {
    return {
      series: {},
      videos: {},
      q: "",
      perPage: 10,
      expand: false,
      checkedVideos: [],
      checkAll: false,
    };
  },
  components: {
    Header,
    QualityControl,
  },
  methods: {
    getResults(page = 1) {
      this.$Progress.start();

      if (this.$route.params.user_id) {
        axios
          .get(
            `/api/video/getbyuserid?user_id=${this.$route.params.user_id}&page=${page}&per_page=${this.perPage}&q=${this.q}`
          )
          .then(({ data }) => (this.videos = data.data));
      }

      // update query param
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("page", page);
      const newUrl = `${window.location.origin}${
        window.location.pathname
      }?${queryParams.toString()}`;
      window.history.replaceState({ path: newUrl }, "", newUrl);

      this.$Progress.finish();
    },
    getSeries() {
      this.$Progress.start();
      if (this.$route.params.series_id) {
        axios
          .get("/api/series/" + this.$route.params.series_id)
          .then(({ data }) => (this.series = data.data));
      }
      this.$Progress.finish();
    },
    goToUpload() {
      this.$router.push(
        `/upload-video?series_id=${this.series.id}&folder_name=${this.series.folder_name}`
      );
    },
    selectItem(item, add) {
      // if select all is checked
      if (this.checkAll) {
        this.checkAll = false;
      }
      if (add) {
        this.checkedVideos.push(item);
      } else {
        var ind = this.checkedVideos.indexOf(item);
        this.checkedVideos.splice(ind, 1);
      }
    },
    handleChange(e) {
      if (e.target.checked) {
        this.checkedVideos = this.videos.data.map((video) => video.id);
      } else {
        this.checkedVideos = [];
      }
    },
  },
  watch: {
    perPage() {
      this.getResults();
    },
    checkedVideos() {
      if (this.checkedVideos.length === this.videos.data.length) {
        this.checkAll = true;
      }
    },
  },
  created() {
    this.getSeries();
    this.getResults(this.$route.query.page);
  },
};
</script>

<style scoped lang="scss">
.content {
  padding: 70px 100px;

  .section-title {
    margin-bottom: 50px;
  }

  .controls {
    align-items: center;
    justify-content: flex-end;
    display: flex;
    gap: 10px;
    margin-bottom: 40px;

    .search-input {
      width: 276px;
      background: #ffffff;
      border: 1px solid #c6c6c6;
      border-radius: 15px;
      height: 40px;
      font-family: "Inter";
      font-weight: 500;
      font-size: 14px;
      line-height: 29px;
    }

    .btn {
      width: 110px;
      height: 40px;
      font-family: "Inter";
      font-weight: 600;
      font-size: 14px;
      line-height: 29px;
      color: #ffffff;
    }

    .paginate-select {
      display: flex;
      align-items: center;

      span {
        padding: 16px;
      }

      select {
        height: 40px;
        font-family: "Inter";
        font-weight: 500;
        font-size: 14px;
        line-height: 29px;
        border-radius: 15px;
      }
    }
  }

  .qc-uploads-header {
    background: #f5f5f5;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    border-radius: 15px;
    gap: 20px;
    margin-bottom: 30px;

    div {
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #4e5d78;
    }

    .check-wrap {
      width: 5%;
      padding-left: 22px;
    }

    .select-wrap {
      width: 20%;
    }

    .bulk-action {
      width: 25%;

      .action-wrapp {
        position: relative;

        .expand-popup {
          position: absolute;
          width: 100%;
          background: #f6f6f6;
          border-radius: 15px;
        }

        .button {
          cursor: pointer;
          padding: 12px;

          &:hover {
            background-color: #f0eded;
          }
        }
      }
    }

    .series {
      width: 20%;
    }

    .title {
      width: 15%;
      padding-left: 30px;
    }

    .status {
      width: 15%;
      padding-left: 20px;
    }
  }
}
</style>
