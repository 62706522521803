var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c("Header"),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c("h1", { staticClass: "title" }, [_vm._v("Success!")]),
        _vm._v(" "),
        _c(
          "p",
          { staticClass: "message" },
          [
            _vm._v(
              "\n      Your video file(s) have successfully been uploaded to Real Life Network.\n      Our team is actively reviewing your submission. Once the file(s) are\n      approved you will be notified via email.\n\n      "
            ),
            _c("br"),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _vm._l(_vm.videoTitles, function (title, index) {
              return _c("span", { key: index }, [
                _vm._v(_vm._s(title)),
                _c("br"),
              ])
            }),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c(
              "button",
              { staticClass: "upload-btn", on: { click: _vm.goToUpload } },
              [_vm._v("\n        Upload More\n      ")]
            ),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }