var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Header"),
      _vm._v(" "),
      _c("section", { staticClass: "content" }, [
        _c("h1", { staticClass: "section-title" }, [_vm._v("Create a User")]),
        _vm._v(" "),
        _c("div", { staticClass: "form" }, [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.createUser()
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("v-select", {
                    staticClass: "new-styles",
                    attrs: {
                      label: "type_name",
                      options: _vm.types,
                      reduce: (type_name) => type_name.value,
                      taggable: true,
                      id: "types-option",
                      placeholder: "Select User Type",
                    },
                    model: {
                      value: _vm.form.type_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "type_name", $$v)
                      },
                      expression: "form.type_name",
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "types-option" } }, [
                    _vm._v("User Type"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              this.form.type_name != "guest"
                ? _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("v-select", {
                        staticClass: "new-styles",
                        attrs: {
                          label: "name",
                          options: _vm.series,
                          taggable: true,
                          id: "series-option",
                          placeholder: "Select Series",
                        },
                        on: { input: _vm.selectedSeries },
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "series-option" } }, [
                        _vm._v("Series Name"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              this.form.type_name != "guest"
                ? _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.folder_name,
                            expression: "form.folder_name",
                          },
                        ],
                        staticClass: "form-control input-box",
                        class: {
                          "is-invalid": _vm.form.errors.has("folder_name"),
                        },
                        attrs: {
                          type: "text",
                          id: "folder-name",
                          "aria-describedby": "foldername",
                          placeholder: "Folder name",
                        },
                        domProps: { value: _vm.form.folder_name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "folder_name",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "folder-name" } }, [
                        _vm._v("Folder Name"),
                      ]),
                      _vm._v(" "),
                      _c("has-error", {
                        attrs: { form: _vm.form, field: "folder_name" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "form-group checkbox" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.require_user,
                      expression: "form.require_user",
                    },
                  ],
                  staticClass: "form-control check-box",
                  attrs: { type: "checkbox", id: "require_user" },
                  domProps: {
                    checked: Array.isArray(_vm.form.require_user)
                      ? _vm._i(_vm.form.require_user, null) > -1
                      : _vm.form.require_user,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.form.require_user,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.form,
                              "require_user",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.form,
                              "require_user",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.form, "require_user", $$c)
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "require_user" } }, [
                  _vm._v("Require User Access"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.email,
                        expression: "form.email",
                      },
                    ],
                    staticClass: "form-control input-box",
                    class: { "is-invalid": _vm.form.errors.has("email") },
                    attrs: {
                      type: "text",
                      id: "programmer-email",
                      disabled: !_vm.form.require_user,
                      "aria-describedby": "programmerEmail",
                      placeholder: "Programmer Email",
                    },
                    domProps: { value: _vm.form.email },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "email", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "programmer-email" } }, [
                    _vm._v("Email"),
                  ]),
                  _vm._v(" "),
                  _c("has-error", {
                    attrs: { form: _vm.form, field: "email" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.password,
                        expression: "form.password",
                      },
                    ],
                    staticClass: "form-control input-box",
                    class: { "is-invalid": _vm.form.errors.has("password") },
                    attrs: {
                      type: "password",
                      id: "programmer-pass",
                      disabled: !_vm.form.require_user,
                      "aria-describedby": "password",
                      placeholder: "Password for programmer",
                      autocomplete: "false",
                    },
                    domProps: { value: _vm.form.password },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "password", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "programmer-pass" } }, [
                    _vm._v("Password"),
                  ]),
                  _vm._v(" "),
                  _c("has-error", {
                    attrs: { form: _vm.form, field: "password" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              this.form.type_name != "admin"
                ? _c("div", { staticClass: "controls-wrapper" }, [
                    _c("div", { staticClass: "all-wrapper" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.watch_all,
                            expression: "form.watch_all",
                          },
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.form.watch_all)
                            ? _vm._i(_vm.form.watch_all, null) > -1
                            : _vm.form.watch_all,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.form.watch_all,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.form,
                                    "watch_all",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.form,
                                    "watch_all",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.form, "watch_all", $$c)
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("  Select all")]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "controls" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.q,
                            expression: "q",
                          },
                        ],
                        staticClass: "form-control search-input",
                        attrs: { type: "text", placeholder: "Search.." },
                        domProps: { value: _vm.q },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.search.apply(null, arguments)
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.q = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary btn-search",
                          attrs: { type: "button", disabled: !_vm.q },
                          on: { click: _vm.search },
                        },
                        [_vm._v("\n              Search\n            ")]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              this.form.type_name != "admin"
                ? _c("div", { staticClass: "card-body table-responsive p-0" }, [
                    _c(
                      "table",
                      {
                        staticClass:
                          "table table-hover table-borderless table-striped",
                      },
                      [
                        _c("thead", [
                          _c("tr", [
                            _c(
                              "th",
                              {
                                staticClass: "series-wrap",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleSort("series.name")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  Series\n                  "
                                ),
                                _c("i", { staticClass: "fa fa-fw fa-sort" }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("th"),
                            _vm._v(" "),
                            _c("th", [_vm._v("Status")]),
                            _vm._v(" "),
                            _c("th"),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                "\n                  Show:\n                  "
                              ),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.perPage,
                                      expression: "perPage",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.perPage = $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                      _vm.changePerPage,
                                    ],
                                  },
                                },
                                [
                                  _c("option", [_vm._v("10")]),
                                  _vm._v(" "),
                                  _c("option", [_vm._v("25")]),
                                  _vm._v(" "),
                                  _c("option", [_vm._v("50")]),
                                  _vm._v(" "),
                                  _c("option", [_vm._v("100")]),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(_vm.seriesAll.data, function (one) {
                            return _c("tr", { key: one.id }, [
                              _c("td", { staticClass: "check-wrap" }, [
                                _c("input", {
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: _vm.form.checked_series.includes(
                                      one.id
                                    ),
                                  },
                                  on: {
                                    change: (e) => _vm.handleChange(e, one.id),
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "series-name" }, [
                                _vm._v(_vm._s(one.name)),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-capitalize" }, [
                                _vm._v(_vm._s("Active")),
                              ]),
                              _vm._v(" "),
                              _c("td"),
                              _vm._v(" "),
                              _c("td", [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goQuploads($event, one.id)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    View Content\n                  "
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          }),
                          0
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "pagination" },
                      [
                        _c("pagination", {
                          attrs: {
                            data: _vm.seriesAll,
                            "per-page": _vm.perPage,
                          },
                          on: { "pagination-change-page": _vm.getResults },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "actions d-flex justify-content-end gap-3" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-cancel btn-white px-4 py-3",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push("/dashboard")
                        },
                      },
                    },
                    [_vm._v("\n            Cancel\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-save btn-primary px-5",
                      attrs: { type: "submit" },
                    },
                    [_vm._v("\n            Save\n          ")]
                  ),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }