<template>
  <div>
    <Header></Header>
    <section class="content">
      <h1 class="section-title">
        {{
          this.$route.params.series_id ? series.name : "Quality Control Uploads"
        }}
      </h1>
      <div v-if="series.id" class="controls">
        <button
          v-if="$gate.isAdmin() || $gate.isUser()"
          class="btn btn-primary"
          @click="goToUpload"
        >
          Upload
        </button>
        <div class="paginate-select">
          <span>Show:</span>
          <select class="form-control" v-model="perPage">
            <option>10</option>
            <option>25</option>
            <option>50</option>
            <option>100</option>
          </select>
        </div>
        <!-- <button
          v-if="$gate.isAdmin() || $gate.isUser()"
          class="btn btn-outline"
          @click="onSort('title')"
        >
          <img v-if="!sort" src="/images/sort.png" />
          <span v-if="sort && ascending" style="font-size: 16px"
            >↑&nbsp;&nbsp;</span
          >
          <span v-if="sort && !ascending" style="font-size: 16px"
            >↓&nbsp;&nbsp;</span
          >
          Sort
        </button> -->
        <div class="filter">
          <button
            v-if="$gate.isAdmin() || $gate.isUser()"
            class="btn btn-outline"
            @click="onFilter"
          >
            <img v-if="!q" src="/images/filter.svg" />
            <img v-if="q" src="/images/filtered.svg" />
            &nbsp;&nbsp; Filter
          </button>
          <div v-if="showFilter" class="expand-filter">
            <input
              type="text"
              v-model="q"
              class="form-control search-input"
              placeholder="Search.."
              @keyup.enter="getResults"
            />
            <button class="btn btn-primary" @click="getResults">Search</button>
          </div>
        </div>
        <button v-if="$gate.isAdmin()" class="btn btn-primary" @click="onApply">
          Apply Changes
        </button>
      </div>
      <div v-if="!series.id">
        <div class="qc-uploads-header">
          <div class="check-wrap">
            <input
              class=""
              type="checkbox"
              @change="handleChange"
              v-model="checkAll"
            />
          </div>
          <div class="bulk-action">
            <div class="action-wrapp">
              <div class="button" @click="() => (expand = !expand)">
                BULK ACTION
                <i
                  :class="expand ? 'fa fa-chevron-up' : 'fa fa-chevron-down'"
                ></i>
              </div>
              <div v-if="expand" class="expand-popup">
                <div class="button" @click="approveItems">Approved</div>
                <div class="button" @click="denyItems">Denied</div>
              </div>
            </div>
          </div>
          <div class="title">EPISODE TITLE</div>
          <div class="series">Series</div>
          <div class="release-date">RELEASE DATE</div>
          <div class="created-at">CREATED AT</div>
          <div class="status">STATUS</div>
          <div class="title"></div>
        </div>
        <div>
          <QualityControl
            v-for="video in videos.data"
            :key="video.id"
            :video="video"
            :series="series"
            :checkedVideos="checkedVideos"
            @selectItem="selectItem"
            @reload="getResults"
          >
          </QualityControl>
          <h2 v-if="videos.data && !videos.data.length">
            No approved episodes for this user
          </h2>
        </div>
        <div>
          <pagination
            :data="videos"
            @pagination-change-page="getResults"
            :limit="1"
            :per-page="perPage"
          ></pagination>
        </div>
      </div>
      <a-tabs
        v-model="activeKey"
        type="editable-card"
        :hideAdd="true"
        @edit="onEdit"
        @change="() => getResults()"
      >
        <a-tab-pane
          v-for="(pane, index) in panes"
          :key="pane.id"
          :closable="false"
          class="tab-pane"
        >
          <span slot="tab" class="tab-header">
            <a-icon
              type="setting"
              class="icon-pos"
              @click="editSeason(pane.id)"
              theme="filled"
            />
            {{ pane.name }}
            <a-button
              v-if="index == panes.length - 1"
              @click="add($event)"
              class="plus-button"
            >
              +
            </a-button>
          </span>
          <div>
            <div class="video-list-header align-items-center">
              <div v-if="$gate.isAdmin()" class="check-wrapp">
                <input type="checkbox" :checked="checkedVideos.length > 0" />
              </div>
              <div class="thumbnail">
                SELECT
                {{
                  checkedVideos.length == 0
                    ? ""
                    : "(" + checkedVideos.length + ")"
                }}
              </div>
              <div class="title" @click="onSort('title')">
                EPISODE TITLE
                <span
                  v-if="sort && sortBy == 'title' && ascending"
                  style="font-size: 16px"
                  >↑&nbsp;&nbsp;</span
                >
                <span
                  v-if="sort && sortBy == 'title' && !ascending"
                  style="font-size: 16px"
                  >↓&nbsp;&nbsp;</span
                >
              </div>
              <div class="series">SERIES</div>
              <div class="release-date" @click="onSort('release_date')">
                RELEASE DATE
                <span
                  v-if="sort && sortBy == 'release_date' && ascending"
                  style="font-size: 16px"
                  >↑&nbsp;&nbsp;</span
                >
                <span
                  v-if="sort && sortBy == 'release_date' && !ascending"
                  style="font-size: 16px"
                  >↓&nbsp;&nbsp;</span
                >
              </div>
              <div class="created-at" @click="onSort('created_at')">
                CREATED AT
                <span
                  v-if="sort && sortBy == 'created_at' && ascending"
                  style="font-size: 16px"
                  >↑&nbsp;&nbsp;</span
                >
                <span
                  v-if="sort && sortBy == 'created_at' && !ascending"
                  style="font-size: 16px"
                  >↓&nbsp;&nbsp;</span
                >
              </div>
              <div class="status">
                <span>STATUS</span>
              </div>
            </div>
            <QualityControl
              v-for="video in videos.data"
              :key="video.id"
              :video="video"
              :series="series"
              :checkedVideos="checkedVideos"
              @selectItem="selectItem"
              @reload="getResults"
            >
            </QualityControl>
            <h2 v-if="videos.data && !videos.data.length">
              No approved episodes for this user
            </h2>
          </div>
          <div>
            <pagination
              :data="videos"
              @pagination-change-page="getResults"
              :limit="1"
              :per-page="perPage"
            ></pagination>
          </div>
        </a-tab-pane>
      </a-tabs>
    </section>
  </div>
</template>

<script>
import Header from "./header";
import QualityControl from "./QualityControl";
import "./QualityUploads.css";

export default {
  data: function () {
    return {
      panes: [],
      activeKey: 1,
      newTabIndex: 0,

      series: {},
      videos: {},
      q: "",
      perPage: 10,
      expand: false,
      checkedVideos: [],
      checkAll: false,
      addingSeasion: false,
      showFilter: false,
      sort: false,
      ascending: false,
      sortBy: "title",
    };
  },
  components: {
    Header,
    QualityControl,
  },
  methods: {
    async onApply(e) {
      if (this.$route.params.series_id) {
        const params = {
          series_id: this.$route.params.series_id,
          sort: this.sort,
          ascending: this.ascending,
          sort_by: this.sortBy,
          filter: this.q,
        };
        const { data } = await axios.patch(
          `/api/sortfilter/${this.$route.params.series_id}`,
          params
        );
        console.log(data);
        Toast.fire({
          icon: "success",
          title: data.message,
        });
      }
    },
    onFilter(e) {
      this.showFilter = !this.showFilter;
    },
    onSort(field) {
      if (!this.sort) this.sort = true;

      if (this.sort) {
        if (this.sortBy == field) this.ascending = !this.ascending;
        else this.ascending = true;
        this.sortBy = field;
      }

      this.getResults(this.$route.query.page);
    },
    onEdit(targetKey, action) {
      this[action](targetKey);
    },

    async add(e) {
      if (this.addingSeasion) return;

      this.$Progress.start();
      this.addingSeasion = true;

      e.preventDefault();
      e.stopPropagation();
      const data = {
        id: this.series.id,
        name: `season${this.panes.length + 1}`,
        status: 1,
      };
      const res = await axios.post("/api/season/addSeason", data);
      this.panes = res.data.data;

      this.addingSeasion = false;
      this.$Progress.finish();
    },

    editSeason(season_id) {
      this.$router.push("/edit-season/" + season_id);
    },

    getResults(page = 1) {
      this.showFilter = false;

      this.$Progress.start();
      if (this.$route.params.series_id) {
        axios
          .get(
            `/api/video/getbyseries?series_id=${this.$route.params.series_id}&page=${page}&per_page=${this.perPage}&season_id=${this.activeKey}&sort=${this.sort}&ascending=${this.ascending}&sortBy=${this.sortBy}&q=${this.q}`
          )
          .then(({ data }) => (this.videos = data.data));
      } else {
        axios
          .get(
            `/api/video?page=${page}&per_page=${this.perPage}&q=${this.q}&sort=${this.sort}&ascending=${this.ascending}`
          )
          .then(({ data }) => (this.videos = data.data));
      }
      // update query param
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("page", page);
      const newUrl = `${window.location.origin}${
        window.location.pathname
      }?${queryParams.toString()}`;
      window.history.replaceState({ path: newUrl }, "", newUrl);

      this.$Progress.finish();
    },
    async getSeries() {
      this.$Progress.start();
      if (this.$route.params.series_id) {
        const res = await axios.get(
          "/api/series/" + this.$route.params.series_id
        );
        this.series = res.data.data;

        const res_season = await axios.post("/api/season/getSeason", {
          id: this.series.id,
        });
        this.panes = res_season.data.data;
        this.activeKey = this.panes[0].id;

        const { data } = await axios.get(
          `/api/sortfilter/${this.$route.params.series_id}`
        );
        if (data.data != null) {
          this.sort = data.data.sort == 1 ? true : false;
          this.ascending = data.data.ascending ? true : false;
          this.q = data.data.filter ?? "";
          this.sortBy = data.data.sort_by;
        }
      }
      this.getResults(this.$route.query.page);
      this.$Progress.finish();
    },

    approveItems() {
      setTimeout(() => {
        this.expand = false;
      }, 300);
      if (this.checkedVideos.length === 0) {
        Toast.fire({
          icon: "warning",
          title: "Please select videos to approve!",
        });
        return;
      }
      this.$Progress.start();
      axios
        .post("/api/video/approveitems", {
          ids: this.checkedVideos,
          userId: this.$gate.user.id,
        })
        .then(({ data }) => {
          if (data.success) {
            Toast.fire({
              icon: "success",
              title: data.message,
            });
            this.checkedVideos = [];
            this.getResults();
          }
        });
      this.$Progress.finish();
    },
    denyItems() {
      setTimeout(() => {
        this.expand = false;
      }, 300);
      if (this.checkedVideos.length === 0) {
        Toast.fire({ icon: "warning", title: "Please select videos to deny!" });
        return;
      }
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        input: "text",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete them!",
        inputPlaceholder: "Write a reason!",
        preConfirm: (reason) => {
          if (reason === "")
            Swal.showValidationMessage("You need to write a reason!");
        },
      }).then((result) => {
        if (result.value) {
          axios
            .post("/api/video/denyitems", {
              ids: this.checkedVideos,
              reason: result.value,
              userId: this.$gate.user.id,
            })
            .then(({ data }) => {
              if (data.success) {
                Toast.fire({
                  icon: "success",
                  title: data.message,
                });
                this.checkedVideos = [];
                this.getResults();
              }
            })
            .catch((data) => {
              Swal.fire("Failed!", data.message, "warning");
            });
        }
      });
    },
    goToUpload() {
      this.$router.push(
        `/upload-video?series_id=${this.series.id}&folder_name=${this.series.folder_name}`
      );
    },
    selectItem(item, add) {
      // if select all is checked
      if (this.checkAll) {
        this.checkAll = false;
      }
      if (add) {
        this.checkedVideos.push(item);
      } else {
        var ind = this.checkedVideos.indexOf(item);
        this.checkedVideos.splice(ind, 1);
      }
    },
    handleChange(e) {
      if (e.target.checked) {
        this.checkedVideos = this.videos.data.map((video) => video.id);
      } else {
        this.checkedVideos = [];
      }
    },
  },
  watch: {
    perPage() {
      this.getResults();
    },
    checkedVideos() {
      if (this.checkedVideos.length === this.videos.data.length) {
        this.checkAll = true;
      }
    },
  },
  created() {
    this.getSeries();
  },
  updated() {},
};
</script>

<style scoped lang="scss">
.content {
  padding: 70px 100px;

  .ant-tabs-tab span i {
    display: none;
  }
  .ant-tabs-tab-active span i {
    display: block;
    position: absolute;
    top: -6px;
    right: -20px;
  }
  .icon-pos:hover {
    color: blue;
  }
  .section-title {
    margin-bottom: 50px;
  }

  .controls {
    align-items: center;
    justify-content: flex-end;
    display: flex;
    gap: 10px;
    margin-bottom: 40px;

    .filter {
      position: relative;
      .expand-filter {
        position: absolute;
        margin-top: 8px;
        padding: 8px;
        border-radius: 8px;
        transform: translateX(-30px);
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        background-color: #3490dc80;
        z-index: 9999;

        .search-input {
          width: 180px;
          background: #ffffff;
          border: 1px solid #c6c6c6;
          border-radius: 15px;
          height: 40px;
          font-family: "Inter";
          font-weight: 500;
          font-size: 14px;
          line-height: 29px;
        }
      }
    }

    .btn {
      width: 110px;
      height: 40px;
      font-family: "Inter";
      font-weight: 600;
      font-size: 14px;
      line-height: 29px;
      color: #ffffff;
    }
    .btn-primary {
      width: 170px;
    }
    .btn-outline {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0.5px solid #4e5d78;
      color: #4e5d78;
    }
    .paginate-select {
      display: flex;
      align-items: center;

      span {
        padding: 16px;
      }

      select {
        height: 40px;
        font-family: "Inter";
        font-weight: 500;
        font-size: 14px;
        line-height: 29px;
        border-radius: 15px;
      }
    }
  }

  .qc-uploads-header {
    display: grid !important;
    grid-template-columns: 1fr 3fr 3fr 3fr 3fr 3fr 2fr 2fr 2fr 3fr;
    background: #f5f5f5;
    border-radius: 15px;
    height: 50px;
    border-radius: 15px;
    gap: 10px;
    align-items: center;
    margin-block: 10px;

    div {
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #4e5d78;
    }

    .check-wrap {
      padding-left: 22px;
    }

    .select-wrap {
    }

    .bulk-action {
      .action-wrapp {
        position: relative;

        .expand-popup {
          position: absolute;
          width: 100%;
          background: #f6f6f6;
          border-radius: 15px;

          .button {
            cursor: pointer;
            padding: 12px;
            border-radius: 15px;
            
            &:hover {
              background-color: #f0eded;
            }
          }
        }

        .button {
          cursor: pointer;
          white-space: nowrap;
          padding-inline: 4px;
        }
      }
    }

    .series {
    }

    .title {
    }

    .status {
      width: 15%;
    }
  }
}
.video-list-header {
  display: grid !important;
  grid-template-columns: 1fr 3fr 3fr 3fr 3fr 3fr 2fr 2fr 2fr 3fr;
  background: #d9d9d933;
  padding-block: 15px;
  gap: 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 29px;
  color: #4e5d78;
  margin-block: 10px;

  .check-wrapp {
    padding-left: 22px;
  }

  .title {
    cursor: pointer;
    span {
      color: red;
      font-size: 14px;
      font-weight: 900;
    }
  }

  .release-date {
    cursor: pointer;
    span {
      color: red;
      font-size: 14px;
      font-weight: 900;
    }
  }

  .created-at {
    cursor: pointer;
    span {
      color: red;
      font-size: 14px;
      font-weight: 900;
    }
  }

  .status {
    cursor: pointer;
  }
}
</style>
