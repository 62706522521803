<template>
  <div>
    <Header></Header>
    <section class="content">
      <h1 class="section-title">Vimeo Test</h1>
    </section>
  </div>
</template>

<script>
import Header from "./header";
import axios from "axios";

export default {
  components: {
    Header,
  },
  data() {
    return {};
  },
  mounted() {
    // const client = new Vimeo(
    //   "93b4915b0ef6a4014a8f99f2acde406101308041",
    //   "7sG6DxDu2eeVknPV+2M0OfDO87+o9RORrkF9q6eBiwalcRnICvIM888rTt5SuSClxVPJqUBCtqt+MkgRcSWiG7DrBx+tMzuAtSH5tn5eOMzFphGX7XrGAnMQuo9NHtrz",
    //   "650fa71ef55e6d138bddc498d0bdaef0"
    // );

    axios.get("/api/getfeed").then((data) => console.log(data.data));
    // axios
    //   .get(`https://api.vimeo.com/users/200081393/videos`, {
    //     headers: {
    //       Authorization: `Bearer 650fa71ef55e6d138bddc498d0bdaef0`,
    //       "Content-Type": "application/json",
    //       Accept: "application/vnd.vimeo.*+json;version=3.4",
    //     },
    //   })
    //   .then((response) => {
    //     // Handle the response containing the user's videos
    //     const videos = response.data.data;
    //     console.log(videos);
    //   })
    //   .catch((error) => {
    //     // Handle any errors
    //     console.error(error);
    //   });

    fetch(`https://api.vimeo.com/users/200081393/videos`, {
      headers: {
        Authorization: `Bearer 650fa71ef55e6d138bddc498d0bdaef0`,
        "Content-Type": "application/json",
        Accept: "application/vnd.vimeo.*+json;version=3.4",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch videos");
        }
        return response.json();
      })
      .then((data) => {
        // Handle the response containing the user's videos
        const videos = data.data;
      })
      .catch((error) => {
        // Handle any errors
        // console.error(error);
      });
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.content {
  padding-top: 70px;

  .controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

    .search-input {
      width: auto;
      height: 40px;
      border-radius: 10px;
    }
    .btn-search {
      height: 40px;
      line-height: 1;
    }
    .btn-create-programmer {
      padding: 8px 20px;
      display: block;
      height: 40px;
      line-height: 1;
    }
  }

  .section-title {
    margin-bottom: 100px;
  }

  .table-striped {
    tbody {
      tr {
        border-radius: 3px;

        &:nth-of-type(even) {
          background-color: #dedede;
        }

        &:nth-of-type(odd) {
          background-color: #f1f1f1;
        }
      }
    }
  }

  .table {
    border-collapse: separate;
    border-spacing: 0 10px;

    thead {
      th {
        font-weight: 700;
        font-size: 16px;
        line-height: 29px;
        color: #616978;

        .form-control {
          width: auto;
          display: inline-block;
        }
      }
    }

    td {
      font-weight: 500;
      font-size: 16px;
      line-height: 29px;
      color: #8a94a6;
    }

    tr td:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    tr td:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}
</style>
