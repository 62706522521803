var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Header"),
      _vm._v(" "),
      _c("section", { staticClass: "main-content" }, [
        _c("div", [
          _c("div", { staticClass: "back-button d-flex justify-content-end" }, [
            _c(
              "button",
              { staticClass: "btn btn-back", on: { click: _vm.goBack } },
              [_vm._v("Back")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "banner" }, [
            _c("div", { staticClass: "video-box" }, [
              _c("video", {
                ref: "qualityVideo",
                staticClass: "quality-control-video",
                attrs: { src: _vm.form.url, controls: "" },
              }),
            ]),
            _vm._v(" "),
            _c("div", {
              ref: "peakMeter",
              staticStyle: { height: "72px" },
              attrs: { id: "peak-meter" },
            }),
          ]),
          _vm._v(" "),
          _c("h3", { staticClass: "small-title" }, [_vm._v("Basic Details")]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.title,
                  expression: "form.title",
                },
              ],
              staticClass: "form-control input-box",
              class: { "is-invalid": _vm.form.errors.has("title") },
              attrs: {
                type: "text",
                id: "eposode-title",
                "aria-describedby": "seriesTitle",
                placeholder: "Episode Title",
              },
              domProps: { value: _vm.form.title },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form, "title", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "eposode-title" } }, [
              _vm._v("Episode Title"),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.short_description,
                  expression: "form.short_description",
                },
              ],
              staticClass: "textarea form-control",
              class: { "is-invalid": _vm.form.errors.has("short_description") },
              attrs: { name: "", id: "short-description" },
              domProps: { value: _vm.form.short_description },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form, "short_description", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "short-description" } }, [
              _vm._v("Short Description"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("datepicker", {
                attrs: {
                  placeholder: "Select Date",
                  "input-class": {
                    "is-invalid": _vm.form.errors.has("release_date"),
                    "form-control": true,
                  },
                  readonly: false,
                },
                model: {
                  value: _vm.form.release_date,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "release_date", $$v)
                  },
                  expression: "form.release_date",
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "publish-date" } }, [
                _vm._v("Publish Date"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "tags-container" },
            [
              _c("vue-tags-input", {
                attrs: {
                  tags: _vm.form.tags,
                  "autocomplete-items": _vm.filteredItems,
                },
                on: { "tags-changed": _vm.tagsChanged },
                model: {
                  value: _vm.tag,
                  callback: function ($$v) {
                    _vm.tag = $$v
                  },
                  expression: "tag",
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "" } }, [_vm._v("Keywords / Tags")]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "image-select" },
            [
              _vm.form.thumbnail && !_vm.thumbFiles.length
                ? _c("img", {
                    staticClass: "d-block",
                    attrs: { src: _vm.form.thumbnail, alt: "Series Poster" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.thumbFiles.length
                ? _c(
                    "ul",
                    [
                      _c("img", {
                        staticClass: "d-block",
                        attrs: {
                          src: _vm.thumbFiles[0].blob,
                          alt: "Video Thumbnail",
                        },
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.thumbFiles, function (file) {
                        return _c("li", { key: file.id }, [
                          _c("span", [_vm._v(_vm._s(file.name))]),
                          _vm._v(" -\n            "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.formatBytes(file.size))),
                          ]),
                          _vm._v(" -\n            "),
                          file.error
                            ? _c("span", [_vm._v(_vm._s(file.error))])
                            : file.success
                            ? _c("span", { staticClass: "success" }, [
                                _vm._v("success\n              "),
                                _c("i", {
                                  staticClass: "fa fas fa-check-circle ml-2",
                                }),
                              ])
                            : file.active
                            ? _c("span", [_vm._v("uploading...")])
                            : _c("span"),
                        ])
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "file-upload",
                {
                  ref: "thumbnailUpload",
                  staticClass: "file-select-label",
                  attrs: { accept: "image/*", "input-id": "thumbnail" },
                  on: { "input-filter": _vm.inputFilter },
                  model: {
                    value: _vm.thumbFiles,
                    callback: function ($$v) {
                      _vm.thumbFiles = $$v
                    },
                    expression: "thumbFiles",
                  },
                },
                [_vm._v("\n          Browse Files\n        ")]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "poster-name" }, [
                _vm._v("Video Thumbnail"),
              ]),
              _vm._v(" "),
              _vm.captionFiles.length
                ? _c(
                    "ul",
                    _vm._l(_vm.captionFiles, function (file) {
                      return _c("li", { key: file.id }, [
                        _c("span", [_vm._v(_vm._s(file.name))]),
                        _vm._v(" -\n            "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.formatBytes(file.size))),
                        ]),
                        _vm._v(" -\n            "),
                        file.error
                          ? _c("span", [_vm._v(_vm._s(file.error))])
                          : file.success
                          ? _c("span", { staticClass: "success" }, [
                              _vm._v("success\n              "),
                              _c("i", {
                                staticClass: "fa fas fa-check-circle ml-2",
                              }),
                            ])
                          : file.active
                          ? _c("span", [_vm._v("uploading...")])
                          : _c("span"),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "file-upload",
                {
                  ref: "captionUpload",
                  staticClass: "file-select-label",
                  attrs: { accept: ".srt,.vtt", "input-id": "caption" },
                  model: {
                    value: _vm.captionFiles,
                    callback: function ($$v) {
                      _vm.captionFiles = $$v
                    },
                    expression: "captionFiles",
                  },
                },
                [_vm._v("\n          Browse Files\n        ")]
              ),
              _vm._v(" "),
              _vm._m(0),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "actions" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { disabled: _vm.isUploading },
                on: { click: _vm.createVideo },
              },
              [_vm._v("\n          Save\n        ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              { staticClass: "btn btn-white", on: { click: _vm.goBack } },
              [_vm._v("Cancel")]
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "poster-name" }, [
      _vm._v("Closed Captions"),
      _c("br"),
      _vm._v("(SRT or VTT files)"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }