<template>
    <div class="">
        <div class="quality-control d-flex align-items-center">
            <div class="thumbnail" style="">
                <!-- <img :src="video.url" :alt="video.fileName"> -->
                Thumbnail
            </div>
            <div class="title">{{ video.fileName }}</div>
            <div class="status">
                <span>ready</span>
            </div>
            <div class="action">
                <a href="#" @click="handleExpand">
                    <i class="fas fa-pencil-alt"></i>
                    Edit
                </a>
            </div>
        </div>
        <div v-if="expand" class="save-form" :id="'save-form-' + idx">
            <h3 class="small-title">Basic Details</h3>
            <div class="form-group">
                <input type="text" id="eposode-title" v-model="form.title" class="form-control input-box"
                    :class="{ 'is-invalid': form.errors.has('title') }" aria-describedby="seriesTitle"
                    placeholder="Episode Title" />
                <label for="eposode-title">Episode Title</label>
                <has-error :form="form" field="title"></has-error>
            </div>
            <div class="form-group">
                <textarea name="" id="short-description" v-model="form.short_description" class="textarea form-control"
                    :class="{ 'is-invalid': form.errors.has('short_description') }"></textarea>
                <label for="short-description">Short Description</label>
                <has-error :form="form" field="short_description"></has-error>
            </div>
            <div class="form-group">
                <datepicker placeholder="Select Date" v-model="form.release_date" :use-utc="false" :input-class="{
                    'is-invalid': form.errors.has('release_date'),
                    'form-control': true,
                }" :readonly="false">
                </datepicker>
                <label for="publish-date">Publish Date</label>
                <has-error :form="form" field="release_date"></has-error>
            </div>
            <div class="tags-container" :class="form.errors.has('tags') ? 'is-invalid' : ''">
                <vue-tags-input v-model="form.tag" :tags="form.tags" @tags-changed="tagsChanged"
                    :autocomplete-items="filteredItems" />
                <label for="">Keywords / Tags</label>
                <has-error :form="form" field="tags"></has-error>
            </div>
            <file-upload class="d-none" :input-id="video.id" v-model="thumbFiles" ref="thumbnailUpload"
                @input-filter="inputFilter" accept="image/*">
            </file-upload>

            <div class="image-select" :class="{ 'is-invalid': form.errors.has('thumbnail') }">
                <ul v-if="thumbFiles.length">
                    <img :src="thumbFiles[0].blob" alt="Video Thumbnail" class="d-block" />
                    <li v-for="file in thumbFiles" :key="file.id">
                        <span>{{ file.name }}</span> -
                        <span>{{ $parent.formatBytes(file.size) }}</span> -
                        <span v-if="file.error">{{ file.error }}</span>
                        <span v-else-if="file.success" class="success">success
                            <i class="fa fas fa-check-circle ml-2"></i>
                        </span>
                        <span v-else-if="file.active">uploading...</span>
                        <span v-else></span>
                    </li>
                </ul>
                <label :for="video.id" class="file-select-label">Browse Files</label>
                <p class="poster-name">Video Thumbnail</p>
                <has-error :form="form" field="thumbnail"></has-error>
            </div>
            <div class="image-select">
                <ul v-if="captionFiles.length">
                    <li v-for="file in captionFiles" :key="file.id">
                        <span>{{ file.name }}</span> -
                        <span>{{ $parent.formatBytes(file.size) }}</span> -
                        <span v-if="file.error">{{ file.error }}</span>
                        <span v-else-if="file.success" class="success">success
                            <i class="fa fas fa-check-circle ml-2"></i>
                        </span>
                        <span v-else-if="file.active">uploading...</span>
                        <span v-else></span>
                    </li>
                </ul>
                <file-upload class="file-select-label" v-model="captionFiles" ref="captionUpload" accept=".srt,.vtt"
                    input-id="caption">
                    Browse Files
                </file-upload>
                <p class="poster-name">Closed Captions<br />(SRT or VTT files)</p>
            </div>
            <div class="actions">
                <button class="btn btn-primary" @click="createVideo" :disabled="isUploading">
                    {{ saved ? "Saved" : "Save" }}
                </button>
                <!-- <button class="btn btn-warning" @click="draftVideo">
                Draft
            </button> -->
            </div>
        </div>
    </div>
</template>

<script>
import VueTagsInput from "@johmun/vue-tags-input";
import Datepicker from "vuejs-datepicker";
import FileUpload from "vue-upload-component";

export default {
    name: "Video",
    props: ["video", "idx", "series_id"],
    components: {
        VueTagsInput,
        Datepicker,
        FileUpload,
    },
    data: function () {
        return {
            expand: false,
            tag: "",
            form: new Form({
                id: "",
                title: "",
                short_description: "",
                url: this.video.url,
                video_type: this.video.type,
                duration: this.video.duration,
                quality: "",
                tags: [],
                release_date: "",
                thumbnail: "",
                caption: "",
                series_id: "",
                user_id: this.$gate.user.id
            }),
            autocompleteItems: [],
            thumbFiles: [],
            captionFiles: [],
            saved: false,
            isUploading: false,
            thumbnailSaved: false,
            series: {}
        };
    },
    methods: {
        handleExpand(event) {
            event.preventDefault();
            this.expand = !this.expand;
        },
        loadSeries() {
            this.$Progress.start();
            let series_id = this.$route.query.series_id;
            if (series_id) {
                axios.get("/api/series/" + series_id).then(({
                    data
                }) => {
                    this.series = data.data;
                });
            }
            this.$Progress.finish();
        },
        loadTags() {
            axios
                .get("/api/tag/list")
                .then((response) => {
                    this.autocompleteItems = response.data.data.map((a) => {
                        return {
                            text: a.name,
                            id: a.id
                        };
                    });
                })
                .catch(() => {
                    // console.warn("Oh. Something went wrong")
                });
        },
        tagsChanged(_newTags) {
            if (_newTags.length > 0) {
                const newTags = _newTags[_newTags.length - 1].text.split(",");
                if (newTags.length > 1) {
                    _newTags.pop();
                    newTags.forEach((tag) => {
                        if (tag != "") _newTags.push({
                            text: tag
                        });
                    });
                }
            }
            this.form.tags = _newTags;
        },
        async createVideo() {
            if (this.isUploading) return;

            this.$Progress.start();
            this.isUploading = true;

            this.form.release_date = new Date(this.form.release_date).toISOString();

            if (this.thumbFiles.length > 0) {
                this.form.thumbnail = await this.uploadS3(this.thumbFiles[0]);
            }
            if (this.captionFiles.length > 0) {
                this.form.caption = await this.uploadS3(this.captionFiles[0]);
            }

            this.form
                .post("api/video")
                .then((data) => {
                    if (data.data.success) {
                        Toast.fire({
                            icon: "success",
                            title: data.data.message,
                        });
                        this.saved = true;
                        this.$parent.uploadedFiles++;
                        this.$parent.videoTitles.push(this.form.title);
                        this.$Progress.finish();
                        this.isUploading = false;
                    } else {
                        Toast.fire({
                            icon: "error",
                            title: "Some error occured! Please try again",
                        });

                        this.$Progress.failed();
                        this.isUploading = false;
                    }
                })
                .catch(() => {
                    Toast.fire({
                        icon: "error",
                        title: "Some error occured! Please try again",
                    });
                    this.isUploading = false;
                });
        },
        async uploadS3(fileObj, component) {
            const s3 = new AWS.S3({
                accessKeyId: this.$parent.env.AWS_ACCESS_KEY_ID,
                secretAccessKey: this.$parent.env.AWS_SECRET_ACCESS_KEY,
                region: "us-east-1",
            });
            const folderName = this.$route.query.folder_name || this.series.folder_name; // ger folder name from $gate
            const file = fileObj.file; // Get the selected file from the file input
            const fileName = file.name; // Get the file name

            // Get the current date and time
            const currentDatetime = new Date();

            // Format the date and time as a string
            const datetimeString = currentDatetime.toISOString().replace(/[-T:]/g, "").slice(0, 14);

            // Split the filename into parts based on the last period
            const filenameParts = fileName.split('.');
            const extension = filenameParts.pop(); // Extract the extension
            const basename = filenameParts.join('.'); // Join the rest of the parts

            // Create the new filename with datetime stamp
            const newFilename = `${basename}_${datetimeString}.${extension}`;

            const params = {
                Bucket: this.$parent.env.AWS_BUCKET,
                Key: "drop/" + folderName + "/" + newFilename,
                Body: file,
                ACL: "public-read", // Set the ACL permissions to allow public read access to the file
            };

            const data = await s3.upload(params).promise();

            return data.Location;
        },
        inputFilter(newFile, oldFile, prevent) {
            if (newFile && !oldFile) {
                // Add file

                // Filter non-image file
                // Will not be added to files
                if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
                    return prevent();
                }

                // Create the 'blob' field for thumbnail preview
                newFile.blob = "";
                let URL = window.URL || window.webkitURL;
                if (URL && URL.createObjectURL) {
                    newFile.blob = URL.createObjectURL(newFile.file);
                }
            }

            if (newFile && oldFile) {
                // Update file

                // Increase the version number
                if (!newFile.version) {
                    newFile.version = 0;
                }
                newFile.version++;
            }
        },
    },
    created() {
        this.$Progress.start();
        this.loadTags();
        this.$Progress.finish();
    },
    mounted() {
        this.loadSeries();
        if (this.idx === 0) this.expand = true;
    },
    computed: {
        filteredItems() {
            return this.autocompleteItems.filter((i) => {
                return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.quality-control {
    margin-bottom: 20px;
    border-radius: 15px;
    padding: 15px 60px;
    cursor: pointer;

    &:hover {
        background-color: #eeeeee;
    }

    .thumbnail {
        width: 15%;

        img {
            width: 100%;
        }
    }

    .title {
        width: 45%;
        font-weight: 600;
        font-size: 14px;
        line-height: 29px;
        color: #4e5d78;
        padding-left: 20px;
    }

    .status {
        width: 20%;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            border-radius: 4px;
            background-color: green;
            top: 10.5px;
            left: 12px;
        }

        span {
            display: inline-block;
            width: 120px;
            height: 25px;
            background: #fafbfc;
            border-radius: 15px;
            font-weight: 500;
            font-size: 14px;
            color: #b0b7c3;
            padding: 0 30px;
        }
    }

    .action {
        width: 20%;

        a {
            display: inline-block;
            width: 80px;
            height: 25px;
            background: #f8f8f9;
            border-radius: 15px;
            padding: 0 15px;
            font-weight: 500;
            font-size: 12px;
            line-height: 25px;
            color: #b0b7c3;

            i {
                margin-right: 4px;
            }
        }
    }
}

.save-form {
    padding: 55px 60px 20px 100px;

    .small-title {
        font-weight: 600;
        font-size: 25px;
        line-height: 29px;
        color: #323b4b;
        font-family: Inter;
        margin-bottom: 40px;
    }

    .image-select {
        margin-bottom: 30px;

        img {
            margin-bottom: 15px;
            width: 165px;
        }

        .file-select-label {
            height: 40px;
            width: 125px;
            background-color: #ffffff;
            border: 1px solid #b6b6b6;
            border-radius: 15px;
            font-weight: 600;
            font-size: 14px;
            line-height: 38px;
            text-align: center;
            color: #323b4b;
            margin-bottom: 10px;
        }

        .poster-name {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 29px;
            color: #8a94a6;
            margin-bottom: 0;
            padding: 0 20px;
        }

        .success {
            i {
                color: green;
            }
        }
    }

    .form-group {
        margin-bottom: 30px;

        input,
        textarea,
        select {
            margin-bottom: 5px;
        }

        select {
            width: 370px;
            height: 50px;
            font-weight: 500;
            font-size: 14px;
            line-height: 29px;
            color: #b0b7c3;
            border-radius: 15px;
        }

        .textarea {
            height: 100px;
        }

        label {
            padding: 0px 20px;
            margin-bottom: 0;
            font-weight: 500 !important;
            font-size: 18px;
            line-height: 29px;
            color: #8a94a6;
        }
    }

    .tags-container {
        margin-bottom: 30px;
    }

    .actions {
        display: flex;
        justify-content: flex-end;
        gap: 30px;

        .btn-primary,
        .btn-white {
            padding: 8px 30px;
        }
    }

    .btn-warning {
        background: #f0ad4e;
        border-color: #eea236;
        border-radius: 15px;
        height: 60px;
        font-weight: 600;
        font-size: 16px;
        line-height: 29px;
        color: #ffffff;
    }
}
</style>
<style>
.vdp-datepicker .form-control {
    width: 370px;
    height: 50px;
    font-weight: 500;
    font-size: 14px;
    line-height: 29px;
    color: #b0b7c3;
    border-radius: 15px;
    background-color: white;
}
</style>
