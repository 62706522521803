<template>
  <div>
    <Header></Header>
    <section class="main-content">
      <div>
        <div class="back-button d-flex justify-content-end">
          <button class="btn btn-back" @click="goBack">Back</button>
        </div>
        <div class="banner">
          <div class="video-box">
            <video
              class="quality-control-video"
              ref="qualityVideo"
              :src="form.url"
              controls
            ></video>
          </div>
          <div id="peak-meter" ref="peakMeter" style="height: 72px"></div>
        </div>

        <h3 class="small-title">Basic Details</h3>
        <div class="form-group">
          <input
            type="text"
            id="eposode-title"
            v-model="form.title"
            class="form-control input-box"
            :class="{ 'is-invalid': form.errors.has('title') }"
            aria-describedby="seriesTitle"
            placeholder="Episode Title"
          />
          <label for="eposode-title">Episode Title</label>
          <!-- <has-error :form="form" field="title"></has-error> -->
        </div>
        <div class="form-group">
          <textarea
            name=""
            id="short-description"
            v-model="form.short_description"
            class="textarea form-control"
            :class="{ 'is-invalid': form.errors.has('short_description') }"
          ></textarea>
          <label for="short-description">Short Description</label>
        </div>
        <div class="form-group">
          <datepicker
            placeholder="Select Date"
            v-model="form.release_date"
            :input-class="{
              'is-invalid': form.errors.has('release_date'),
              'form-control': true,
            }"
            :readonly="false"
          >
          </datepicker>
          <label for="publish-date">Publish Date</label>
          <!-- <has-error :form="form" field="release_date"></has-error> -->
        </div>
        <div class="tags-container">
          <vue-tags-input
            v-model="tag"
            :tags="form.tags"
            @tags-changed="tagsChanged"
            :autocomplete-items="filteredItems"
          />
          <label for="">Keywords / Tags</label>
        </div>

        <div class="image-select">
          <img
            v-if="form.thumbnail && !thumbFiles.length"
            :src="form.thumbnail"
            alt="Series Poster"
            class="d-block"
          />
          <ul v-if="thumbFiles.length">
            <img
              :src="thumbFiles[0].blob"
              alt="Video Thumbnail"
              class="d-block"
            />
            <li v-for="file in thumbFiles" :key="file.id">
              <span>{{ file.name }}</span> -
              <span>{{ formatBytes(file.size) }}</span> -
              <span v-if="file.error">{{ file.error }}</span>
              <span v-else-if="file.success" class="success"
                >success
                <i class="fa fas fa-check-circle ml-2"></i>
              </span>
              <span v-else-if="file.active">uploading...</span>
              <span v-else></span>
            </li>
          </ul>
          <file-upload
            class="file-select-label"
            v-model="thumbFiles"
            ref="thumbnailUpload"
            @input-filter="inputFilter"
            accept="image/*"
            input-id="thumbnail"
          >
            Browse Files
          </file-upload>
          <p class="poster-name">Video Thumbnail</p>

          <ul v-if="captionFiles.length">
            <li v-for="file in captionFiles" :key="file.id">
              <span>{{ file.name }}</span> -
              <span>{{ formatBytes(file.size) }}</span> -
              <span v-if="file.error">{{ file.error }}</span>
              <span v-else-if="file.success" class="success"
                >success
                <i class="fa fas fa-check-circle ml-2"></i>
              </span>
              <span v-else-if="file.active">uploading...</span>
              <span v-else></span>
            </li>
          </ul>
          <file-upload
            class="file-select-label"
            v-model="captionFiles"
            ref="captionUpload"
            accept=".srt,.vtt"
            input-id="caption"
          >
            Browse Files
          </file-upload>
          <p class="poster-name">Closed Captions<br />(SRT or VTT files)</p>
        </div>
        <div class="actions">
          <button
            class="btn btn-primary"
            @click="createVideo"
            :disabled="isUploading"
          >
            Save
          </button>
          <button class="btn btn-white" @click="goBack">Cancel</button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Header from "./header";
import AWS from "aws-sdk";
import VueTagsInput from "@johmun/vue-tags-input";
import FileUpload from "vue-upload-component";
import Datepicker from "vuejs-datepicker";
const webAudioPeakMeter = require("web-audio-peak-meter");

export default {
  data: function () {
    return {
      env: {},
      tag: "",
      form: new Form({
        id: "",
        title: "",
        short_description: "",
        url: "",
        video_type: "",
        duration: "",
        quality: "",
        tags: [],
        release_date: "",
        thumbnail: "",
        caption: "",
        series_id: "",
        user_id: this.$gate.user.id,
      }),
      thumbFiles: [],
      captionFiles: [],
      autocompleteItems: [],
      playStarted: false,
      isUpdating: false,
      draftId: this.$route.params.draft_id,
      isUploading: false,
      progress: "",
      uploadedFiles: 0,
      videoTitles: [],
      series: {},
    };
  },
  components: {
    Header,
    VueTagsInput,
    FileUpload,
    Datepicker,
  },
  methods: {
    formatBytes(bytes, decimals) {
      if (bytes == 0) return "0 Bytes";
      var k = 1024,
        dm = decimals || 2,
        sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    getDraftInfo() {
      axios
        .get(`/api/draft/getdraftfileinfo?draft_id=${this.draftId}`)
        .then((response) => {
          const info = response.data;
          this.form = new Form({
            url: info.video_url,
            video_type: info.video_type,
            duration: info.video_duration,
            series_id: info.series_id,
            user_id: info.user_id,
            quality: "FHD",
          });

          axios.get("/api/series/" + info.series_id).then(({ data }) => {
            this.series = data.data;
          });
        });
    },

    async createVideo() {
      if (this.isUploading) return;

      this.$Progress.start();
      this.isUploading = true;

      this.form.release_date = new Date(this.form.release_date).toISOString();

      if (this.thumbFiles.length > 0) {
        this.form.thumbnail = await this.uploadS3(this.thumbFiles[0]);
      }
      if (this.captionFiles.length > 0) {
        this.form.caption = await this.uploadS3(this.captionFiles[0]);
      }

      this.form
        .post("/api/video")
        .then((data) => {
          console.log("api/video", data);
          if (data.data.success) {
            Toast.fire({
              icon: "success",
              title: data.data.message,
            });
            axios.delete(`/api/draft/${this.draftId}`).then((response) => {
              console.log("deleted", response);
            });
            this.saved = true;
            this.$Progress.finish();
            this.isUploading = false;
            this.$router.push("/draft");
          } else {
            Toast.fire({
              icon: "error",
              title: "Some error occured! Please try again",
            });

            this.$Progress.failed();
            this.isUploading = false;
          }
        })
        .catch((err) => {
          console.log("catching error", err);
          Toast.fire({
            icon: "error",
            title: "Some error occured! Please try again",
          });
          this.isUploading = false;
        });
    },
    async uploadS3(fileObj, component) {
      const s3 = new AWS.S3({
        accessKeyId: this.env.AWS_ACCESS_KEY_ID,
        secretAccessKey: this.env.AWS_SECRET_ACCESS_KEY,
        region: "us-east-1",
      });
      const folderName =
        this.$route.query.folder_name || this.series.folder_name; // ger folder name from $gate
      const file = fileObj.file; // Get the selected file from the file input
      const fileName = file.name; // Get the file name

      const currentDatetime = new Date();
      const datetimeString = currentDatetime
        .toISOString()
        .replace(/[-T:]/g, "")
        .slice(0, 14);

      const filenameParts = fileName.split(".");
      const extension = filenameParts.pop();
      const basename = filenameParts.join(".");
      const newFilename = `${basename}_${datetimeString}.${extension}`;

      const params = {
        Bucket: this.env.AWS_BUCKET,
        Key: "drop/" + folderName + "/" + newFilename,
        Body: file,
        ACL: "public-read", // Set the ACL permissions to allow public read access to the file
      };

      const data = await s3.upload(params).promise();

      return data.Location;
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Add file

        // Filter non-image file
        // Will not be added to files
        if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
          return prevent();
        }

        // Create the 'blob' field for thumbnail preview
        newFile.blob = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
      }

      if (newFile && oldFile) {
        // Update file

        // Increase the version number
        if (!newFile.version) {
          newFile.version = 0;
        }
        newFile.version++;
      }
    },
    goBack() {
      this.$router.push("/draft");
    },
    loadTags() {
      axios
        .get("/api/tag/list")
        .then((response) => {
          this.autocompleteItems = response.data.data.map((a) => {
            return {
              text: a.name,
              id: a.id,
            };
            f;
          });
        })
        .catch(() => {
          // console.warn("Oh. Something went wrong")
        });
    },
    initAudioMeter() {
      const audioCtx = new AudioContext();
      const videoElement = this.$refs.qualityVideo;
      if (!videoElement) return;
      videoElement.crossOrigin = "anonymous";

      const meterElement = this.$refs.peakMeter;

      const sourceNode = audioCtx.createMediaElementSource(videoElement);
      sourceNode.connect(audioCtx.destination);
      const options = {
        audioMeterStandard: "true-peak",
        dbRangeMin: -60,
        dbTickSize: 3,
      };

      const test = new webAudioPeakMeter.WebAudioPeakMeter(
        sourceNode,
        meterElement,
        options
      );

      videoElement.addEventListener("play", () => {
        audioCtx.resume();
      });

      videoElement.addEventListener("pause", () => {
        audioCtx.suspend();
      });
    },
    tagsChanged(_newTags) {
      if (_newTags.length > 0) {
        const newTags = _newTags[_newTags.length - 1].text.split(",");
        if (newTags.length > 1) {
          _newTags.pop();
          newTags.forEach((tag) => {
            if (tag != "")
              _newTags.push({
                text: tag,
              });
          });
        }
      }
      this.form.tags = _newTags;
    },
    async getFileDuration(file) {
      return new Promise((resolve, reject) => {
        const video = document.createElement("video");
        video.src = URL.createObjectURL(file);
        video.onloadedmetadata = function () {
          URL.revokeObjectURL(video.src);
          resolve(video.duration);
        };
      });
    },
  },
  created() {
    this.$Progress.start();
    this.loadTags();
    this.$Progress.finish();
  },
  mounted() {
    this.getDraftInfo();
    this.initAudioMeter();

    axios.get("/api/env").then((response) => {
      this.env = response.data;
    });
  },
  computed: {
    filteredItems() {
      return this.autocompleteItems.filter((i) => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  padding: 70px 100px;

  .content {
    padding: 0px 0px !important;
  }

  .drop {
    background-color: #f4f8ff;
    border-radius: 15px;
    padding: 30px 110px !important;
    margin-bottom: 25px;

    .drop-zone {
      border-radius: 15px;
      border: 1.5px dashed #b8b0b0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 30px;

      img {
        margin-bottom: 25px;
      }

      p {
        font-weight: 600;
        font-size: 16px;
        line-height: 29px;
        color: #b0b7c3;
      }

      label {
        color: #377dff;
        cursor: pointer;
      }

      ul li {
        align-items: center;

        .success {
          display: flex;
          align-items: center;

          i {
            color: green;
          }
        }
      }
    }

    .drop-active {
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      position: fixed;
      z-index: 9999;
      opacity: 0.6;
      text-align: center;
      background: #000;
    }

    .drop-active h3 {
      margin: -0.5em 0 0;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      font-size: 40px;
      color: #fff;
      padding: 0;
    }
  }

  .section-title {
    margin-bottom: 30px;
  }

  .small-title {
    font-weight: 600;
    font-size: 25px;
    line-height: 29px;
    color: #323b4b;
    font-family: Inter;
    margin-bottom: 40px;
    margin-top: 60px;
  }

  .banner {
    margin-bottom: 40px;

    .video-box {
      position: relative;

      .thumbnail {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    video {
      width: 100%;
    }
  }

  .image-select {
    width: 165px;
    margin-bottom: 30px;

    img {
      margin-bottom: 15px;
      width: 100%;
    }

    .file-select-label {
      height: 40px;
      width: 125px;
      background-color: #ffffff;
      border: 1px solid #b6b6b6;
      border-radius: 15px;
      font-weight: 600;
      font-size: 14px;
      line-height: 38px;
      text-align: center;
      color: #323b4b;
      margin-bottom: 10px;
    }

    .poster-name {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 29px;
      color: #8a94a6;
    }

    .success {
      i {
        color: green;
      }
    }
  }

  .form-group {
    margin-bottom: 30px;

    input,
    textarea,
    select {
      margin-bottom: 5px;
    }

    select {
      width: 370px;
      height: 50px;
      font-weight: 500;
      font-size: 14px;
      line-height: 29px;
      color: #b0b7c3;
      border-radius: 15px;
    }

    .textarea {
      height: 100px;
    }

    label {
      padding: 0px 20px;
      margin-bottom: 0;
    }
  }

  .tags-container {
    margin-bottom: 30px;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 30px;

    .btn-primary,
    .btn-white {
      padding: 8px 30px;
    }
  }

  .btn-back {
    font-weight: 500;
    font-size: 16px;
    line-height: 29px;
    color: #8a94a6;
  }

  .control-bar {
    display: none;
  }
}

.drop {
  background-color: #f4f8ff;
  border-radius: 15px;
  padding: 30px 110px;
  margin-bottom: 25px;

  .drop-zone {
    border-radius: 15px;
    border: 1.5px dashed #b8b0b0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 30px;

    img {
      margin-bottom: 25px;
    }

    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 29px;
      color: #b0b7c3;
    }

    label {
      color: #377dff;
      cursor: pointer;
    }

    ul li {
      align-items: center;

      .success {
        display: flex;
        align-items: center;

        i {
          color: green;
        }
      }
    }
  }

  .drop-active {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: fixed;
    z-index: 9999;
    opacity: 0.6;
    text-align: center;
    background: #000;
  }

  .drop-active h3 {
    margin: -0.5em 0 0;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 40px;
    color: #fff;
    padding: 0;
  }
}

.btn-warning {
  background: #f0ad4e;
  border-color: #eea236;
  border-radius: 15px;
  height: 60px;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  color: #ffffff;
}

.banner {
  margin-bottom: 40px;

  .video-box {
    position: relative;

    .thumbnail {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  video {
    width: 100%;
  }
}
</style>
