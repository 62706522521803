var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Header"),
      _vm._v(" "),
      _c("section", { staticClass: "content" }, [
        _c("h1", { staticClass: "section-title" }, [_vm._v("Vimeo Videos")]),
        _vm._v(" "),
        _c("div", { staticClass: "controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.q,
                expression: "q",
              },
            ],
            staticClass: "form-control search-input",
            attrs: { type: "text", placeholder: "Search.." },
            domProps: { value: _vm.q },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.q = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { disabled: !_vm.q },
              on: { click: _vm.getResults },
            },
            [_vm._v("\n        Search\n      ")]
          ),
          _vm._v(" "),
          _vm.$gate.isAdmin()
            ? _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: { click: _vm.runScript },
                },
                [_vm._v("\n        Run\n      ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "paginate-select" }, [
            _c("span", [_vm._v("Show:")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.perPage,
                    expression: "perPage",
                  },
                ],
                staticClass: "form-control",
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.perPage = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", [_vm._v("10")]),
                _vm._v(" "),
                _c("option", [_vm._v("25")]),
                _vm._v(" "),
                _c("option", [_vm._v("50")]),
                _vm._v(" "),
                _c("option", [_vm._v("100")]),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "qc-uploads-header" }, [
          _c("div", { staticClass: "check-wrap" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.checkAll,
                  expression: "checkAll",
                },
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.checkAll)
                  ? _vm._i(_vm.checkAll, null) > -1
                  : _vm.checkAll,
              },
              on: {
                change: [
                  function ($event) {
                    var $$a = _vm.checkAll,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.checkAll = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.checkAll = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.checkAll = $$c
                    }
                  },
                  _vm.handleChange,
                ],
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "select-wrap" }, [_vm._v("Select All")]),
          _vm._v(" "),
          _c("div", { staticClass: "bulk-action" }, [
            _c("div", { staticClass: "action-wrapp" }, [
              _c(
                "div",
                {
                  staticClass: "button",
                  on: { click: () => (_vm.expand = !_vm.expand) },
                },
                [
                  _vm._v("\n            Bulk Action\n            "),
                  _c("i", {
                    class: _vm.expand
                      ? "ml-2 fa fa-chevron-up"
                      : "ml-2 fa fa-chevron-down",
                  }),
                ]
              ),
              _vm._v(" "),
              _vm.expand
                ? _c("div", { staticClass: "expand-popup" }, [
                    _c(
                      "div",
                      {
                        staticClass: "button",
                        on: { click: _vm.approveItems },
                      },
                      [_vm._v("Download")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "button", on: { click: _vm.denyItems } },
                      [_vm._v("Remove")]
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "series" }),
          _vm._v(" "),
          _c("div", { staticClass: "status" }, [_vm._v("Status")]),
          _vm._v(" "),
          _c("div", { staticClass: "title" }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _vm._l(_vm.videos.data, function (video) {
              return _c("VimeoVideo", {
                key: video.id,
                attrs: {
                  video: video,
                  user: _vm.user,
                  checkedVideos: _vm.checkedVideos,
                },
                on: { selectItem: _vm.selectItem },
              })
            }),
            _vm._v(" "),
            _vm.videos.data && !_vm.videos.data.length
              ? _c("h2", [
                  _vm._v(
                    "\n        No approved episodes for this user\n      "
                  ),
                ])
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("pagination", {
              attrs: { data: _vm.videos, limit: 1, "per-page": _vm.perPage },
              on: { "pagination-change-page": _vm.getResults },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }