<template>
  <div>
    <Header></Header>
    <section class="content">
      <h1 class="section-title">Edit Season</h1>
      <div>
        <div class="d-flex edit-grid">
          <div class="edit-box">
            <div class="form-group">
              <input
                type="text"
                v-model="form.name"
                id="seasons-title"
                class="form-control input-box"
                aria-describedby="seasonsTitle"
                placeholder="seasons Title"
                :class="{ 'is-invalid': form.errors.has('name') }"
              />
              <label for="Season name">Season Name</label>
            </div>
            <div class="form-group">
              <select
                id="status-title"
                v-model="form.status"
                class="form-control input-box"
                aria-describedby="statusTitle"
                placeholder="status Title"
                :class="{ 'is-invalid': form.errors.has('status') }"
              >
                <option value="1">Active</option>
                <option value="0">inActive</option>
              </select>
              <label for="status-select">Status</label>
            </div>
            <div class="actions d-flex justify-content-end gap-3">
              <button
                class="btn btn-cancel btn-white px-4 py-3 text-danger"
                @click="seasonDelete($event)"
              >
                Delete
              </button>
              <button
                class="btn btn-save btn-primary px-5"
                @click="updateSeason"
              >
                Save
              </button>
            </div>
            <div class="description-season">
              <p>Are you sure you want to delete job? (prompt to confirm)</p>
              <p>
                <b
                  >Programmer can request deletion and needs to be QC’d before
                  removal. Provide reason for deletion.</b
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
  
<script>
import Header from "./header";

export default {
  data: function () {
    return {
      env: {},
      form: new Form({
        id: "",
        name: "",
        status: "",
      }),
      name: "",
      status: "",
      files: [],
    };
  },
  components: {
    Header,
  },
  methods: {
    getSeason() {
      this.$Progress.start();
      axios
        .post("/api/season/getSeasonById",{id: this.$route.params.season_id})
        .then(({ data }) => {
          this.form.fill(data.data[0]);
          // this.name = data.data[0].name;
          // this.status = data.data.status;
          // console.log(this.name, '3333');
        });

      this.$Progress.finish();
    },
    seasonDelete(event) {
      event.preventDefault();
      this.$Progress.start();
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        input: "text",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
        inputPlaceholder: "Write a reason!",
        preConfirm: (reason) => {
          // if (reason === "")
            // Swal.showValidationMessage("You need to write a reason!");
        },
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete(`/api/season/${this.form.id}?reason=${result.value}`).then(({ data }) => {
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            this.$emit("reload");
            this.$router.go(-1);
          }).catch((data) => {
            Swal.fire("Failed!", data.message, "warning");
          });
        }
      });
      this.$Progress.finish();
    },
    updateSeason() {
      this.$Progress.start();

      // this.form.release_date = new Date(this.form.release_date).toISOString();

      this.form
        .put("/api/season/" + this.form.id)
        .then((response) => {
          // success
          Toast.fire({
            icon: "success",
            title: response.data.message,
          });
          this.$Progress.finish();
          this.$router.go(-1);
        })
        .catch(() => {
          this.$Progress.fail();
        });
    },
    async uploadS3(fileObj, component) {
      const s3 = new AWS.S3({
        accessKeyId: this.env.AWS_ACCESS_KEY_ID,
        secretAccessKey: this.env.AWS_SECRET_ACCESS_KEY,
        region: "us-east-1",
      });
      const folderName = this.form.folder_name; // ger folder name from $gate

      const file = fileObj.file; // Get the selected file from the file input
      const fileName = file.name; // Get the file name

      const params = {
        Bucket: this.env.AWS_BUCKET,
        Key: "drop/" + folderName + "/" + fileName,
        Body: file,
        ACL: "public-read", // Set the ACL permissions to allow public read access to the file
      };

      const data = await s3.upload(params).promise();

      this.form.poster = data.Location;
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Add file

        // Filter non-image file
        // Will not be added to files
        if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
          return prevent();
        }

        // Create the 'blob' field for thumbnail preview
        newFile.blob = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
      }

      if (newFile && oldFile) {
        // Update file

        // Increase the version number
        if (!newFile.version) {
          newFile.version = 0;
        }
        newFile.version++;
      }
    },
    formatBytes(bytes, decimals) {
      if (bytes == 0) return "0 Bytes";
      var k = 1024,
        dm = decimals || 2,
        sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
  },
  created() {
    this.getSeason();
    // axios.get("/api/env").then((response) => {
    //   this.env = response.data;
    // });
  },
};
</script>
  
  <style scoped lang="scss">
.content {
  padding: 70px 100px;

  .section-title {
    margin-bottom: 30px;
  }

  .small-title {
    font-weight: 600;
    font-size: 25px;
    line-height: 29px;
    color: #323b4b;
    font-family: Inter;
    margin-bottom: 40px;
  }

  .edit-grid {
    gap: 40px;

    .image-select {
      width: 165px;

      img {
        margin-bottom: 15px;
        width: 100%;
      }

      .file-select-label {
        height: 40px;
        width: 125px;
        background-color: #ffffff;
        border: 1px solid #b6b6b6;
        border-radius: 15px;
        font-weight: 600;
        font-size: 14px;
        line-height: 38px;
        text-align: center;
        color: #323b4b;
        margin-bottom: 10px;
      }

      .poster-name {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 29px;
        color: #8a94a6;
      }

      .success {
        i {
          color: green;
        }
      }
    }

    .edit-box {
      padding-left: 165px;
      flex: 1;

      .form-group {
        margin-bottom: 30px;

        input,
        textarea,
        select {
          margin-bottom: 5px;
        }

        select {
          font-weight: 500;
          font-size: 14px;
          line-height: 29px;
          color: #b0b7c3;
          border-radius: 15px;
        }

        .textarea {
          height: 100px;
        }

        label {
          padding: 0px 20px;
          margin-bottom: 0;
          font-weight: 500 !important;
          font-size: 18px;
          line-height: 29px;
          color: #8a94a6;
        }
      }
      .description-season {
        margin-top: 30px;
        float: right;
        width: 50%;
        p {
          font-size: 14px;
          color: #b0b7c3;
        }
      }
    }
  }
}
</style>
  