var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Header"),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "back-button" }, [
          _c(
            "button",
            { staticClass: "btn btn-back", on: { click: _vm.goBack } },
            [_vm._v("Back")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "content" }, [
        _c("h3", { staticClass: "small-title" }, [_vm._v("Basic Details")]),
        _vm._v(" "),
        _c("div", { staticClass: "form" }, [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.createSchedule()
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("v-select", {
                    staticClass: "new-styles",
                    attrs: {
                      label: "name",
                      options: _vm.series,
                      taggable: true,
                      id: "series-option",
                      placeholder: "Select a Programmer",
                    },
                    on: { input: _vm.selectedSeries },
                    model: {
                      value: _vm.selectedValue,
                      callback: function ($$v) {
                        _vm.selectedValue = $$v
                      },
                      expression: "selectedValue",
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "series-option" } }, [
                    _vm._v("Select a Programmer"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("v-select", {
                    staticClass: "new-styles",
                    attrs: {
                      label: "file",
                      options: _vm.files,
                      reduce: (file) => file.code,
                      taggable: true,
                      id: "file",
                      placeholder: "Which Files?",
                    },
                    model: {
                      value: _vm.form.file,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "file", $$v)
                      },
                      expression: "form.file",
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "frequency" } }, [
                    _vm._v("Frequency"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.email_address,
                      expression: "form.email_address",
                    },
                  ],
                  staticClass: "form-control input-box",
                  attrs: {
                    type: "text",
                    label: "email_address",
                    id: "sendToEmail",
                    "aria-describedby": "sendToEmail",
                    placeholder: "Input The Email",
                  },
                  domProps: { value: _vm.form.email_address },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "email_address", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "sendToEmail" } }, [
                  _vm._v("Send To Email"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("v-select", {
                    staticClass: "new-styles",
                    attrs: {
                      label: "frequency",
                      options: _vm.frequencies,
                      reduce: (frequency) => frequency.code,
                      taggable: true,
                      id: "frequency",
                      placeholder: "Frequency",
                    },
                    model: {
                      value: _vm.form.frequency,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "frequency", $$v)
                      },
                      expression: "form.frequency",
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "frequency" } }, [
                    _vm._v("Frequency"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.message,
                      expression: "form.message",
                    },
                  ],
                  staticClass: "textarea form-control",
                  attrs: { id: "email-message" },
                  domProps: { value: _vm.form.message },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "message", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "email-message" } }, [
                  _vm._v("Email Message"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "actions d-flex justify-content-start gap-3" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-cancel btn-white px-4 py-3",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push("/dashboard")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\r\n                        Delete\r\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-save btn-primary px-5",
                      attrs: { type: "submit" },
                    },
                    [
                      _vm._v(
                        "\r\n                        Schedule\r\n                    "
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h1", { staticClass: "section-title" }, [
        _vm._v("Start an Automation"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }