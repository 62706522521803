var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Header"),
      _vm._v(" "),
      _c("section", { staticClass: "content" }, [
        _c("h1", { staticClass: "section-title" }, [_vm._v("Edit Series")]),
        _vm._v(" "),
        _c("div", [
          _c(
            "div",
            { staticClass: "d-flex edit-grid" },
            [
              _c("file-upload", {
                ref: "thumbnailUpload",
                staticClass: "d-none",
                attrs: { "custom-action": _vm.uploadS3, accept: "image/*" },
                on: { "input-filter": _vm.inputFilter },
                model: {
                  value: _vm.files,
                  callback: function ($$v) {
                    _vm.files = $$v
                  },
                  expression: "files",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "image-select" }, [
                _vm.form.poster && !_vm.files.length
                  ? _c("img", {
                      staticClass: "d-block",
                      attrs: { src: _vm.form.poster, alt: "Series Poster" },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.files.length
                  ? _c("img", {
                      staticClass: "d-block",
                      attrs: { src: _vm.files[0].blob, alt: "Series Poster" },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.files.length
                  ? _c(
                      "ul",
                      _vm._l(_vm.files, function (file) {
                        return _c("li", { key: file.id }, [
                          _c("span", [_vm._v(_vm._s(file.name))]),
                          _vm._v(" -\n              "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.formatBytes(file.size))),
                          ]),
                          _vm._v(" -\n              "),
                          file.error
                            ? _c("span", [_vm._v(_vm._s(file.error))])
                            : file.success
                            ? _c("span", { staticClass: "success" }, [
                                _vm._v("success\n                "),
                                _c("i", {
                                  staticClass: "fa fas fa-check-circle ml-2",
                                }),
                              ])
                            : file.active
                            ? _c("span", [_vm._v("uploading...")])
                            : _c("span"),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "label",
                  { staticClass: "file-select-label", attrs: { for: "file" } },
                  [_vm._v("Browse Files")]
                ),
                _vm._v(" "),
                _c("input", {
                  staticClass: "d-none",
                  attrs: { type: "text", id: "file" },
                }),
                _vm._v(" "),
                _c("p", { staticClass: "poster-name" }, [
                  _vm._v("Series Poster"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "upload-btn" }, [
                  !_vm.$refs.thumbnailUpload ||
                  !_vm.$refs.thumbnailUpload.active
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-primary px-4",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.$refs.thumbnailUpload.active = true
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-arrow-up",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v("\n              Start Upload\n            "),
                        ]
                      )
                    : _c(
                        "button",
                        {
                          staticClass: "btn btn-danger px-4",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.$refs.thumbnailUpload.active = false
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-stop",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v("\n              Stop Upload\n            "),
                        ]
                      ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "edit-box" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.name,
                        expression: "form.name",
                      },
                    ],
                    staticClass: "form-control input-box",
                    class: { "is-invalid": _vm.form.errors.has("name") },
                    attrs: {
                      type: "text",
                      id: "series-title",
                      "aria-describedby": "seriesTitle",
                      placeholder: "Series Title",
                    },
                    domProps: { value: _vm.form.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "name", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "series-title" } }, [
                    _vm._v("Series Title"),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.short_description,
                        expression: "form.short_description",
                      },
                    ],
                    staticClass: "textarea form-control",
                    class: {
                      "is-invalid": _vm.form.errors.has("short_description"),
                    },
                    attrs: { id: "short-description" },
                    domProps: { value: _vm.form.short_description },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.form,
                          "short_description",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "short-description" } }, [
                    _vm._v("Short Description"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("datepicker", {
                      attrs: {
                        placeholder: "Select Date",
                        "input-class": {
                          "is-invalid": _vm.form.errors.has("release_date"),
                          "form-control": true,
                        },
                        readonly: false,
                      },
                      model: {
                        value: _vm.form.release_date,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "release_date", $$v)
                        },
                        expression: "form.release_date",
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "publish-date" } }, [
                      _vm._v("Publish Date"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "actions d-flex justify-content-end gap-3" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-cancel btn-white px-4 py-3",
                        on: {
                          click: function ($event) {
                            return _vm.$router.go(-1)
                          },
                        },
                      },
                      [_vm._v("\n              Cancel\n            ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-save btn-primary px-5",
                        on: { click: _vm.updateSeries },
                      },
                      [_vm._v("\n              Save\n            ")]
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }