var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _c("div", { staticClass: "quality-control d-flex align-items-center" }, [
      _c("div", { staticClass: "thumbnail" }, [
        _vm._v("\n            Thumbnail\n        "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.video.fileName))]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "action" }, [
        _c("a", { attrs: { href: "#" }, on: { click: _vm.handleExpand } }, [
          _c("i", { staticClass: "fas fa-pencil-alt" }),
          _vm._v("\n                Edit\n            "),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _vm.expand
      ? _c(
          "div",
          { staticClass: "save-form", attrs: { id: "save-form-" + _vm.idx } },
          [
            _c("h3", { staticClass: "small-title" }, [_vm._v("Basic Details")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.title,
                      expression: "form.title",
                    },
                  ],
                  staticClass: "form-control input-box",
                  class: { "is-invalid": _vm.form.errors.has("title") },
                  attrs: {
                    type: "text",
                    id: "eposode-title",
                    "aria-describedby": "seriesTitle",
                    placeholder: "Episode Title",
                  },
                  domProps: { value: _vm.form.title },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "title", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "eposode-title" } }, [
                  _vm._v("Episode Title"),
                ]),
                _vm._v(" "),
                _c("has-error", { attrs: { form: _vm.form, field: "title" } }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.short_description,
                      expression: "form.short_description",
                    },
                  ],
                  staticClass: "textarea form-control",
                  class: {
                    "is-invalid": _vm.form.errors.has("short_description"),
                  },
                  attrs: { name: "", id: "short-description" },
                  domProps: { value: _vm.form.short_description },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.form,
                        "short_description",
                        $event.target.value
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "short-description" } }, [
                  _vm._v("Short Description"),
                ]),
                _vm._v(" "),
                _c("has-error", {
                  attrs: { form: _vm.form, field: "short_description" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("datepicker", {
                  attrs: {
                    placeholder: "Select Date",
                    "use-utc": false,
                    "input-class": {
                      "is-invalid": _vm.form.errors.has("release_date"),
                      "form-control": true,
                    },
                    readonly: false,
                  },
                  model: {
                    value: _vm.form.release_date,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "release_date", $$v)
                    },
                    expression: "form.release_date",
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "publish-date" } }, [
                  _vm._v("Publish Date"),
                ]),
                _vm._v(" "),
                _c("has-error", {
                  attrs: { form: _vm.form, field: "release_date" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "tags-container",
                class: _vm.form.errors.has("tags") ? "is-invalid" : "",
              },
              [
                _c("vue-tags-input", {
                  attrs: {
                    tags: _vm.form.tags,
                    "autocomplete-items": _vm.filteredItems,
                  },
                  on: { "tags-changed": _vm.tagsChanged },
                  model: {
                    value: _vm.form.tag,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "tag", $$v)
                    },
                    expression: "form.tag",
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "" } }, [
                  _vm._v("Keywords / Tags"),
                ]),
                _vm._v(" "),
                _c("has-error", { attrs: { form: _vm.form, field: "tags" } }),
              ],
              1
            ),
            _vm._v(" "),
            _c("file-upload", {
              ref: "thumbnailUpload",
              staticClass: "d-none",
              attrs: { "input-id": _vm.video.id, accept: "image/*" },
              on: { "input-filter": _vm.inputFilter },
              model: {
                value: _vm.thumbFiles,
                callback: function ($$v) {
                  _vm.thumbFiles = $$v
                },
                expression: "thumbFiles",
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "image-select",
                class: { "is-invalid": _vm.form.errors.has("thumbnail") },
              },
              [
                _vm.thumbFiles.length
                  ? _c(
                      "ul",
                      [
                        _c("img", {
                          staticClass: "d-block",
                          attrs: {
                            src: _vm.thumbFiles[0].blob,
                            alt: "Video Thumbnail",
                          },
                        }),
                        _vm._v(" "),
                        _vm._l(_vm.thumbFiles, function (file) {
                          return _c("li", { key: file.id }, [
                            _c("span", [_vm._v(_vm._s(file.name))]),
                            _vm._v(" -\n                    "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$parent.formatBytes(file.size))
                              ),
                            ]),
                            _vm._v(" -\n                    "),
                            file.error
                              ? _c("span", [_vm._v(_vm._s(file.error))])
                              : file.success
                              ? _c("span", { staticClass: "success" }, [
                                  _vm._v("success\n                        "),
                                  _c("i", {
                                    staticClass: "fa fas fa-check-circle ml-2",
                                  }),
                                ])
                              : file.active
                              ? _c("span", [_vm._v("uploading...")])
                              : _c("span"),
                          ])
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "file-select-label",
                    attrs: { for: _vm.video.id },
                  },
                  [_vm._v("Browse Files")]
                ),
                _vm._v(" "),
                _c("p", { staticClass: "poster-name" }, [
                  _vm._v("Video Thumbnail"),
                ]),
                _vm._v(" "),
                _c("has-error", {
                  attrs: { form: _vm.form, field: "thumbnail" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "image-select" },
              [
                _vm.captionFiles.length
                  ? _c(
                      "ul",
                      _vm._l(_vm.captionFiles, function (file) {
                        return _c("li", { key: file.id }, [
                          _c("span", [_vm._v(_vm._s(file.name))]),
                          _vm._v(" -\n                    "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$parent.formatBytes(file.size))),
                          ]),
                          _vm._v(" -\n                    "),
                          file.error
                            ? _c("span", [_vm._v(_vm._s(file.error))])
                            : file.success
                            ? _c("span", { staticClass: "success" }, [
                                _vm._v("success\n                        "),
                                _c("i", {
                                  staticClass: "fa fas fa-check-circle ml-2",
                                }),
                              ])
                            : file.active
                            ? _c("span", [_vm._v("uploading...")])
                            : _c("span"),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "file-upload",
                  {
                    ref: "captionUpload",
                    staticClass: "file-select-label",
                    attrs: { accept: ".srt,.vtt", "input-id": "caption" },
                    model: {
                      value: _vm.captionFiles,
                      callback: function ($$v) {
                        _vm.captionFiles = $$v
                      },
                      expression: "captionFiles",
                    },
                  },
                  [_vm._v("\n                Browse Files\n            ")]
                ),
                _vm._v(" "),
                _vm._m(1),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "actions" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { disabled: _vm.isUploading },
                  on: { click: _vm.createVideo },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.saved ? "Saved" : "Save") +
                      "\n            "
                  ),
                ]
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "status" }, [_c("span", [_vm._v("ready")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "poster-name" }, [
      _vm._v("Closed Captions"),
      _c("br"),
      _vm._v("(SRT or VTT files)"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }