<template>
  <div>
    <Header></Header>
    <section class="content">
      <h1 class="section-title">
        {{ $gate.isAdmin() ? "Quality Control Check" : "Edit Video" }}
      </h1>
      <div>
        <div class="back-button d-flex justify-content-end">
          <button class="btn btn-back" @click="goBack">Back</button>
        </div>
        <div class="banner">
          <div class="video-box">
            <!-- <div class="player-wrapper">
              <div id="player"></div>
            </div> -->
            <video class="quality-control-video" ref="qualityVideo" :src="form.url" controls></video>
            <img v-if="!playStarted && form.thumbnail" class="thumbnail" :src="form.thumbnail" alt="" @click="
              $refs.qualityVideo.play();
            playStarted = true;
            " />
          </div>
          <div id="peak-meter" ref="peakMeter" style="height: 72px"></div>
        </div>
        <div class="deploy-button">
          <h3 class="small-title">Basic Details</h3>
          <button @click="copyEmbedCode" :disabled="copied" class="action">
            <i class="fas fa-paperclip"></i>
            {{ embedButton }}</button>
        </div>
        <div class="form-group">
          <input type="text" id="eposode-title" v-model="form.title" class="form-control input-box"
            :class="{ 'is-invalid': form.errors.has('title') }" aria-describedby="seriesTitle"
            placeholder="Episode Title" />
          <label for="eposode-title">Episode Title</label>
          <!-- <has-error :form="form" field="title"></has-error> -->
        </div>
        <div class="form-group">
          <textarea name="" id="short-description" v-model="form.short_description" class="textarea form-control"
            :class="{ 'is-invalid': form.errors.has('short_description') }"></textarea>
          <label for="short-description">Short Description</label>
        </div>
        <div class="form-group">
          <datepicker placeholder="Select Date" v-model="form.release_date" :input-class="{
          'is-invalid': form.errors.has('release_date'),
          'form-control': true
        }" :readonly="false">
          </datepicker>
          <label for="publish-date">Publish Date</label>
          <!-- <has-error :form="form" field="release_date"></has-error> -->
        </div>
        <div class="form-group">
          <select v-model="form.season_id" class="select form-control">
            <option v-for="season in seasons" :value="season.id" :key="season.id">
              {{ season.name }}
            </option>
          </select>
          <label for="season">Season</label>
          <!-- <has-error :form="form" field="release_date"></has-error> -->
        </div>

        <div class="tags-container">
          <vue-tags-input v-model="tag" :tags="form.tags" @tags-changed="tagsChanged"
            :autocomplete-items="filteredItems" />
          <label for="">Keywords / Tags</label>
        </div>

        <div class="image-select">
          <img v-if="form.thumbnail && !thumbFiles.length" :src="form.thumbnail" alt="Series Poster" class="d-block" />
          <ul v-if="thumbFiles.length">
            <img :src="thumbFiles[0].blob" alt="Video Thumbnail" class="d-block" />
            <li v-for="file in thumbFiles" :key="file.id">
              <span>{{ file.name }}</span> -
              <span>{{ formatBytes(file.size) }}</span> -
              <span v-if="file.error">{{ file.error }}</span>
              <span v-else-if="file.success" class="success">success
                <i class="fa fas fa-check-circle ml-2"></i>
              </span>
              <span v-else-if="file.active">uploading...</span>
              <span v-else></span>
            </li>
          </ul>
          <file-upload class="file-select-label" v-model="thumbFiles" ref="thumbnailUpload" @input-filter="inputFilter"
            accept="image/*" input-id="thumbnail">
            Browse Files
          </file-upload>
          <p class="poster-name">Video Thumbnail</p>

          <ul v-if="captionFiles.length">
            <li v-for="file in captionFiles" :key="file.id">
              <span>{{ file.name }}</span> -
              <span>{{ formatBytes(file.size) }}</span> -
              <span v-if="file.error">{{ file.error }}</span>
              <span v-else-if="file.success" class="success">success
                <i class="fa fas fa-check-circle ml-2"></i>
              </span>
              <span v-else-if="file.active">uploading...</span>
              <span v-else></span>
            </li>
          </ul>
          <file-upload class="file-select-label" v-model="captionFiles" ref="captionUpload" accept=".srt,.vtt"
            input-id="caption">
            Browse Files
          </file-upload>
          <p class="poster-name">Closed Captions<br />(SRT or VTT files)</p>
        </div>
        <div v-if="$gate.isAdmin()" class="actions">
          <button v-if="!form.approved" class="btn btn-white" @click="deleteVideo">
            Denied
          </button>
          <button v-if="form.approved" class="btn btn-white" @click="goBack">
            Cancel
          </button>
          <button class="btn btn-primary" @click="updateVideo" :disabled="isUpdating">
            {{ form.approved ? "Save" : "Approved" }}
          </button>
        </div>
        <div v-else class="actions">
          <button class="btn btn-white" @click="goBack">Cancel</button>
          <button class="btn btn-primary" @click="updateVideo">Save</button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Header from "./header";
import VueTagsInput from "@johmun/vue-tags-input";
import Datepicker from "vuejs-datepicker";
import FileUpload from "vue-upload-component";
import {
  v4 as uuidv4
} from "uuid";
import "moment-timezone";
import bitmovin from 'bitmovin-player';
import {
  PlayerEvent
} from "bitmovin-player";
import { UIFactory } from "bitmovin-player-ui";
import "bitmovin-player-ui/dist/css/bitmovinplayer-ui.min.css";
import AudioMotionAnalyzer from 'audiomotion-analyzer';

const webAudioPeakMeter = require("web-audio-peak-meter");

export default {
  data: function () {
    return {
      env: {},
      tag: "",
      form: new Form({
        id: "",
        title: "",
        short_description: "",
        url: "",
        video_type: "",
        quality: "",
        tags: [],
        season_id: "",
        release_date: "",
        thumbnail: "",
        caption: "",
        series_id: "",
        user_id: this.$gate.user.id,
        approved: null,
        approve_action: "NO",
        user_edit: "NO",
      }),
      seasons: [],
      thumbFiles: [],
      captionFiles: [],
      autocompleteItems: [],
      playStarted: false,
      isUpdating: false,
      player: null,
      uiManager: null,
      copied: false,
      source: {
        title: '',
        progressive: '',
      }
    };
  },
  components: {
    Header,
    VueTagsInput,
    Datepicker,
    FileUpload,
  },
  methods: {
    copyEmbedCode() {
      const embedString = `<!DOCTYPE html>
        <html lang="en">
        <head>
          <title>Bitmovin Player Demo<\/title>
          <meta charset="UTF-8"\/>
          <meta http-equiv="X-UA-Compatible" content="text/html; charset=utf-8" \/>

          <script type="text/javascript" src="https://cdn.jsdelivr.net/npm/bitmovin-player@8/bitmovinplayer.js"><\/script>

          <style>
            .player-wrapper {
              position: relative;
              overflow: hidden;
            }
            .bmpui-ui-watermark{
              display:none !important;
            }
            .thumbnail {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          <\/style>
        <\/head>
        <body>
        <div class="player-wrapper">
          <div id="player"><\/div>
          <img
            class="thumbnail"
            id="thumbnail"
            src='${this.form.thumbnail}'
            alt=""
            onclick="
              playVideo()
            "
          />
        <\/div>

        <script type="text/javascript">
          var playStarted = false;
          var conf = {
            key: 'af63d141-8c92-4101-98ac-4d3b1ff26786',
            analytics: {
              key: 'd8b7bb76-80c1-480b-8889-534090d74cee',
              title: '${this.form.title}',
              videoId: '${this.form.id}',
            },
          };

          var source = {
            title: '${this.form.title}',
            progressive: '${this.form.url}',
          };

          var player = new bitmovin.player.Player(document.getElementById('player'), conf);
          player.load(source);
         
          function playVideo() {
            const thumbnail = document.getElementById("thumbnail");
            if(thumbnail)
              thumbnail.style.display = "none";
            this.player.play();
          }
        <\/script>

        <\/body>
        <\/html>
        `;
      const textarea = document.createElement('textarea');
      textarea.value = embedString;
      document.body.appendChild(textarea);

      textarea.select();
      document.execCommand('copy');

      document.body.removeChild(textarea);

      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 3000);
    },
    loadVideo() {
      this.$Progress.start();
      axios
        .get("/api/video/" + this.$route.params.video_id)
        .then(({
          data
        }) => {
          this.form.fill(data.data);
          this.initAudioMeter();

          // this.source.progressive = this.form.url;
          // this.source.title = this.form.title;
          // const player = new bitmovin.Player(document.getElementById('player'), {
          //   key: 'af63d141-8c92-4101-98ac-4d3b1ff26786',
          //   analytics: {
          //     key: 'd8b7bb76-80c1-480b-8889-534090d74cee',
          //     title: 'form.title',
          //     videoId: 'form.id',
          //   },
          //   ui: {}
          // });
          // this.uiManager = UIFactory.buildDefaultUI(player);
          // player.on(PlayerEvent.Destroy, () => {
          //   this.uiManager.release();
          // });
          // player.load(this.source).then(() => {
          //   this.player = player;
          //   this.initAudioMeter();

          //   const waterMark = document.querySelector("button.bmpui-ui-watermark");
          //   if (waterMark)
          //     waterMark.style.display = "none";
          // }, () => {
          //   console.log('Error while loading source');
          // });
        });
      this.$Progress.finish();
    },
    getSeasons() {
      this.$Progress.start();
      axios
        .post("/api/season/getSeasonByVideoId", {
          video_id: this.$route.params.video_id,
        })
        .then(({
          data
        }) => {
          // this.form.fill(data.data);
          this.seasons = data.data;
          // this.initAudioMeter();
        });
      this.$Progress.finish();
    },
    loadTags() {
      axios
        .get("/api/tag/list")
        .then((response) => {
          this.autocompleteItems = response.data.data.map((a) => {
            return {
              text: a.name,
              id: a.id
            };
          });
        })
        .catch(() => console.warn("Oh. Something went wrong"));
    },
    async uploadS3(fileObj, component) {
      const s3 = new AWS.S3({
        accessKeyId: this.env.AWS_ACCESS_KEY_ID,
        secretAccessKey: this.env.AWS_SECRET_ACCESS_KEY,
        region: "us-east-1",
      });
      const folderName = this.$gate.user.folder_name; // get folder name from $gate

      const file = fileObj.file; // Get the selected file from the file input
      const fileName = file.name.split("."); // Get the file name
      const uuid = uuidv4() + "." + fileName[fileName.length - 1];

      const params = {
        Bucket: this.env.AWS_BUCKET,
        Key: "drop/" + folderName + "/" + uuid,
        Body: file,
        ACL: "public-read", // Set the ACL permissions to allow public read access to the file
      };

      const data = await s3.upload(params).promise();
      return data.Location;
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Add file

        // Filter non-image file
        // Will not be added to files
        if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
          return prevent();
        }

        // Create the 'blob' field for thumbnail preview
        newFile.blob = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
      }

      if (newFile && oldFile) {
        // Update file

        // Increase the version number
        if (!newFile.version) {
          newFile.version = 0;
        }
        newFile.version++;
      }
    },
    formatBytes(bytes, decimals) {
      if (bytes == 0) return "0 Bytes";
      var k = 1024,
        dm = decimals || 2,
        sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    async updateVideo() {
      if (this.isUpdating) return;

      this.$Progress.start();
      this.isUpdating = true;

      this.form.release_date = new Date(this.form.release_date).toISOString();

      if (this.thumbFiles.length > 0) {
        this.form.thumbnail = await this.uploadS3(this.thumbFiles[0]);
      }
      if (this.captionFiles.length > 0) {
        this.form.caption = await this.uploadS3(this.captionFiles[0]);
      }

      if (!this.form.approved && this.$gate.isAdmin()) {
        this.form.approve_action = "YES";
      }
      if (!this.$gate.isAdmin()) {
        this.form.user_edit = "YES";
      }
      this.form.approved = this.$gate.isAdmin() ? true : false;
      this.form.user_id = this.$gate.user.id;
      this.form
        .put("/api/video/" + this.form.id)
        .then((response) => {
          Toast.fire({
            icon: "success",
            title: response.data.message,
          });
          this.$Progress.finish();
          this.loadVideo();
          this.isUpdating = false;
        })
        .catch(() => {
          this.$Progress.fail();
          this.isUpdating = false;
        });
    },
    deleteVideo() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        input: "text",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
        inputPlaceholder: "Write a reason!",
        preConfirm: (reason) => {
          if (reason === "")
            Swal.showValidationMessage("You need to write a reason!");
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.form
            .delete(`/api/video/${this.form.id}?userId=${this.$gate.user.id}&reason=${result.value}`)
            .then(() => {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
              this.goBack();
            })
            .catch((data) => {
              Swal.fire("Failed!", data.message, "warning");
            });
        }
      });
    },
    goBack() {
      if (this.$route.query.series_id) {
        if (this.$gate.isAdmin()) {
          this.$router.push("/videos/" + this.$route.query.series_id);
        } else {
          this.$router.push("/quality-uploads/" + this.$route.query.series_id);
        }
      } else {
        this.$router.push("/quality-uploads/");
      }
    },
    initAudioMeter() {
      const audioCtx = new AudioContext();
      const videoElement = this.$refs.qualityVideo;//document.getElementById("bitmovinplayer-video-player");
      if (!videoElement) return;
      videoElement.crossOrigin = "anonymous";

      const meterElement = this.$refs.peakMeter;

      const sourceNode = audioCtx.createMediaElementSource(videoElement);
      sourceNode.connect(audioCtx.destination);
      const options = {
        audioMeterStandard: "true-peak", // peak-sample | true-peak
        dbRangeMin: -60,
        dbTickSize: 3,
      };

      const test = new webAudioPeakMeter.WebAudioPeakMeter(
        sourceNode,
        meterElement,
        options
      );

      // Create an analyzer instance
      // const analyzer = new AudioMotionAnalyzer(document.getElementById('peak-meter'), {
      //   source: audioCtx.createMediaElementSource(videoElement),
      //   mode: 3, // Use a mode that suits your needs; refer to the audiomotion-analyzer documentation
      //   gradient: 'classic', // Choose a gradient
      //   audioCtx: audioCtx // Pass the AudioContext
      // });

      videoElement.addEventListener("play", () => {
        audioCtx.resume();
      });

      videoElement.addEventListener("pause", () => {
        audioCtx.suspend();
      });
    },
    tagsChanged(_newTags) {
      if (_newTags.length > 0) {
        const newTags = _newTags[_newTags.length - 1].text.split(",");
        if (newTags.length > 1) {
          _newTags.pop();
          newTags.forEach((tag) => {
            if (tag != "") _newTags.push({
              text: tag
            });
          });
        }
      }
      this.form.tags = _newTags;
    }
  },
  created() {
    this.$Progress.start();
    axios.get("/api/env").then((response) => {
      this.env = response.data;
    });
    this.loadVideo();
    this.getSeasons();
    this.loadTags();
    this.$Progress.finish();
  },
  mounted() { },
  computed: {
    embedButton() {
      return this.copied ? 'Copied' : 'Embed'
    },
    filteredItems() {
      return this.autocompleteItems.filter((i) => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
      });
    },
  },
  beforeDestroy() {
    if (this.player) {
      this.player.destroy();
    }
  },
};
</script>
<style lang="scss" scoped>
.content {
  padding: 70px 100px;

  .section-title {
    margin-bottom: 30px;
  }

  .small-title {
    font-weight: 600;
    font-size: 25px;
    line-height: 29px;
    color: #323b4b;
    font-family: Inter;
    margin-bottom: 40px;
  }

  .banner {
    margin-bottom: 40px;

    .video-box {
      position: relative;

      .thumbnail {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    video {
      width: 100%;
    }
  }

  .image-select {
    width: 165px;
    margin-bottom: 30px;

    img {
      margin-bottom: 15px;
      width: 100%;
    }

    .file-select-label {
      height: 40px;
      width: 125px;
      background-color: #ffffff;
      border: 1px solid #b6b6b6;
      border-radius: 15px;
      font-weight: 600;
      font-size: 14px;
      line-height: 38px;
      text-align: center;
      color: #323b4b;
      margin-bottom: 10px;
    }

    .poster-name {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 29px;
      color: #8a94a6;
    }

    .success {
      i {
        color: green;
      }
    }
  }

  .form-group {
    margin-bottom: 30px;

    input,
    textarea,
    select {
      margin-bottom: 5px;
    }

    select {
      width: 370px;
      height: 50px;
      font-weight: 500;
      font-size: 14px;
      line-height: 29px;
      color: #b0b7c3;
      border-radius: 15px;
    }

    .textarea {
      height: 100px;
    }

    label {
      padding: 0px 20px;
      margin-bottom: 0;
    }
  }

  .tags-container {
    margin-bottom: 30px;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 30px;

    .btn-primary,
    .btn-white {
      padding: 8px 30px;
    }
  }

  .btn-back {
    font-weight: 500;
    font-size: 16px;
    line-height: 29px;
    color: #8a94a6;
  }

  .control-bar {
    display: none;
  }
}

.deploy-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.action {
  width: 15%;
  padding: 5px;

  a {
    display: inline-block;
    width: 100px;
    height: 25px;
    background: #f8f8f9;
    border-radius: 15px;
    padding: 0 15px;
    font-weight: 500;
    font-size: 12px;
    line-height: 25px;
    color: #b0b7c3;

    i {
      margin-right: 4px;
    }
  }
}
</style>
