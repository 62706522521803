<template>
  <div>
    <Header></Header>
    <section class="content">
      <h1 class="section-title">Video Upload</h1>
      <p>
        Drag and Drop your videos on the upload area below or click browse and
        add your videos. Click Start Upload to send Real Life Network your video
        content. Once the videos are uploaded you will be prompt to add meta
        data to each video below. Make sure to click save to complete your video
        uploads. You will recieve an email receipt letting you know we
        successfully recieved your content.
      </p>
      <div class="drop">
        <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
          <h3>Drop files to upload</h3>
        </div>
        <file-upload class="d-none" :multiple="true" :drop="true" :drop-directory="true" v-model="files" ref="upload"
          :custom-action="uploadS3" accept="video/*" :timeout="600 * 1000">
          <i class="fa fa-plus"></i>
          Select files
        </file-upload>

        <div class="drop-zone">
          <label for="file">
            <img src="/images/upload-icon.png" alt="Upload Icon" />
          </label>
          <p class="upload-text">
            Drag and Drop or <label for="file">Browse</label> to upload
          </p>
          <p class="file-counts" v-if="files.length">
            {{ files.length }} files added
          </p>
          <ul v-if="files.length">
            <li v-for="file in files" :key="file.id">
              <div class="d-flex">
                <span>{{ file.name }}</span> -
                <span>{{ formatBytes(file.size) }}</span> -
                <span v-if="file.error">{{ file.error }}</span>
                <span v-else-if="file.success" class="success">success
                  <i class="fa fas fa-check-circle ml-2"></i>
                </span>
                <span v-else-if="file.active">uploading...</span>
                <span v-else></span>
              </div>
              <div class="progress" v-if="file.active">
                <div :class="{
          'progress-bar': true,
          'progress-bar-striped': true,
          'bg-danger': file.error,
          'progress-bar-animated': file.active,
        }" role="progressbar" :style="{ width: progress + '%' }">
                  {{ progress }}%
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="upload-btn m-4">
        <button type="button" class="btn btn-primary px-4" v-if="!$refs.upload || !$refs.upload.active"
          @click.prevent="$refs.upload.active = true">
          <i class="fa fa-arrow-up" aria-hidden="true"></i>
          Start Upload
        </button>
        <button type="button" class="btn btn-danger px-4" v-else @click.prevent="$refs.upload.active = false">
          <i class="fa fa-stop" aria-hidden="true"></i>
          Stop Upload
        </button>
      </div>
      <div v-if="videos.length" class="videos">
        <div class="videos-head d-flex">
          <div class="count">{{ videos.length }} Videos</div>
          <div class="status">Status</div>
        </div>
        <Video v-for="(video, index) in videos" :key="index" :video="video" :idx="index"
          :series_id="$route.query.series_id ? $route.query.series_id : ''" />
      </div>
    </section>
  </div>
</template>

<script>
import AWS from "aws-sdk";
import Header from "./header";
import Video from "./video";
import FileUpload from "vue-upload-component";

export default {
  data: function () {
    return {
      env: {},
      videos: [],
      files: [],
      progress: "",
      uploadedFiles: 0,
      videoTitles: [],
      series: {},
      fileName: ""
    };
  },
  components: {
    Header,
    Video,
    FileUpload,
  },
  methods: {
    loadSeries() {
      this.$Progress.start();
      let series_id = this.$route.query.series_id;
      if (series_id) {
        axios.get("/api/series/" + series_id).then(({ data }) => {
          this.series = data.data;
        });
      }
      this.$Progress.finish();
    },
    formatBytes(bytes, decimals) {
      if (bytes == 0) return "0 Bytes";
      var k = 1024,
        dm = decimals || 2,
        sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    async uploadS3(fileObj, component) {
      const s3 = new AWS.S3({
        accessKeyId: this.env.AWS_ACCESS_KEY_ID,
        secretAccessKey: this.env.AWS_SECRET_ACCESS_KEY,
        region: "us-east-1",
      });

      const folderName =
        this.$route.query.folder_name ||
        this.series.folder_name ||
        "defaultFolder";
      const file = fileObj.file; // Get the selected file from the file input
      const fileName = file.name; // Get the file name

      const currentDatetime = new Date();
      const datetimeString = currentDatetime
        .toISOString()
        .replace(/[-T:]/g, "")
        .slice(0, 14);
        
      const filenameParts = fileName.split(".");
      const extension = filenameParts.pop();
      const basename = filenameParts.join(".");
      const newFilename = `${basename}_${datetimeString}.${extension}`;

      const params = {
        Bucket: this.env.AWS_BUCKET,
        Key: "drop/" + folderName + "/" + newFilename,
        Body: file,
        ACL: "public-read", // Set the ACL permissions to allow public read access to the file
      };

      const uploadPromise = s3.upload(params);

      uploadPromise.on("httpUploadProgress", (progress) => {
        this.progress = Math.round((progress.loaded / progress.total) * 100);
      });

      const data = await uploadPromise.promise();
      this.progress = "0";
      const duration = await this.getFileDuration(file);
      const type = file.type.split("/")[1].toUpperCase();

      axios
        .post('/api/draft', {
          video_url: data.Location,
          video_type: type,
          video_duration: duration,
          seriesId: this.series.id,
          userId: this.$gate.user.id,
          fileName: fileName
        })
        .then((response) => {
          Toast.fire({
            icon: "success",
            title: response.data.message,
          });
          this.uploadedFiles++;
          console.log(this.uploadedFiles)
        })
        console.log(this.series, this.uploadedFiles)
      },
    async getFileDuration(file) {
      return new Promise((resolve, reject) => {
        const video = document.createElement("video");
        video.src = URL.createObjectURL(file);
        video.onloadedmetadata = function () {
          URL.revokeObjectURL(video.src);
          resolve(video.duration);
        };
      });
    },
  },
  mounted() {
    this.loadSeries();

    axios.get("/api/env").then((response) => {
      this.env = response.data;
    });
    this.$watch(
      () => {
        return this.$refs.upload.active;
      },
      (newValue, oldValue) => {

      },
      { immediate: true }
    );
  },
  watch: {
    uploadedFiles(val) {
      console.log(this.files.length, val)
      if (this.files && this.files.length == val){
        window.location.href = "/draft";
        
      }
    }
  },
};
</script>

<style scoped lang="scss">
.content {
  padding: 70px 100px;

  .section-title {
    margin-bottom: 30px;
  }

  .drop {
    background-color: #f4f8ff;
    border-radius: 15px;
    padding: 30px 110px;
    margin-bottom: 25px;

    .drop-zone {
      border-radius: 15px;
      border: 1.5px dashed #b8b0b0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 30px;

      img {
        margin-bottom: 25px;
      }

      p {
        font-weight: 600;
        font-size: 16px;
        line-height: 29px;
        color: #b0b7c3;
      }

      label {
        color: #377dff;
        cursor: pointer;
      }

      ul li {
        align-items: center;

        .success {
          display: flex;
          align-items: center;

          i {
            color: green;
          }
        }
      }
    }

    .drop-active {
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      position: fixed;
      z-index: 9999;
      opacity: 0.6;
      text-align: center;
      background: #000;
    }

    .drop-active h3 {
      margin: -0.5em 0 0;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      font-size: 40px;
      color: #fff;
      padding: 0;
    }
  }

  .videos {
    .videos-head {
      background: #fafbfc;
      border-radius: 15px;
      padding: 20px 50px;
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #4e5d78;
      margin-bottom: 30px;

      div {
        flex: 1;
      }

      .status {
        padding-left: 200px;
      }
    }
  }

  .btn-warning {
    background: #f0ad4e;
    border-color: #eea236;
    border-radius: 15px;
    height: 60px;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    color: #ffffff;
  }
}
</style>
