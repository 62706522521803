<template>
  <div>
    <div class="header d-flex justify-content-between align-items-center">
      <a href="/" class="site-logo">
        <img src="/images/logo.png" alt="" />
      </a>
      <div class="menu">
        <a v-if="$gate.isAdmin()" href="/dashboard" class="menu-item">Dashboard
        </a>
        <a v-if="$gate.isGuest()" href="/guest" class="menu-item"></a>
        <a :href="$gate.isAdmin() ? '/quality-uploads' : '/videos/' + $gate.user.id
          " class="menu-item">{{
            $gate.isAdmin() ? "Quality Control" : $gate.isUser() ? "Videos" : ""
          }}</a>
        <!-- <a v-if="$gate.isAdmin()" href="/schedule" class="menu-item"
        >Schedule</a> -->
        <a v-if="$gate.isAdmin() || $gate.isUser()" href="/draft" class="menu-item">Draft</a>
        <a v-else></a>
        <a href="mailto:onboarding@reallifenetwork.com" class="menu-item">Support</a>
        <span v-if="name" class="profile-name">Hi, {{name}}</span>
        <div class="profile-wrapper">
          <a href="#" @click="toggleProfile"><img :src="avatarUrl ? avatarUrl : '/images/avatar.png'"
              class="profile-avatar" alt="avatar" />
          </a>
          <div class="submenu" v-if="showProfile">
            <a class="submenu-item" href="/profile">Settings</a>
            <a class="submenu-item" href="#" @click="logout">Logout</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      showProfile: false,
      avatarUrl: "",
      name: null,
    };
  },
  methods: {
    logout() {
      let token = document.head
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content");

      axios
        .post("/logout", {
          _token: token,
        })
        .then((response) => {
          window.location.href = "/login";
        })
        .catch((error) => { });
    },
    toggleProfile(event) {
      event.stopPropagation();
      this.showProfile = true;
    },
    goTOProfile() { },
    handlePageClick(event) {
      this.showProfile = false;
    }
  },
  created() {
    this.$Progress.start();

    axios.get("/api/profile").then(({ data }) => {
      if (data.profile) {
        this.avatarUrl = data.profile.avatar_url;
        this.name = data.profile.name;
      }
    });
    axios.get("/api/env").then((response) => {
      this.env = response.data;
    });
    this.$Progress.finish();

    document.addEventListener("click", this.handlePageClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handlePageClick);
  }
};
</script>

<style lang="scss" scoped>
.header {
  padding: 26px 60px;
  height: 127px;

  .menu {
    position: relative;
    display: flex;
    align-items: center;

    .menu-item {
      font-weight: 500;
      font-size: 16px;
      line-height: 29px;
      color: #8a94a6;
      padding: 10px 20px;
    }

    .profile-name {
      font-size: 16px;
      font-weight: 500;
      &::before {
        content: "";
        border-left: 1px solid #8a94a6;      
        padding-inline: 12px;
      }
    }
    .profile-wrapper {
      position: relative;
      padding-inline: 16px;

      .profile-avatar {
        width: 35px;
        height: 35px;
        border-radius: 50%;
      }

      .submenu {
        position: absolute;
        top: 40px;
        left: -20px;
        z-index: 10;
        background: #F5F5F5;
        border-radius: 2px;
        padding-block: 8px;

        .submenu-item {
          font-weight: 500;
          font-size: 16px;
          line-height: 29px;
          color: #8a94a6;
          padding: 4px 20px;
          &:hover {
            background: #c7c7c7;
          }
        }
      }
    }
  }
}
</style>
