var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Header"),
      _vm._v(" "),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row" }, [
            _c("h1", { staticStyle: { "margin-bottom": "50px" } }, [
              _vm._v("My Profile"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-12" }, [
              _c("div", { staticClass: "card" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "tab-content" }, [
                    _c(
                      "div",
                      {
                        staticClass: "tab-pane active show",
                        attrs: { id: "settings" },
                      },
                      [
                        _c("div", { staticClass: "profile-setting" }, [
                          _c("div", { staticClass: "upload-section" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "border rounded vue-avatar-cropper-demo text-center",
                              },
                              [
                                _c("div", { staticClass: "p-6" }, [
                                  _c("div", { staticClass: "relative" }, [
                                    _c("img", {
                                      staticClass: "avatar",
                                      attrs: {
                                        src: _vm.form.avatarUrl
                                          ? _vm.form.avatarUrl
                                          : "/images/avatar.png",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.showCropper = true
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("avatar-cropper", {
                                  on: {
                                    submit: _vm.handleSubmit,
                                    error: _vm.handlerError,
                                  },
                                  model: {
                                    value: _vm.showCropper,
                                    callback: function ($$v) {
                                      _vm.showCropper = $$v
                                    },
                                    expression: "showCropper",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "form",
                            { staticClass: "form-horizontal setting-section" },
                            [
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "col-sm-4 control-label",
                                    attrs: { for: "inputName" },
                                  },
                                  [_vm._v("Name")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-12" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.form.name,
                                          expression: "form.name",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      class: {
                                        "is-invalid":
                                          _vm.form.errors.has("name"),
                                      },
                                      attrs: {
                                        type: "text",
                                        id: "inputName",
                                        placeholder: "Name",
                                      },
                                      domProps: { value: _vm.form.name },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.form,
                                            "name",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("has-error", {
                                      attrs: { form: _vm.form, field: "name" },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "col-sm-4 control-label",
                                    attrs: { for: "inputEmail" },
                                  },
                                  [_vm._v("Email")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-12" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.form.email,
                                          expression: "form.email",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      class: {
                                        "is-invalid":
                                          _vm.form.errors.has("email"),
                                      },
                                      attrs: {
                                        type: "text",
                                        id: "inputEmail",
                                        placeholder: "Email",
                                      },
                                      domProps: { value: _vm.form.email },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.form,
                                            "email",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("has-error", {
                                      attrs: { form: _vm.form, field: "email" },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-group" }, [
                                _c("div", { staticClass: "col-md-12" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn update-btn",
                                      attrs: { type: "submit" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.updateProfile.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            Update Profile\n                          "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "tab-pane",
                        attrs: { id: "change-password" },
                      },
                      [
                        _c("form", { staticClass: "form-horizontal" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              {
                                staticClass: "col-sm-4 control-label",
                                attrs: {
                                  for: "current_password",
                                  autocomplete: "false",
                                },
                              },
                              [_vm._v("Current Password")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-sm-12" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.current_password,
                                      expression: "form.current_password",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.form.errors.has("current_password"),
                                  },
                                  attrs: {
                                    type: "password",
                                    id: "current_password",
                                    placeholder: "Current Password",
                                  },
                                  domProps: {
                                    value: _vm.form.current_password,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.form,
                                        "current_password",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("has-error", {
                                  attrs: {
                                    form: _vm.form,
                                    field: "current_password",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              {
                                staticClass: "col-sm-4 control-label",
                                attrs: { for: "new_password" },
                              },
                              [_vm._v("New Password")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-sm-12" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.new_password,
                                      expression: "form.new_password",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.form.errors.has("new_password"),
                                  },
                                  attrs: {
                                    type: "password",
                                    id: "new_password",
                                    placeholder: "New Password",
                                  },
                                  domProps: { value: _vm.form.new_password },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.form,
                                        "new_password",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("has-error", {
                                  attrs: {
                                    form: _vm.form,
                                    field: "new_password",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              {
                                staticClass: "col-sm-4 control-label",
                                attrs: { for: "confirm_password" },
                              },
                              [_vm._v("Confirm Password")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-sm-12" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.confirm_password,
                                      expression: "form.confirm_password",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.form.errors.has("confirm_password"),
                                  },
                                  attrs: {
                                    type: "password",
                                    id: "confirm_password",
                                    placeholder: "Confirm Password",
                                  },
                                  domProps: {
                                    value: _vm.form.confirm_password,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.form,
                                        "confirm_password",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("has-error", {
                                  attrs: {
                                    form: _vm.form,
                                    field: "confirm_password",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "div",
                              { staticClass: "col-sm-offset-2 col-sm-12" },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn update-btn",
                                    attrs: { type: "submit" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.updatePassword.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                          Update Password\n                        "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("h1", { staticStyle: { "margin-top": "100px" } }, [
              _vm._v("My Content"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-body table-responsive p-0" }, [
              _c(
                "table",
                {
                  staticClass:
                    "table table-hover table-borderless table-striped",
                },
                [
                  _c("thead", [
                    _c("tr", [
                      _c(
                        "th",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.handleSort("series.name")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                  Series\n                  "
                          ),
                          _c("i", { staticClass: "fa fa-fw fa-sort" }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("th", [_vm._v("Status")]),
                      _vm._v(" "),
                      _vm.$gate.isAdmin() || _vm.$gate.isUser()
                        ? _c("th")
                        : _vm._e(),
                      _vm._v(" "),
                      _c("th", [
                        _vm._v("\n                  Show:\n                  "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.perPage,
                                expression: "perPage",
                              },
                            ],
                            staticClass: "form-control",
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.perPage = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                _vm.changePerPage,
                              ],
                            },
                          },
                          [
                            _c("option", [_vm._v("10")]),
                            _vm._v(" "),
                            _c("option", [_vm._v("25")]),
                            _vm._v(" "),
                            _c("option", [_vm._v("50")]),
                            _vm._v(" "),
                            _c("option", [_vm._v("100")]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.series.data, function (one) {
                      return _c("tr", { key: one.id }, [
                        _c("td", { staticClass: "series-name" }, [
                          _vm._v(_vm._s(one.name)),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-capitalize" }, [
                          _vm._v(_vm._s("Active")),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  return _vm.goQuploads($event, one.id)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                    View Content\n                  "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.$gate.isAdmin() || _vm.$gate.isUser()
                          ? _c("td", [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goEdit($event, one.id)
                                    },
                                  },
                                },
                                [_vm._v(" Edit Series ")]
                              ),
                            ])
                          : _vm._e(),
                      ])
                    }),
                    0
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("pagination", {
                    attrs: { data: _vm.series, "per-page": _vm.perPage },
                    on: { "pagination-change-page": _vm.getResults },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header p-2" }, [
      _c("ul", { staticClass: "nav nav-pills" }, [
        _c("li", { staticClass: "nav-item" }, [
          _c(
            "a",
            {
              staticClass: "nav-link active show",
              attrs: { href: "#settings", "data-toggle": "tab" },
            },
            [_vm._v("Profile")]
          ),
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "nav-item" }, [
          _c(
            "a",
            {
              staticClass: "nav-link",
              attrs: { href: "#change-password", "data-toggle": "tab" },
            },
            [_vm._v("Change Password")]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }