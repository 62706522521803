var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Header"),
      _vm._v(" "),
      _c("section", { staticClass: "content" }, [
        _c("h1", { staticClass: "section-title" }, [_vm._v("Dashboard")]),
        _vm._v(" "),
        _c("div", { staticClass: "controls" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.q,
                expression: "q",
              },
            ],
            staticClass: "form-control search-input",
            attrs: { type: "text", placeholder: "Search.." },
            domProps: { value: _vm.q },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.search.apply(null, arguments)
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.q = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary btn-search",
              attrs: { disabled: !_vm.q },
              on: { click: _vm.search },
            },
            [_vm._v("\n        Search\n      ")]
          ),
          _vm._v(" "),
          _vm.$gate.isAdmin()
            ? _c(
                "button",
                {
                  staticClass: "btn btn-primary btn-create-programmer",
                  on: {
                    click: function ($event) {
                      return _vm.$router.push("/create-programmer")
                    },
                  },
                },
                [_vm._v("\n        Create a User\n      ")]
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-body table-responsive p-0" }, [
          _c(
            "table",
            { staticClass: "table table-hover table-borderless table-striped" },
            [
              _c("thead", [
                _c("tr", [
                  _c(
                    "th",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.handleSort("series.name")
                        },
                      },
                    },
                    [
                      _vm._v("\n              Series\n              "),
                      _c("i", { staticClass: "fa fa-fw fa-sort" }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("th", [_vm._v("Status")]),
                  _vm._v(" "),
                  _c("th"),
                  _vm._v(" "),
                  _c("th", [
                    _vm._v("\n              Show:\n              "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.perPage,
                            expression: "perPage",
                          },
                        ],
                        staticClass: "form-control",
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.perPage = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            _vm.changePerPage,
                          ],
                        },
                      },
                      [
                        _c("option", [_vm._v("10")]),
                        _vm._v(" "),
                        _c("option", [_vm._v("25")]),
                        _vm._v(" "),
                        _c("option", [_vm._v("50")]),
                        _vm._v(" "),
                        _c("option", [_vm._v("100")]),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.series.data, function (one) {
                  return _c("tr", { key: one.id }, [
                    _c("td", { staticClass: "series-name" }, [
                      _vm._v(_vm._s(one.name)),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-capitalize" }, [
                      _vm._v(_vm._s("Active")),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              return _vm.goQuploads($event, one.id)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                View Content\n              "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              return _vm.goEdit($event, one.id)
                            },
                          },
                        },
                        [_vm._v(" Edit Series ")]
                      ),
                    ]),
                  ])
                }),
                0
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("pagination", {
                attrs: { data: _vm.series, "per-page": _vm.perPage },
                on: { "pagination-change-page": _vm.getResults },
              }),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }